import { TextInput, NumberInput, SimpleForm, Toolbar, SaveButton, number, minValue, required } from 'react-admin';

import { CreateDialog } from '@react-admin/ra-form-layout';
import './ArticleEdit.css';
import { withTranslation } from 'react-i18next';

const ArticleCreate = props => {
    const t = props.t;
    const transform = data => ({
        ...data,
        categoryId: props.location.state.categoryId,
        nbArticles: props.location.state.nbArticles
    });

    const roundMe = value => {
        if (value === '') return '';
        return value > 0 ? Math.round(value) : -1 * Math.round(value);
    };

    const validateNotEmpty = [required(t('fieldRequire'))];

    return (
        <CreateDialog className='createArticleAllContainer' transform={transform} {...props} title={t('createArticle')}>
            <SimpleForm toolbar={<ArticleCreateToolbar {...props} />}>
                <div className='articleEditContainer'>
                    <div className='articleBlock'>
                        <div className='articleTitle'>{t('textDescriptionArticleCreate')}</div>
                        <TextInput validate={validateNotEmpty} source='name' label={t('labelArticleName')} />
                        <TextInput
                            source='price_ttc'
                            label='prix TTC (XPF)'
                            type='number'
                            parse={roundMe}
                            validate={validateNotEmpty}
                        />
                        <TextInput
                            step={1}
                            validate={validateNotEmpty}
                            type='number'
                            source='tax'
                            label='dont taxe (%)'
                            parse={roundMe}
                            defaultValue={JSON.parse(localStorage.getItem('store_data')).item_tax || null}
                        />
                    </div>
                </div>
            </SimpleForm>
        </CreateDialog>
    );
};

const ArticleCreateToolbar = props => (
    <div className='saveButton'>
        <Toolbar {...props}>
            <SaveButton label={props.t('validateLabel')} icon={<></>} />
        </Toolbar>
    </div>
);

export default withTranslation()(ArticleCreate);
