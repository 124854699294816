import React from 'react';
import GoogleMapReact from 'google-map-react';
import { useState } from 'react';
import StoreIcon from '../assets/store.png';

export default function GmapsStore({ lat, lng }) {
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    console.log('in map', lat, lng);

    if (map && maps) {
        map.setZoom(11);
        map.setCenter({
            lat,
            lng
        });
        map.setOptions({ draggable: false });
    }

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: '30vh', width: '80%', margin: 'auto' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDWOkPsOo9gWN_8AZYWv3P5TZ5SwE5y26A' }}
                defaultCenter={{
                    lat: -22.248946765407986,
                    lng: 166.46014461145876
                }}
                zoomControl={false}
                defaultZoom={8}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                    map.draggable = false;
                    map.gestureHandling = 'none';
                    setMap(map);
                    setMaps(maps);
                }}
            >
                <div className='gmapsStorePoint'></div>
                <img className='gmapsStoreIcon' src={StoreIcon} />
            </GoogleMapReact>
        </div>
    );
}
