import React from 'react';
import { BooleanInput, minValue, NumberInput, number, maxValue } from 'react-admin';
import { withTranslation } from 'react-i18next';
import './Cards.css';

const CardPrice = ({ t }) => {
    const validateNotnegative = [number(), minValue(0, t('errorNotNegative'))];
    const validatePercentage = [number(), minValue(0, t('errorPercentage')), maxValue(100, t('errorPercentage'))];
    // const validateNotnegative = (message = 'Required') =>
    // value => value ? undefined : message;
    return (
        <div className='cardParameter'>
            <div className='headerParameter'>
                <span className='titleParameter'>{t('titlePriceParameter')}</span>
            </div>
            {localStorage.getItem('isAdmin') === 'true' && (
                <div className='rowInput'>
                    <span>{t('ArticleTaxText')}</span>
                    <BooleanInput label={false} source='is_use_item_tax' />
                </div>
            )}

            <div className='rowInput'>
                <span>{t('TGCText')}</span>
                <NumberInput
                    validate={validatePercentage}
                    min={0}
                    max={100}
                    label={false}
                    helperText={t('inPercentage')}
                    source='item_tax'
                />
            </div>
            <div className='rowInput'>
                <span>{t('minimumBuy')}</span>
                <NumberInput
                    validate={validateNotnegative}
                    min={0}
                    label={false}
                    helperText={t('inXpf')}
                    source='min_order_price'
                />
            </div>
            {localStorage.getItem('isAdmin') === 'true' && (
                <div className='rowInput'>
                    <span>{t('AssignDeliver')}</span>
                    <BooleanInput label={false} source='is_ask_estimated_time_for_ready_order' />
                </div>
            )}
        </div>
    );
};
export default withTranslation()(CardPrice);
