import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { addCredentials } from './library';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getOne: resource => {
        return httpClient(`${apiUrl}/api/store/get_store_data`, {
            method: 'POST',
            body: JSON.stringify(addCredentials({}))
        })
            .then(({ json }) => {
                let res = {};
                if (json.success === true) {
                    res.data = json.store_detail.store_time;
                    res.data.id = json.store_detail._id;
                    return res;
                }
            })
            .catch(err => {
                console.log(err, 'error setting');
            });
    },
    setStoreTime: (resource, { store_id, store_time }) => {
        return httpClient(apiUrl + '/api/store/update_store_time', {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    store_time
                })
            )
        }).then(({ json }) => {
            return { data: json };
        });
    }
};
