import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useLogin, useRedirect, useNotify, Notification, defaultTheme } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import './MyLoginPage.css';
import lockIcon from '../assets/lockIcon.svg';

const MyLoginPage = ({ theme }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    // const submit = e => {
    //     e.preventDefault();
    //     login({ email, password }).catch(() => notify('Invalid email or password'));
    // };
    return (
        <div className='loginBackground'>
            <div className='loginBox'>
                <img src={lockIcon} width='35px' />
                <input
                    placeholder='Tel ou email'
                    name='email'
                    type='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <div style={{ height: '15px' }}></div>
                <input
                    placeholder='Mot de passe'
                    name='password'
                    type='password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <button
                    className='signInButton'
                    onClick={() => {
                        login({ email, password }).catch(() => notify('Invalid email or password'));
                    }}
                >
                    Se connecter
                </button>
                <Link to='/register'>
                    {' '}
                    <div style={{ fontFamily: 'Roboto' }}> Nouveau compte</div>
                </Link>
            </div>

            <Notification />
        </div>
    );
};

export default MyLoginPage;
