import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { useEffect, useState } from 'react';
import './schedule.css';
import Button from '@material-ui/core/Button';
import { AddOutlined, Remove } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';

const ScheduleRow = ({ t, setForm, form, totalForm, setModified }) => {
    const [expand, setExpand] = useState(false);
    const [newScheduleVerification, setNewScheduleVerification] = useState('');
    const [formCloseTime, setFormCloseTime] = useState('');
    const [formOpenTime, setFormOpenTime] = useState('');
    const [addSchedule, setAddSchedule] = useState(false);

    // Je vais utiliser changeId de manière à faire la vérification des erreurs une fois que l'horaire a été updaté
    const [changeId, setChangeId] = useState(false);

    useEffect(() => {
        let newState = JSON.parse(JSON.stringify(totalForm));
        form.day_time.map((schedule, index) => {
            const actualDay = form.day;
            let verif;
            verif = NewDateIsOk(
                form.day_time[index].store_open_time,
                form.day_time[index].store_close_time,
                form.day_time,
                index
            );
            if (verif !== true) {
                newState[actualDay].errors[index] = verif;
            } else {
                delete newState[actualDay].errors[index];
            }
        });
        setForm(newState);
    }, [changeId]);

    const getDay = day => {
        switch (day) {
            case 0:
                return t('sunday');
            case 1:
                return t('monday');
            case 2:
                return t('tuesday');
            case 3:
                return t('wednesday');
            case 4:
                return t('thursday');
            case 5:
                return t('friday');
            case 6:
                return t('saturday');
            default:
                return t('sunday');
        }
    };

    const handleChange = event => {
        let newState = JSON.parse(JSON.stringify(totalForm));
        const actualDay = form.day;
        if (event.target.name === 'checkStoreOpen') {
            newState[actualDay].is_store_open = event.target.checked;
        }
        setForm(newState);
        setModified(true);
    };
    const handleChangeDate = (event, id) => {
        const actualDay = form.day;
        let newState = [...totalForm];
        const name = event.target.name;
        if (name === 'updateDateOpen') {
            newState[actualDay].day_time[id].store_open_time = event.target.value;
        }
        if (name === 'updateDateClose') {
            newState[actualDay].day_time[id].store_close_time = event.target.value;
        }
        setForm(newState);
        setModified(true);
        setChangeId(!changeId);
    };

    const checkIfBetween = (scheduleOpen, scheduleClose, arrayAllSchedules) => {
        let errors = [];
        const timeOpen = scheduleOpen.split(':');
        const timeClose = scheduleClose.split(':');
        const nbMinuteMin = timeOpen[0] * 60 + timeOpen[1];
        const nbMinuteMax = timeClose[0] * 60 + timeClose[1];
        for (const schedule of arrayAllSchedules) {
            const splitScheduleOpen = schedule.store_open_time.split(':');
            const nbMinScheduleOpen = splitScheduleOpen[0] * 60 + splitScheduleOpen[1];
            const splitScheduleClose = schedule.store_close_time.split(':');
            const nbMinScheduleClose = splitScheduleClose[0] * 60 + splitScheduleClose[1];
            if (
                parseInt(nbMinuteMin) > parseInt(nbMinScheduleOpen) &&
                parseInt(nbMinScheduleClose) > parseInt(nbMinuteMin)
            ) {
                errors.push(t('errorScheduleOne'));
            }
            if (
                parseInt(nbMinuteMax) > parseInt(nbMinScheduleOpen) &&
                parseInt(nbMinScheduleClose) > parseInt(nbMinuteMax)
            ) {
                errors.push(t('errorScheduleTwo'));
            }
            if (
                parseInt(nbMinuteMin) <= parseInt(nbMinScheduleOpen) &&
                parseInt(nbMinuteMax) >= parseInt(nbMinScheduleClose)
            ) {
                errors.push(t('errorScheduleFive'));
            }
        }
        if (errors.length === 0) {
            return false;
        } else {
            return errors;
        }
    };

    const onAddSchedule = () => {
        const scheduleAlreadyPresent = [...form.day_time];
        const actualDay = form.day;
        const objToAdd = { store_close_time: formCloseTime, store_open_time: formOpenTime };
        scheduleAlreadyPresent.push(objToAdd);
        scheduleAlreadyPresent.sort(function compare(a, b) {
            if (a.store_open_time < b.store_open_time) return -1;
            if (a.store_open_time > b.store_open_time) return 1;
            return 0;
        });
        let newState = [...totalForm];
        newState[actualDay].day_time = scheduleAlreadyPresent;
        setForm(newState);
        setModified(true);
        setFormCloseTime('');
        setFormOpenTime('');
        setAddSchedule(false);
        setNewScheduleVerification('');
        // setInitialState(false);
        // setShouldUpdate(!shouldUpdate);
    };

    const checkCloseSupOpen = (currentScheduleOpen, currentScheduleClose) => {
        let timeOpen = currentScheduleOpen.split(':');
        let nbMinOpen = timeOpen[0] * 60 + timeOpen[1];
        let timeClose = currentScheduleClose.split(':');
        let nbMinClose = timeClose[0] * 60 + timeClose[1];
        if (parseInt(nbMinClose) < parseInt(nbMinOpen)) {
            return true;
        }
        return false;
    };

    const NewDateIsOk = (currentScheduleOpen, currentScheduleClose, arrayAllSchedules, id) => {
        let errors = [];
        if (currentScheduleClose === '' || currentScheduleOpen === '') {
            errors.push(t('errorScheduleFour'));
            return errors;
            // return t('errorScheduleFour');
        }
        let newArrayAllSchedules;
        if (id !== null) {
            newArrayAllSchedules = [...arrayAllSchedules];
            newArrayAllSchedules.splice(id, 1);
        } else {
            newArrayAllSchedules = arrayAllSchedules;
        }

        if (checkCloseSupOpen(currentScheduleOpen, currentScheduleClose)) {
            errors.push(t('errorScheduleThree'));
        }
        if (checkIfBetween(currentScheduleOpen, currentScheduleClose, newArrayAllSchedules) !== false) {
            return errors.concat(checkIfBetween(currentScheduleOpen, currentScheduleClose, newArrayAllSchedules));
        }
        if (errors.length === 0) {
            return true;
        } else {
            return errors;
        }
    };
    const deleteDate = id => {
        const actualDay = form.day;
        const newState = JSON.parse(JSON.stringify(totalForm));
        newState[actualDay].day_time.splice(id, 1);
        setForm(newState);
        setModified(true);
    };
    const handleChangeNewDate = event => {
        if (event.target.name === 'actualOpen') {
            const verif = NewDateIsOk(event.target.value, formCloseTime, form.day_time, null);
            if (verif !== true) {
                setNewScheduleVerification(verif);
            } else {
                setNewScheduleVerification(true);
            }
            setFormOpenTime(event.target.value);
        }
        if (event.target.name === 'actualClose') {
            setFormCloseTime(event.target.value);
            const verif = NewDateIsOk(formOpenTime, event.target.value, form.day_time, null);
            if (verif !== true) {
                setNewScheduleVerification(verif);
            } else {
                setNewScheduleVerification(true);
            }
            setFormCloseTime(event.target.value);
        }
    };

    return (
        <div className={form.day === 0 ? 'rowScheduleFirst' : form.day === 6 ? 'rowScheduleLast' : 'rowSchedule'}>
            <div onClick={() => setExpand(!expand)} className='scheduleExpandDiv'>
                <div className='rowContainerSchedule'>
                    <div className='scheduleTitleDay'>{getDay(form.day)}</div>
                    <div>
                        {t('open')}
                        <Switch
                            checked={form.is_store_open}
                            onChange={handleChange}
                            color='primary'
                            name='checkStoreOpen'
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                    <div className='scheduleOpenTimeContainer'>
                        {form.day_time.length !== 0 &&
                            form.day_time.map(dayTime => {
                                return (
                                    <div
                                        className='scheduleTimeAlreadyPresent'
                                        key={dayTime.store_open_time}
                                    >{` ${dayTime.store_open_time}  -  ${dayTime.store_close_time}`}</div>
                                );
                            })}
                    </div>
                    {expand ? (
                        <div className='scheduleIconContainer'>
                            <Remove style={{ fontSize: 30 }} />
                        </div>
                    ) : (
                        <div className='scheduleIconContainer'>
                            <AddOutlined style={{ fontSize: 30 }} />
                        </div>
                    )}
                </div>
            </div>
            {expand && (
                <div className='scheduleExpand'>
                    <div className='scheduleRowButton'>
                        <div>
                            {form.day_time.length !== 0 &&
                                form.day_time.map((dayTime, index) => {
                                    return (
                                        <div key={`a-${index}`} className='scheduleRowSchedules'>
                                            <div key={`b-${index}`} className='scheduleWrapperTextField'>
                                                <TextField
                                                    key={`b-${index}`}
                                                    margin='normal'
                                                    className='scheduleAlreadyExistantInput'
                                                    label={t('scheduleOpen')}
                                                    error={form.errors[index] && form.errors[index].length !== 0}
                                                    variant='outlined'
                                                    type='time'
                                                    name='updateDateOpen'
                                                    defaultValue={dayTime.store_open_time}
                                                    onChange={e => handleChangeDate(e, index)}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    inputProps={{
                                                        step: 300 // 5 min
                                                    }}
                                                />
                                            </div>
                                            <div key={`d-${index}`} className='scheduleWrapperTextField'>
                                                <TextField
                                                    key={`e-${index}`}
                                                    margin='normal'
                                                    className='scheduleAlreadyExistantInput'
                                                    label={t('scheduleClose')}
                                                    type='time'
                                                    error={form.errors[index] && form.errors[index].length !== 0}
                                                    name='updateDateClose'
                                                    variant='outlined'
                                                    onChange={e => handleChangeDate(e, index)}
                                                    defaultValue={dayTime.store_close_time}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    inputProps={{
                                                        step: 300, // 5 min
                                                        test: index
                                                    }}
                                                />
                                            </div>
                                            <Delete
                                                className='scheduleDelete'
                                                onClick={() => deleteDate(index)}
                                                style={{ fontSize: 25, color: 'red' }}
                                            />
                                            <div>
                                                {form.errors[index] &&
                                                    form.errors[index].length !== 0 &&
                                                    form.errors[index].map(err => (
                                                        <div className='scheduleError'>{err}</div>
                                                    ))}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className='scheduleContainerButtonCreate'>
                            <div className='scheduleButtonCreate'>
                                <Button onClick={() => setAddSchedule(true)} variant='contained' color='primary'>
                                    {t('addSchedule')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    {addSchedule && (
                        <div className='addScheduleContainer'>
                            <div className='scheduleRowSchedules'>
                                <div className='scheduleWrapperTextField'>
                                    <TextField
                                        value={formOpenTime}
                                        id={'open'}
                                        label={t('scheduleOpen')}
                                        type='time'
                                        className='scheduleAlreadyExistantInput'
                                        onChange={handleChangeNewDate}
                                        name='actualOpen'
                                        variant='outlined'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            step: 300 // 5 min
                                        }}
                                    />
                                </div>
                                <div className='scheduleWrapperTextField'>
                                    <TextField
                                        value={formCloseTime}
                                        id={'close'}
                                        label={t('scheduleClose')}
                                        className='scheduleAlreadyExistantInput'
                                        type='time'
                                        variant='outlined'
                                        name='actualClose'
                                        onChange={handleChangeNewDate}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            step: 300 // 5 min
                                        }}
                                    />
                                </div>
                                {newScheduleVerification !== true &&
                                    newScheduleVerification !== '' &&
                                    newScheduleVerification.map(err => (
                                        <div className='scheduleError'>{`${err}.`} </div>
                                    ))}
                            </div>

                            <div className='scheduleButtonAdd'>
                                <Button
                                    onClick={onAddSchedule}
                                    disabled={newScheduleVerification !== true}
                                    variant='contained'
                                    color='primary'
                                >
                                    {t('validate')}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default withTranslation()(ScheduleRow);
