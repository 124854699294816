import * as React from 'react';

export const Spacer = ({ height }) => {
    return (
        <div
            style={{
                // backgroundColor: "green",
                width: '10px',
                height: height || '10px'
            }}
        ></div>
    );
};
export const Divider = ({ height }) => {
    return (
        <hr
            style={{
                borderTop: `${height || '5px'} solid rgb(184, 184, 184)`
            }}
        ></hr>
    );
};
