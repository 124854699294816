import React from 'react'
import { required, TextInput } from 'react-admin';
import { withTranslation } from 'react-i18next';


const CardPassword = ({t}) => {
  const validateNotEmpty = [required('Champ obligatoire')];

  return (
    <div className='cardParameterPassword'>
    <div className='headerParameter'>
      <span className='titlePasswordParameter'>
        {t('generalPassword')}
      </span>
    </div>
    <div className='rowInput'>
        <span>{t('passwordLabel')}</span>
        <TextInput validate={validateNotEmpty} label={t('passwordLabel')} source="old_password" helperText={t('passwordMandatory')} /> 
      </div>
  </div>
   
  );
};
export default withTranslation()(CardPassword);