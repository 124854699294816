import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { addCredentials } from './library';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page } = params.pagination;
        const { search_field, search_value, order_status, order_type, start_date, end_date } = params.filter;
        // get_store_product_item_list
        return httpClient(`${apiUrl}/api/store/search_sort_promo_code_list`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    page: page,
                    search_field: search_field || 'promo_code_name',
                    search_value: search_value || '',
                    sort_field: 'unique_id',
                    sort_promo_code: -1
                })
            )
        }).then(async ({ json }) => {
            // CURRENCY SIGN ?
            const data = json.promo_codes
                ? json.promo_codes.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: json.pages * 10
            };
        });
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/admin/get_promo_detail`, {
            method: 'POST',
            body: JSON.stringify({
                promo_id: params.id
            })
        }).then(({ json }) => {
            json.promo_code.id = json.promo_code._id;
            json.promo_code.test = 'testXX';
            return {
                data: json.promo_code
            };
        });
    },
    update: (resource, params) => {
        return httpClient(`${apiUrl}/api/store/update_promo_code`, {
            method: 'POST',
            body: JSON.stringify(addCredentials({ ...params.data, promo_id: params.data._id }))
        }).then(({ json }) => {
            return {
                data: { id: params.id }
            };
        });
    },
    create: (resource, params) => {
        return httpClient(`${apiUrl}/api/store/add_promo`, {
            method: 'POST',
            body: JSON.stringify(addCredentials({ ...params.data }))
        }).then(({ json }) => {
            return {
                data: { id: params.id }
            };
        });
    }
};

export const callApiArticle = async () => {
    let resFinal;
    const requestForArticle = await httpClient(`${apiUrl}/api/store/get_product_list`, {
        method: 'POST',
        body: JSON.stringify(addCredentials())
    })
        .then(res => {
            if (res.status === 200) {
                resFinal = {products:res.json.products,items:res.json.item_array};
            }
        })
        .catch(e => {
            console.log(e, 'error return');
        });

    return resFinal;
};
