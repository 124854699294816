import { useContext, useState } from 'react';
import { DataProviderContext, useListContext, useNotify } from 'react-admin';
import { DeleteOutline, Edit, Check } from '@material-ui/icons';

const OptionCard = ({ id, i, t, setOptionGroupToEdit }) => {
    const dataProvider = useContext(DataProviderContext);
    const notify = useNotify();

    const deleteSpecification = async () => {
        try {
            const res = await dataProvider.customApiCall('options', {
                action: 'removeEntirelyOption',
                optionGroupId: id
            });
            if (!res.success) {
                notify(t('textCommandErrorApi'));
                refetch();
            } else {
                notify(t('categoryUpdated'));
                refetch();
                // redirect('list', '/articles', 1, 1, {
                //     categoryId: res.product._id
                // });
            }
        } catch (e) {
            console.log(e, 'err api');
            notify(t('textCommandErrorApi'));
        }
    };
    const { ids, data, refetch } = useListContext();
    const [isHover, setIsHover] = useState(false);

    return (
        <div
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            key={id}
            className='catCard'
            onClick={() => {
                setOptionGroupToEdit(data[id]);
            }}
        >
            {!!isHover && (
                <div className='removeOrEditCategory'>
                    <div
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            // updateProduct(true, { isVisible: false });
                        }}
                        className='orderSuppressIcon'
                    >
                        <DeleteOutline
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                deleteSpecification();
                            }}
                            className='orderSuppressIconInside'
                            fontSize='small'
                        />
                    </div>
                </div>
            )}
            <div key={id} className='catCardText'>
                {data[id].name}
            </div>
        </div>
    );
};

export default OptionCard;
