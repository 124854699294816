import _get from 'lodash/get';
import { Container, Draggable } from 'react-smooth-dnd';
import './OrderList.css';
import { useState, useContext, useEffect } from 'react';
import OrderCard from './OrderCard';
import { MenuBookOutlined, ThumbUpAltOutlined, OutdoorGrillOutlined, MotorcycleOutlined } from '@material-ui/icons';

import ModalInput from '../../components/ModalInput';

import {
    //   MISC
    List,
    useListContext,
    DataProviderContext,
    useNotify
} from 'react-admin';
import { withTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import OrderDetail from '../../components/OrderDetail';

const containerStyle = {
    width: '250px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

const DnD = props => {
    const t = props.t;
    const dataProvider = useContext(DataProviderContext);
    const { ids, data, refetch } = useListContext();
    const notify = useNotify();
    const [enterContainer, setEnterContainer] = useState(null);
    const [currPayload, setCurrPayload] = useState(false);

    const [selectedOrder, setSelectedOrder] = useState(() => {
        const orderDialogOpen = localStorage.getItem('order');
        return orderDialogOpen ? JSON.parse(orderDialogOpen) : null;
    });

    useEffect(() => {
        localStorage.setItem('order', JSON.stringify(selectedOrder));
    }, [selectedOrder]);

    const handleOrderClick = order => {
        setSelectedOrder(order);
    };

    const handleCloseDialog = () => {
        setSelectedOrder(null);
    };

    const changeStatus = async (item, status) => {
        if (item.addedIndex === null) return;
        try {
            const { data } = await dataProvider.customApiCall('orders', {
                action: 'setOrderStatus',
                record: item.payload.id,
                otherParameters: { status }
            });
            refetch();
            if (!data.success) {
                notify(t('textCommandErrorApi'));
            } else {
                if (status === 3) {
                    notify(t('textCommandAccepted'));
                } else {
                    notify(t('textCommandReadyToDelivery'));
                }
            }
        } catch (e) {
            notify(t('textCommandErrorApi'));
        }
    };

    const createRequest = async (item, time) => {
        try {
            if (_get(item, 'order_payment_detail.is_user_pick_up_order', false)) {
                const { data } = await dataProvider.customApiCall('orders', {
                    action: 'setOrderStatus',
                    record: item.id,
                    otherParameters: { status: 5 }
                });
                refetch();
                if (!data.success) {
                    notify(t('textCommandErrorApi'));
                } else {
                    notify(t('textCommandOnGoing'));
                }
            } else {
                const vehiculeResponse = await dataProvider.customApiCall('orders', {
                    action: 'getVehiculeList',
                    record: item._id
                });
                if (!vehiculeResponse.success || !vehiculeResponse.vehicles || vehiculeResponse.vehicles.length === 0) {
                    notify(t('textErrorVehicle'));
                    return;
                }
                const createRequestResponse = await dataProvider.customApiCall('orders', {
                    action: 'createRequest',
                    record: item,
                    otherParameters: {
                        time,
                        vehiculeId: vehiculeResponse.vehicles[0]._id
                    }
                });
                if (!createRequestResponse.success) {
                    notify(t('textCommandErrorApi'));
                } else {
                    notify(t('textCommandOnGoing'));
                }
                refetch();
            }
        } catch (e) {
            notify(t('textCommandErrorApi'));
            console.log(e);
            refetch();
        }
    };

    const cancelOrder = async (order, reason) => {
        try {
            const data = await dataProvider.customApiCall('orders', {
                action: 'cancelOrder',
                record: order,
                otherParameters: { reason }
            });
            refetch();
            if (!data.success) {
                notify(t('textCommandErrorApi'));
            } else {
                notify(t('textCancelOrderSuccessful'));
            }
        } catch (e) {
            notify(t('textCommandErrorApi'));
        }
    };

    return (
        <div className='containerpage'>
            {/* # # # # # # # # # # # # # # # # CONTAINER 1 # # # # # # # */}
            <div className='dndContainer'>
                <div className='dndTitle'>
                    <span>{t('labelNewCommand')}</span>
                    <MenuBookOutlined />
                </div>
                <Container
                    getChildPayload={i => {
                        const myId = ids.filter(id => data[id].order_status === 1)[i];
                        return data[myId];
                    }}
                    shouldAcceptDrop={e => false}
                    groupName='dragndrop'
                    dropPlaceholder={true}
                    style={containerStyle}
                    // lockAxis="x"
                    // autoScrollEnabled={false}
                >
                    {ids.map((id, i) => {
                        if (data[id].order_status === 1) {
                            return (
                                <Draggable key={id}>
                                    <OrderCard order={data[id]} cancelOrder={cancelOrder} onClick={handleOrderClick} />
                                </Draggable>
                            );
                        }
                    })}
                </Container>
            </div>
            {/* # # # # # # # # # # # # # # # # CONTAINER 2 # # # # # # # */}
            <div className={`dndContainer ${enterContainer === 2 ? 'dndSelected' : ''}`}>
                <div className='dndTitle'>
                    <span>Accepté </span>
                    <ThumbUpAltOutlined />
                </div>
                <Container
                    dropPlaceholder={true}
                    style={containerStyle}
                    className='test'
                    onDrop={item => {
                        changeStatus(item, 3);
                        setEnterContainer(null);
                    }}
                    groupName='dragndrop'
                    shouldAcceptDrop={(sourceContainerOptions, payload) => {
                        return payload.order_status === 1;
                    }}
                    getChildPayload={i => {
                        const myId = ids.filter(id => data[id].order_status === 3)[i];
                        return data[myId];
                    }}
                    onDragEnter={() => {
                        setEnterContainer(2);
                    }}
                    onDragLeave={() => {
                        setEnterContainer(null);
                    }}
                >
                    {ids.map((id, i) => {
                        if (data[id].order_status === 3) {
                            return (
                                <Draggable key={id}>
                                    <OrderCard order={data[id]} cancelOrder={cancelOrder} onClick={handleOrderClick} />
                                </Draggable>
                            );
                        }
                    })}
                </Container>
            </div>
            {/* # # # # # # # # # # # # # # # # CONTAINER 3 # # # # # # # */}
            <div className={`dndContainer ${enterContainer === 3 ? 'dndSelected' : ''}`}>
                <div className='dndTitle'>
                    <span>{t('labelOnGoing')}</span>
                    <OutdoorGrillOutlined />
                </div>
                <Container
                    dropPlaceholder={true}
                    style={containerStyle}
                    onDrop={item => {
                        setEnterContainer(null);
                        setCurrPayload(item.payload);
                    }}
                    groupName='dragndrop'
                    shouldAcceptDrop={(sourceContainerOptions, payload) => payload.order_status === 3}
                    getChildPayload={i => {
                        const myId = ids.filter(id => data[id].order_status === 5)[i];
                        return data[myId];
                    }}
                    onDragEnter={() => {
                        setEnterContainer(3);
                    }}
                    onDragLeave={() => {
                        setEnterContainer(null);
                    }}
                >
                    {ids.map((id, i) => {
                        if (data[id].order_status === 5) {
                            return (
                                <Draggable key={id}>
                                    <OrderCard order={data[id]} cancelOrder={cancelOrder} onClick={handleOrderClick} />
                                </Draggable>
                            );
                        }
                    })}
                </Container>
                {/* MODAL TO SET PREPARATION TIME */}
                <ModalInput
                    t={t}
                    numberMustBeSupToZero={true}
                    label={t('labelTime')}
                    numbersOnly
                    title={t('labelTimePreparation')}
                    onValidate={input => {
                        createRequest(currPayload, input);
                        setCurrPayload(false);
                    }}
                    open={currPayload ? true : false}
                    onClose={() => {
                        setCurrPayload(false);
                    }}
                    iconValidate={OutdoorGrillOutlined}
                    textValidate='en cours'
                />
            </div>
            {/* # # # # # # # # # # # # # # # # CONTAINER 4 # # # # # # # */}
            <div className={`dndContainer ${enterContainer === 4 ? 'dndSelected' : ''}`}>
                <div className='dndTitle'>
                    <span>{t('labelReadyToBeDelivered')}</span>
                    <MotorcycleOutlined />
                </div>
                <Container
                    dropPlaceholder={true}
                    style={containerStyle}
                    onDrop={item => {
                        changeStatus(item, 7);
                        setEnterContainer(null);
                    }}
                    groupName='dragndrop'
                    shouldAcceptDrop={(sourceContainerOptions, payload) => {
                        return payload.order_status === 5;
                    }}
                    getChildPayload={i => {
                        const myId = ids.filter(id => data[id].order_status === 7)[i];
                        return data[myId];
                    }}
                    onDragEnter={() => {
                        setEnterContainer(4);
                    }}
                    onDragLeave={() => {
                        setEnterContainer(null);
                    }}
                >
                    {ids.map((id, i) => {
                        if (data[id].order_status === 7) {
                            return (
                                <Draggable key={id}>
                                    <OrderCard order={data[id]} cancelOrder={cancelOrder} onClick={handleOrderClick} />
                                </Draggable>
                            );
                        }
                    })}
                </Container>
            </div>
            {!!selectedOrder && (
                <Dialog open={true} onClose={handleCloseDialog} maxWidth={false}>
                    <OrderDetail data={selectedOrder} />
                </Dialog>
            )}
        </div>
    );
};

const OrderList = props => (
    <div className='containerAllOrder'>
        <List {...props} pagination={null} actions={null}>
            <DnD {...props} />
        </List>
    </div>
);

export default withTranslation()(OrderList);
