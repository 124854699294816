import { withTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { AUTOCOMPLETE_APIKEY } from '../config';

const AddressAutoComplete = ({ setLoc }) => {
    const setAutoCompleteAddress = e => {
        geocodeByAddress(e.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                setLoc([lat, lng, e.label]);
            });
    };

    return (
        <div className='AddressAutoCompleteAccount'>
            <GooglePlacesAutocomplete
                style={{ width: '100px' }}
                apiKey={AUTOCOMPLETE_APIKEY}
                selectProps={{
                    onChange: setAutoCompleteAddress,
                    defaultInputValue: '',
                    placeholder: 'entrez le nom de votre restaurant'
                }}
            />
        </div>
    );
};
export default withTranslation()(AddressAutoComplete);
