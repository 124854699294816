import { useEffect, useState } from 'react';

import { TextField, Checkbox } from '@material-ui/core';

const ArticleOptionRange = ({ values, i, moveOptions }) => {
    const [range, setRange] = useState(values.specifications[i].range);
    const [max_range, setMax_range] = useState(values.specifications[i].max_range);
    const [is_required, setIs_required] = useState(values.specifications[i].is_required);

    const integersOnly = new RegExp('^[0-9]*$');
    useEffect(() => {
        // if max_range = null make it look like it's equal range
        if (max_range === null || max_range === 'null') {
            setMax_range('');
        }
    }, []);

    useEffect(() => {
        values.specifications[i].range = Number(range);
        values.specifications[i].max_range = Number(max_range);
        values.specifications[i].is_required = is_required;
    }, [range, max_range, is_required]);

    const numOptions = values.specifications[i].list.reduce((a, b) => a + b.is_user_selected, 0);

    return (
        <div>
            <div className='articleMaxMinLine'>
                <TextField
                    error={range > numOptions}
                    label='minimum'
                    helperText={
                        range > numOptions
                            ? `doit être inférieur ou égal à ${numOptions}`
                            : `minimum obligatoire d'options`
                    }
                    value={range}
                    onChange={e => {
                        if (e.target.value.match(integersOnly)) {
                            setRange(e.target.value === '' ? '' : Number(e.target.value));
                            if (!is_required && Number(e.target.value) > 0) {
                                setIs_required(true);
                            }
                            if (is_required && Number(e.target.value) === 0) {
                                setIs_required(false);
                            }
                        }
                    }}
                />
                <TextField
                    label='maximum'
                    error={(Number(max_range) && max_range < range) || max_range > numOptions}
                    helperText={
                        Number(max_range) && max_range < range
                            ? 'doit être supérieur ou égal au minimum'
                            : max_range > numOptions
                            ? `doit être inférieur ou égal à ${numOptions}`
                            : `maximum d'options à choisir`
                    }
                    value={max_range}
                    onChange={e => {
                        if (e.target.value.match(integersOnly)) {
                            setMax_range(e.target.value === '' ? '' : Number(e.target.value));
                        }
                    }}
                />
                <div className='articleRequired'>
                    <div>obligatoire</div>
                    <Checkbox
                        label='obligatoire'
                        checked={is_required}
                        onChange={e => {
                            if (!e.target.checked) {
                                setRange('0');
                            } else if (range == 0) {
                                setRange('1');
                                setMax_range('1');
                            }
                            setIs_required(e.target.checked);
                        }}
                    />
                </div>
            </div>
            <div className='articleTextMaxMin'>Règle : {textToShow(is_required, range, max_range)} </div>
        </div>
    );
};

export default ArticleOptionRange;

const textToShow = (is_required, range, maxrange) => {
    // don't show if error
    if (maxrange && maxrange < range) {
        return 'erreur';
    }

    // choisir jusqu'a
    if (!is_required && !Number(range) && maxrange > 0) {
        return `choisir jusqu'à ${maxrange} options (non obligatoire)`;
    }
    // choisir
    if (is_required && range > 0 && (!maxrange || maxrange === range)) {
        return `choisir exactement ${range} options (obligatoire)`;
    }
    // choisir entre
    if (is_required && range > 0 && maxrange !== null) {
        return `choisir entre ${range} et ${maxrange} options (obligatoire)`;
    }

    return 'Libre';
};
