import { useField } from 'react-final-form';

const CheckboxCustomField = ({ name, label }) => {
    const {
        input: { onChange },
        meta: { touched, error }
    } = useField(name);

    const value = useField(name).input.value;

    return (
        <input
            className='checkBoxOptionArticleEdit'
            type='checkbox'
            checked={value}
            defaultChecked={true}
            name={name}
            label={label}
            onChange={onChange}
            style={{ width: '20px', padding: '10px' }}
        />
    );
};

export default CheckboxCustomField;
