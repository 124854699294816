import moment from 'moment';

export const getDayFromNumber = (t, day) => {
    day = parseInt(day);
    switch (day) {
        case 0:
            return t('sunday');
            break;
        case 1:
            return t('monday');
            break;
        case 2:
            return t('tuesday');
            break;
        case 3:
            return t('wednesday');
            break;
        case 4:
            return t('thursday');
            break;
        case 5:
            return t('friday');
            break;
        case 6:
            return t('saturday');
            break;
        default:
            return t('sunday');
            break;
    }
};

export const getReadableDateFromTimezone = (t, date, timezone) => {
    let dayOfWeek = moment(
        new Date(date).toLocaleString('en-US', {
            timeZone: timezone
        })
    ).format('d');
    let dayString = getDayFromNumber(t, dayOfWeek);
    let dateFormatted = moment(
        new Date(date).toLocaleString('en-US', {
            timeZone: timezone
        })
    ).format('DD/MM/YYYY --- HH:mm:ss');

    return `${dayString} ${dateFormatted}`;
};

export const backEndErrorCodes = code => {
    switch (code) {
        case 602:
            return 'email déjà enregistré';
        case 603:
            return 'téléphone déjå enregistré';
        default:
            return 'error ' + code;
    }
};
