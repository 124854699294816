import { useNotify, DataProviderContext } from 'react-admin';
import { useState, useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ButtonCallApiConfirm from '../../components/ButtonCallApiConfirm';
import { DeleteForever, AddBox, Link, LinkOff } from '@material-ui/icons';

const OptionGroupEdit = ({ optionGroupToEdit, refetch, t }) => {
    const [optionName, setOptionName] = useState('');
    const [addPrice, setAddPrice] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [isCalledAPI, setIsCalledAPI] = useState(false);

    useEffect(() => {
        if (optionName === '' || !optionName || addPrice < 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [optionName, addPrice]);

    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    const [optionGroupData, setOptionGroupData] = useState(optionGroupToEdit);

    const addOption = async () => {
        let priceToAdd = addPrice;
        if (!optionName || optionName === '') {
            notify(t('notifyErrorEmpty'));
            return;
        }

        if (addPrice === undefined || addPrice === null) {
            priceToAdd = 0;
        } else if (addPrice < 0) {
            notify(t('notifyPriceUnder0'));
            return;
        }
        try {
            const { data } = await dataProvider.customApiCall('options', {
                action: 'addOption',
                optionGroupId: optionGroupData._id,
                name: optionName,
                price: priceToAdd
            });
            setOptionName('');
            setAddPrice('');
            if (!data.success) {
                notify("Erreur dans la création d'option");
            } else {
                notify(
                    `La nouvelle option a été ajoutée à tous les articles auxquels la catégorie d’option ${optionGroupData.name} est associée`
                );
                const cp = { ...optionGroupData };
                cp.list = data.specifications;
                setOptionGroupData(cp);
                refetch();
            }
        } catch (e) {
            notify('there was an api error');
            setOptionName('');
            setAddPrice('');
        }
    };

    if (!optionGroupData) return null;
    return (
        <div className='optionsContainer'>
            <div className='menuTitle'>{t('titleEditOption')}</div>
            <div>{optionGroupData.name}</div>

            {optionGroupData.list.length ? <div className='menuTitle'>Options (prix HT)</div> : null}

            {optionGroupData.list.length ? (
                optionGroupData.list.map(option => (
                    <div className='optionsLine' key={option._id}>
                        {/* <div style={{ width: '170px', overflow: 'scroll' }}>{option.name}</div> */}
                        <div style={{ width: '170px' }}>{option.name}</div>
                        <div style={{ width: '100px' }}>{option.price + ' XPF'}</div>
                        <div>
                            <ButtonCallApiConfirm
                                text='Supprimer'
                                Icon={DeleteForever}
                                resource='options'
                                action='deleteOption'
                                parameters={{ optionGroupId: optionGroupData._id, optionId: option._id }}
                                messageConfirm={`L’option ${option.name} sera supprimée définitivement pour tous les articles auxquels la catégorie d’option ${optionGroupData.name} est associée`}
                                successAction={data => {
                                    const cp = { ...optionGroupData };
                                    cp.list = data.specifications;
                                    setOptionGroupData(cp);
                                    refetch();
                                }}
                                isCallAPI={isCalledAPI}
                                setCallAPI={setIsCalledAPI}
                            />
                        </div>
                        <div>
                            <ButtonCallApiConfirm
                                text='Attacher'
                                Icon={Link}
                                resource='options'
                                action='linkOptionToArticles'
                                parameters={{
                                    optionGroupId: optionGroupData._id,
                                    optionId: option._id,
                                    is_active: true
                                }}
                                messageConfirm={`L’option ${option.name} sera rattachée à tous les articles auxquels la catégorie d’option ${optionGroupData.name} est associée`}
                                successAction={data => {
                                    notify('Rattachement avec succès');
                                }}
                                isCallAPI={isCalledAPI}
                                setCallAPI={setIsCalledAPI}
                            />
                        </div>
                        <div>
                            <ButtonCallApiConfirm
                                text='Détacher'
                                Icon={LinkOff}
                                resource='options'
                                action='linkOptionToArticles'
                                parameters={{
                                    optionGroupId: optionGroupData._id,
                                    optionId: option._id,
                                    is_active: false
                                }}
                                messageConfirm={`L’option ${option.name} sera détachée de tous les articles auxquels la catégorie d’option ${optionGroupData.name} est associée`}
                                successAction={data => {
                                    notify('Détachement avec succès');
                                }}
                                isCallAPI={isCalledAPI}
                                setCallAPI={setIsCalledAPI}
                            />
                        </div>
                    </div>
                ))
            ) : (
                <div className='menuTitle'>{t('notYetOption')}</div>
            )}

            <div className='menuTitle'>{t('addOptionLabel')}</div>

            <div className='optionsInputLine'>
                <input
                    type='text'
                    value={optionName}
                    onChange={e => {
                        setOptionName(e.target.value);
                    }}
                    placeholder={t('placeHolderNewOptionName')}
                />
                <input
                    type='number'
                    value={addPrice}
                    onChange={e => {
                        setAddPrice(e.target.value);
                    }}
                    placeholder={t('placeHolderNewOptionPrice')}
                />
                <AddBox
                    color='error'
                    fontSize='large'
                    onClick={() => {
                        addOption();
                    }}
                    className={`optionIcon ${disabled ? 'optionDisabled' : 'optionEnable'}`}
                />
            </div>
        </div>
    );
};

export default withTranslation()(OptionGroupEdit);
