import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useEffect, useState } from 'react';
import { useLogout } from 'react-admin';
import logoLogout from '../assets/logout.png';
import './ModalInput.css';
import { REACT_APP_API_URL } from '../config';

const apiUrl = REACT_APP_API_URL;

const ModalNonApprouve = () => {
    const [data, setData] = useState({});
    const logout = useLogout();

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch(`${apiUrl}/api/admin/check_app_keys`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ type: 7, device_type: 'android' })
            });

            const json = await result.json();
            setData(json);
        };

        fetchData();
    }, []);

    const { admin_contact_phone_number, admin_contact_email } = data;

    return (
        <Dialog open={true}>
            <div className='ModalNonApprouve'>
                <div className='ModalNonApprouve_Text'>
                    <div className='ModalNonApprouve_Info'>
                        <p>Votre compte est actuellement désactivé.</p>
                        <p>Afin de l'activer merci de contacter le support commercial :</p>
                    </div>
                    <div className='ModalNonApprouve_Contact'>
                        <ul>
                            <li>
                                <span style={{ fontWeight: 'bold' }}>Par téléphone :</span>
                                <span>{admin_contact_phone_number}</span>
                            </li>
                            <li>
                                <span style={{ fontWeight: 'bold' }}>Par email :</span>
                                <span>{admin_contact_email}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='ModalNonApprouve_Button'>
                    <Button
                        style={{ margin: 0, color: 'white' }}
                        onClick={() => {
                            logout();
                        }}
                        startIcon={<img src={logoLogout} />}
                    >
                        Déconnexion
                    </Button>
                </div>
            </div>
        </Dialog >
    );
};

export default ModalNonApprouve;
