import { useContext, useEffect, useState } from 'react';
import { DataProviderContext, useNotify, Toolbar } from 'react-admin';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';

const ToolbarSchedule = ({ t, form, setForm, setModified }) => {
    const [disabled, setDisabled] = useState(false);
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    useEffect(() => {
        let err = false;
        form.map(day => {
            let errotFilterred = day.errors.filter(function (err) {
                return err !== null;
            });
            if (errotFilterred.length > 0) {
                err = true;
            }
        });
        if (err === true) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [form]);

    const updateSchedule = async () => {
        try {
            let days = JSON.parse(JSON.stringify(form));
            let dayToUpdate = days.map(day => {
                delete day.errors;
                return day;
            });
            const { data } = await dataProvider.customApiCall('schedule', {
                action: 'setStoreTime',
                store_time: dayToUpdate
            });
            if (!data.success) {
                notify('there was an error');
            } else {
                setModified(false);
                notify(t('notifySuccesScheduleUpdate'));
                // Following lines are used to update the form with the data received from the back
                let newState = JSON.parse(JSON.stringify(data.store.store_time));
                newState.map(day => (day.errors = []));
                setForm(newState);
            }
        } catch (e) {
            console.log(e, 'error');
            notify('there was an api error');
        }
    };
    return (
        <div className='saveButton'>
            <Toolbar>
                <Button disabled={disabled} onClick={updateSchedule} variant='contained' color='primary'>
                    {t('validateLabel')}
                </Button>
            </Toolbar>
        </div>
    );
};

export default withTranslation()(ToolbarSchedule);
