import { REACT_APP_API_URL } from '../config';

const authProvider = {
    login: ({ email, password }) => {
        const request = new Request(`${REACT_APP_API_URL}/api/store/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password, login_by: 'manuel', social_id: '' }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if (!auth.success) {
                    throw new Error('login fails');
                } else {
                    localStorage.setItem('store_data', JSON.stringify(auth.store));
                    localStorage.setItem('store_id', auth.store._id);
                    localStorage.setItem('store_token', auth.store.server_token);
                }
            })
            .catch(() => {
                throw new Error('Login Fails');
            });
    },
    checkAuth: () => {
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem('store_token')) {
                reject('auth Ko');
            }
            resolve('auth ok');
        }).catch(() => {
            throw new Error('Auth Fail');
        });
    },
    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem('store_data');
        localStorage.removeItem('store_id');
        localStorage.removeItem('store_token');
        return Promise.resolve();
    }
};

export default authProvider;
