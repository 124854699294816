import Menu from "./Menu";
import { Layout } from "react-admin";
import { AppLocationContext } from "@react-admin/ra-navigation";
import ModalErrorApproved from "./ModalErrorApproved";

const getStoreIsApproved = () => {
  try {
    const storeData = JSON.parse(localStorage.store_data);
    return storeData.is_approved ? false : true;
  } catch (e) {
    console.error('store_data is not a valid JSON string:', e);
    return false;
  }
}

const CustomLayout = (props) => {
  const isShowModalError = getStoreIsApproved();

  return (
    <AppLocationContext>
      {isShowModalError && <ModalErrorApproved />}
      <Layout {...props} menu={Menu} />
    </AppLocationContext>
  );
};

export default CustomLayout;
