import GoogleMapReact from 'google-map-react';
import { DirectionsBike, Fastfood, Home, Phone } from '@material-ui/icons';
import { useState } from 'react';
import Marker from './GmapsMarker';
import { withTranslation } from 'react-i18next';
import HomeIcon from '../assets/home.png';
import CarIcon from '../assets/car.png';
import StoreIcon from '../assets/store.png';


const Gmaps = ({ record,t }) => {
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [infoBubble, setInfoBubble] = useState(null);

    const destination = record ? record.destination_addresses[0] : null;
    const pickup = record ? record.pickup_addresses[0] : null;
    const provider = record ? record.provider_detail : null;
    const phone = record ? record.user_detail.phone : null;

    if (map && maps && destination && pickup) {
        const bounds = new maps.LatLngBounds();
        bounds.extend(new maps.LatLng(destination.location[0], destination.location[1]));
        bounds.extend(new maps.LatLng(pickup.location[0], pickup.location[1]));
        if (provider) {
            bounds.extend(new maps.LatLng(provider.location[0], provider.location[1]));
        }
        map.fitBounds(bounds);
    }

    return (
        // Important! Always set the container height explicitly
        <div className='gmapsContainer'>
            <div className='gmapsAddressContainer'>
                {!destination && (
                    <div className='gmapsDeliveryHeaderDefault'>
                        <Home />
                        <span className='gmapsTitle'>{t('deliveryAdressText')}</span>
                    </div>
                )}
                <div>
                    {destination ? (
                        <>
                            <Home style={{ fontSize: 14 }} /> {destination.address}
                        </>
                    ) : (
                        'select a delivery'
                    )}
                </div>
                <div>
                    {' '}
                    {phone ? (
                        <>
                            <Phone style={{ fontSize: 16 }} /> {phone}
                        </>
                    ) : null}
                </div>
            </div>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDWOkPsOo9gWN_8AZYWv3P5TZ5SwE5y26A' }}
                defaultCenter={{
                    lat: -22.248946765407986,
                    lng: 166.46014461145876
                }}
                defaultZoom={11}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                    setMap(map);
                    setMaps(maps);
                }}
            >
                {!!destination && (
                    <Marker
                        lat={destination.location[0]}
                        lng={destination.location[1]}
                        info={destination}
                        Icon={HomeIcon}
                        num={1}
                        setInfoBubble={setInfoBubble}
                        infoBubble={infoBubble}
                        color='red'
                    />
                )}
                {!!pickup && (
                    <Marker
                        lat={pickup.location[0]}
                        lng={pickup.location[1]}
                        info={pickup}
                        Icon={StoreIcon}
                        num={2}
                        setInfoBubble={setInfoBubble}
                        infoBubble={infoBubble}
                        color='blue'
                    />
                )}
                {!!provider && (
                    <Marker
                        lat={provider.location[0]}
                        lng={provider.location[1]}
                        info={provider}
                        Icon={CarIcon}
                        num={3}
                        setInfoBubble={setInfoBubble}
                        infoBubble={infoBubble}
                        color='green'
                    />
                )}
            </GoogleMapReact>
        </div>
    );
};

export default withTranslation()(Gmaps);
