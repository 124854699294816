import React, { useState } from 'react';
import { BooleanInput, minValue, number, NumberInput, required } from 'react-admin';
import { withTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import './Cards.css';

const CardDelivery = ({ t }) => {
    const { values } = useFormState();
    const [takeDelivery, setTakeDelivery] = useState(values.is_store_pay_delivery_fees);
    const [deliverEverywhere, setDeliverEverywhere] = useState(values.is_provide_delivery_anywhere);
    const validateNotnegative = [number(), minValue(0, t('errorNotNegative'))];
    const validateNotnegativeAndRequired = [
        number(),
        minValue(0.1, t('errorNotNegative')),
        required(t('fieldRequire'))
    ];

    return (
        <>
            <div className='cardParameter'>
                <div className='headerParameter'>
                    <span className='titleParameter'>{t('titleDelivery')}</span>
                </div>
                <div className='rowInput'>
                    <span>{t('takeDeliveryInCharge')}</span>
                    <BooleanInput
                        onChange={event => setTakeDelivery(event)}
                        label=''
                        source='is_store_pay_delivery_fees'
                    />
                </div>
                {takeDelivery && (
                    <>
                        <div className='rowInput'>
                            <span>{t('labelFreeDeliveryPrice')}</span>
                            <NumberInput
                                validate={validateNotnegative}
                                min={0}
                                label={false}
                                helperText={t('inXpf')}
                                source='free_delivery_for_above_order_price'
                            />
                        </div>
                        <div className='rowInput'>
                            <span>{t('labelFreeDeliveryRadius')}</span>
                            <NumberInput
                                validate={validateNotnegative}
                                min={0}
                                label={false}
                                helperText={t('inKm')}
                                source='free_delivery_within_radius'
                            />
                        </div>
                    </>
                )}
                <div className='rowInput'>
                    <span>{t('minDeliveryTimeLabel')}</span>
                    <NumberInput
                        validate={validateNotnegativeAndRequired}
                        min={0}
                        label={false}
                        helperText={t('inMinute')}
                        source='delivery_time'
                    />
                </div>
                <div className='rowInput'>
                    <span>{t('maxDeliveryTimeLabel')}</span>
                    <NumberInput
                        validate={validateNotnegativeAndRequired}
                        min={0}
                        label={false}
                        helperText={t('inMinute')}
                        source='delivery_time_max'
                    />
                </div>
                <div className='rowInput'>
                    <span>{t('authorizeClientGetCommand')}</span>
                    <BooleanInput label='' source='is_provide_pickup_delivery' />
                </div>
                {localStorage.getItem('isAdmin') === 'true' && (
                    <div className='rowInput'>
                        <span>{t('deliveryAllArea')}</span>
                        <BooleanInput
                            onChange={event => setDeliverEverywhere(event)}
                            label=''
                            source='is_provide_delivery_anywhere'
                        />
                    </div>
                )}
                {(!deliverEverywhere && localStorage.getItem('isAdmin') === 'true') &&  (
                    <div className='rowInput'>
                        <span>{t('radiusDelivery')}</span>
                        <NumberInput
                            validate={validateNotnegativeAndRequired}
                            n={0}
                            label={false}
                            source='delivery_radius'
                        />
                    </div>
                )}
            </div>
        </>
    );
};
export default withTranslation()(CardDelivery);
