import { MultiLevelMenu, MenuItem } from '@react-admin/ra-navigation';
import styles from './Menu.css';
import { REACT_APP_API_URL_NEEDEAT } from '../config';
import { makeStyles } from '@material-ui/core/styles';
import logoNeedeat from '../assets/logoNeedeat.png';

// Icons
import {
    DirectionsBike,
    People,
    AddBox,
    Dashboard,
    Redeem,
    MenuBook,
    AccessTimeRounded,
    AccountCircle
} from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

const Menu = ({ t }) => {
    const LSStoreData = localStorage.getItem('store_data');
    const imgSrc = LSStoreData && LSStoreData !== 'undefined' ? JSON.parse(LSStoreData).image_url : null;
    const name = LSStoreData && LSStoreData !== 'undefined' ? JSON.parse(LSStoreData).name : null;
    return (
        <div className='containerAllMenu'>
            <MultiLevelMenu>
                <div className='LogoContainer'>
                    <img src={REACT_APP_API_URL_NEEDEAT + '/' + imgSrc} />
                    <div className='menuTitleStore'>{name}</div>
                </div>
                <MenuItem
                    className='menu-item'
                    button
                    name='dashboard'
                    to='/'
                    exact
                    label={t('labelDashboard')}
                    icon={<Dashboard />}
                />
                <MenuItem
                    className='menu-item'
                    name='commandes'
                    to='/orders'
                    label={t('labelCommande')}
                    icon={<AddBox />}
                />
                <MenuItem
                    className='menu-item'
                    name='livraisons'
                    to='/deliveries'
                    label='Livraisons'
                    icon={<DirectionsBike />}
                />
                <MenuItem
                    className='menu-item'
                    name='history'
                    to='/history'
                    label={t('labelHistoric')}
                    icon={<People />}
                />
                <MenuItem className='menu-item' name='promos' to='/promos' label={t('labelPromos')} icon={<Redeem />} />
                <MenuItem
                    className='menu-item'
                    name='parameters'
                    to={`/parameters/${localStorage.getItem('store_id')}`}
                    label={t('labelParameters')}
                    icon={<AccessTimeRounded />}
                >
                    <MenuItem
                        className='menu-item'
                        name='parameters'
                        to={`/parameters/${localStorage.getItem('store_id')}`}
                        label={t('generalSettings')}
                    />
                    <MenuItem
                        className='menu-item'
                        name='schedule'
                        to={`/schedule/${localStorage.getItem('store_id')}`}
                        label={t('labelSchedule')}
                    />
                </MenuItem>
                <MenuItem
                    className='menu-item'
                    name='categories'
                    to='/categories'
                    label={t('labelMenu')}
                    icon={<MenuBook />}
                >
                    <MenuItem className='menu-item' name='categories' to='/categories' label={t('labelArticle')} />
                    {/* <MenuItem name='articles' to='/articles' label='Articles' /> */}
                    <MenuItem className='menu-item' name='options' to='/options' label={t('labelArticleOption')} />
                </MenuItem>
                <MenuItem
                    className='menu-item'
                    name='account'
                    to={`/account/${localStorage.getItem('store_id')}`}
                    icon={<AccountCircle />}
                    label={t('modifAccountLabel')}
                />
                <div className='imgLogoMenuContainer'>
                    <img className='imgLogoMenu' src={logoNeedeat} />
                </div>
            </MultiLevelMenu>
        </div>
    );
};

export default withTranslation()(Menu);
