import { useState } from 'react';
import { AddCircleOutline } from '@material-ui/icons';
import ArticleEditImages from './components/ArticleEditImages';
import {
    TextInput,
    NumberInput,
    Toolbar,
    SaveButton,
    BooleanInput,
    useGetList,
    SelectInput,
    SimpleForm,
    useNotify,
    required
} from 'react-admin';
import CheckboxCustomField from '../../components/InputCheckBox';
import { EditDialog } from '@react-admin/ra-form-layout';
import './ArticleEdit.css';
import { useFormState } from 'react-final-form';
import { AccordionSection } from '@react-admin/ra-form-layout';
import { DeleteForever } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import ArticleOptionComponent from './components/ArticleOptionComponent';
import ArticleFourPrices from './components/ArticleFourPrices';
import ArticleFourPricesSpecial from './components/ArticleFourPricesSpecial';
import ArticleOptionRange from './components/ArticleOptionRange';
import ArticleTime from './components/ArticleTime';

const CustomToolbar = props => {
    return (
        <div className='saveButton'>
            <Toolbar {...props}>
                <SaveButton icon={<></>} label={'enregistrer'} />
            </Toolbar>
        </div>
    );
};

const EditContainerComponent = ({ t }) => {
    // validation
    const validateNotEmpty = [required(t('emptyError'))];

    // data
    const optionGroups = useGetList('options').data;
    const { values } = useFormState();
    const [expandedOptionGroup, setExpandedOptionGroup] = useState(null);
    const [refreshMe, setRefreshMe] = useState(false);
    const reFreshComponent = () => setRefreshMe(!refreshMe);
    const notify = useNotify();

    // We have to create states to manipulate prices because changing one will affect two others
    const [allPrices, setAllPrices] = useState({
        price_ttc: null,
        tax: null,
        price: null,
        tax_currency: null
    });
    // This is to activate one of the 4 prices for changing. otherwise all fields are disabled
    const [disabledFields, setDisabledFields] = useState([true, true, true, true]);

    // This takes care of the preOrder time
    const canPreOrder = JSON.parse(localStorage.getItem('store_data')).is_taking_schedule_order;

    /* # # # # # # # # # # OPTIONS HANDLING # # # # # # # # # # # # # # #  */
    // options to propose to add. (take all options available and remove the ones already selected)
    let optionsGroupsToAdd;

    if (Object.values(optionGroups).length === 0) {
        optionsGroupsToAdd = 'noOptions';
    } else {
        if (!values.specifications) {
            optionsGroupsToAdd = Object.values(optionGroups);
        } else {
            optionsGroupsToAdd = Object.values(optionGroups).filter(
                opt => values.specifications.findIndex(opt2 => opt2?._id && opt2._id === opt._id) === -1
            );
            if (optionsGroupsToAdd.length === 0) {
                optionsGroupsToAdd = 'noMoreOptions';
            }
        }
    }
    const addOptionGroup = () => {
        const newOptionGroup = {
            is_required: false,
            range: 0,
            max_range: null,
            name: optionGroups[values.idOptionToAdd].name,
            type: 2,
            unique_id: optionGroups[values.idOptionToAdd].unique_id,
            _id: values.idOptionToAdd,
            list: optionGroups[values.idOptionToAdd].list.map(opt => {
                return {
                    is_default_selected: opt.is_default_selected,
                    is_user_selected: true,
                    name: opt.name,
                    // price: opt.price, // OLD TAX SYSTEM
                    price_ttc: opt.price,
                    tax_currency: values.tax ? Math.round(opt.price - opt.price / ((values.tax + 100) / 100)) : 0,
                    unique_id: opt.unique_id,
                    _id: opt._id
                };
            })
        };

        if (!Array.isArray(values.specifications)) {
            values.specifications = [newOptionGroup];
        } else {
            values.specifications.push(newOptionGroup);
        }

        setExpandedOptionGroup(values.idOptionToAdd);
        values.idOptionToAdd = null;
        notify("n'oubliez pas d'enregistrer pour sauver les modifications");
    };
    const deleteOptionGroup = id => {
        values.specifications = values.specifications.filter(opg => opg._id !== id);
        // update this state is an excuse to update the component with the new values
        setExpandedOptionGroup(null);
        notify("n'oubliez pas d'enregistrer pour sauver les modifications");
    };

    const [moveOptions, setMoveOptions] = useState(false);
    const changeOptionGroupPlace = drop => {
        const specsCopy = JSON.parse(JSON.stringify(values.specifications));
        const removed = specsCopy.splice(drop.removedIndex, 1)[0];
        specsCopy.splice(drop.addedIndex, 0, removed);
        values.specifications = specsCopy;
        setMoveOptions(!moveOptions);
        reFreshComponent();
    };
    return (
        <div
            className='articleEditContainer'
            onClick={() => {
                setDisabledFields([true, true, true, true]);
            }}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
        >
            {/* # # # # # # # # # # BLOCK NAME AND PRICES # # # # # # # # # # # # # # #  */}
            <div className='articleBlockFirst'>
                <div className='articleTitle'> Propriétés de l'article</div>
                <TextInput validate={validateNotEmpty} source='name' label={t('labelArticleName')} />
                {values && (
                    <ArticleFourPrices
                        values={values}
                        allPrices={allPrices}
                        setAllPrices={setAllPrices}
                        disabledFields={disabledFields}
                        setDisabledFields={setDisabledFields}
                    />
                )}
                <div style={{ height: '15px' }}></div>
                <TextInput source='details' label={t('labelDescriptionArticle')} multiline style={{ width: '100%' }} />
                <BooleanInput
                    source='is_item_in_stock'
                    label={t('labelIsInStock')}
                    multiline='true'
                    style={{ width: '100%' }}
                />
                <div className='articlePreOrderTime'>
                    <BooleanInput source='pre_order_time' label='Précommander ?' disabled={!canPreOrder} />
                    {!canPreOrder && (
                        <div style={{ width: '150px' }} className='articleNote'>
                            Vous devez d'abord autoriser la précommande dans vos paramêtres
                        </div>
                    )}
                    {values.pre_order_time && (
                        <NumberInput
                            disabled={!values.pre_order_time}
                            source='pre_order_time_hours'
                            label='commander avant (heures)'
                            step={0.1}
                            style={{ width: '210px' }}
                            parse={v => (!v ? v : Math.round(10 * v) / 10)}
                        />
                    )}
                </div>
                {values && <ArticleTime values={values} />}
            </div>

            {/* # # # # # # # # # # BLOCK OPTIONS # # # # # # # # # # # # # # #  */}

            <div className='articleBlockLast'>
                <div className='articleTitle'>{t('optionLabel')}</div>

                {/* <Container onDrop={drop => changeOptionGroupPlace(drop)} lockAxis='y'> */}
                {values.specifications &&
                    Array.isArray(values.specifications) &&
                    values.specifications.map((optionGroup, i) => {
                        return (
                            // <Draggable key={i}>
                            <AccordionSection
                                defaultExpanded={() => {
                                    if (expandedOptionGroup === optionGroup._id) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }}
                                fullWidth={true}
                                label={optionGroup?.name || ''}
                                key={i}
                                className='articleAccordion'
                            >
                                <div className='containerAccordionArticleEdit' key={i}>
                                    <div className='articleOneLine'>
                                        <TextInput source={`specifications[${i}].name`} label={t('labelOptionName')} />
                                    </div>
                                    {values && <ArticleOptionRange values={values} i={i} moveOptions={moveOptions} />}
                                    <div className='articleTitle'> {t('addOptionLabel')}</div>
                                    <div className='articleOptionsContainer'>
                                        <div className='articleOptionsTitles'>
                                            <div className='articleOptionTitle' style={{ width: '40px' }}>
                                                option proposée
                                            </div>
                                            <div className='articleOptionTitle'>nom de l'option</div>
                                            <div className='articleOptionTitle'>Prix TTC</div>
                                            <div className='articleOptionTitle'>TGC (XPF)</div>
                                            <div className='articleOptionTitle'>Taxe manuelle</div>
                                        </div>
                                        {optionGroup.list.map((item, j) => {
                                            return (
                                                <div key={j} className='articleOptionLine'>
                                                    <CheckboxCustomField
                                                        name={`specifications[${i}].list[${j}].is_user_selected`}
                                                    />

                                                    <TextInput
                                                        source={`specifications[${i}].list[${j}].name`}
                                                        label=''
                                                        disabled
                                                    />
                                                    {values && (
                                                        <ArticleOptionComponent
                                                            values={values}
                                                            i={i}
                                                            j={j}
                                                            tax={allPrices.tax}
                                                            listValue={'list'}
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })}
                                        {optionGroup?.listNotSelected &&
                                            optionGroup.listNotSelected.length > 0 &&
                                            optionGroup.listNotSelected.map((item, j) => {
                                                return (
                                                    <div key={j} className='articleOptionLine'>
                                                        <CheckboxCustomField
                                                            name={`specifications[${i}].listNotSelected[${j}].is_user_selected`}
                                                        />

                                                        <TextInput
                                                            source={`specifications[${i}].listNotSelected[${j}].name`}
                                                            label=''
                                                            disabled
                                                        />
                                                        {values && (
                                                            <ArticleOptionComponent
                                                                values={values}
                                                                i={i}
                                                                j={j}
                                                                tax={allPrices.tax}
                                                                listValue={'listNotSelected'}
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div className='buttonAddOption'>
                                        <button
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                deleteOptionGroup(optionGroup._id);
                                            }}
                                        >
                                            <DeleteForever />
                                            <span>
                                                {t('textSuppressGroupOf')} {values.specifications[i].name}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </AccordionSection>
                            // </Draggable>
                        );
                    })}
                {/* </Container> */}
                <div className='articleAddOptions'>
                    {optionsGroupsToAdd === 'noOptions' ? (
                        <div>Vous devez d'abord créer des options dans la page "Options d'articles"</div>
                    ) : optionsGroupsToAdd === 'noMoreOptions' ? (
                        <div>toutes les options disponibles sont sélectionnées</div>
                    ) : (
                        <>
                            <div className='articleTitle'>{t('addGroupOptions')}</div>
                            <SelectInput
                                source='idOptionToAdd'
                                choices={optionsGroupsToAdd.map(option => {
                                    return { id: option._id, name: option.name };
                                })}
                                allowEmpty={true}
                                label={t('labelSelectGroup')}
                            />
                        </>
                    )}
                </div>

                {values.idOptionToAdd && (
                    <div className='buttonAddOption'>
                        <button
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                addOptionGroup();
                            }}
                        >
                            <AddCircleOutline />
                            <span>{t('addInOption')}</span>
                        </button>
                    </div>
                )}
            </div>
            {/* # # # # # # # # # # BLOCK IMAGES # # # # # # # # # # # # # # #  */}

            <div className='articleBlock'>
                <ArticleEditImages t={t} values={values} reFreshComponent={reFreshComponent} />
            </div>
        </div>
    );
};

const ArticleEdit = props => {
    // this state will contain the cropped image and its url as a blob
    // it's passed to the dataProvider through transform
    const t = props.t;

    const validateCreation = values => {};

    const transform = data => {
        // this code was created to match with legacy system : is_user_selected false means don't even record it in database.
        // so to remember the ones that are not user_selected, I create a listNotSelected.
        // Check out dataProviderArticles for the way to join this two tables in one and show it normaly in this page
        data.specifications.forEach(s => {
            // to match legaacy system :
            if (s.max_range === s.range || Number(s.max_range) === 0) {
                s.max_range = null;
            }
            // special case if one option mandatory. to make it work with legacy app
            if (s.range === 1 && s.max_range === null) {
                s.type = 1;
            } else {
                s.type = 2;
            }

            // delete the ones that are not user_selected and save them in listNotSelected
            const newListNotSelected = [
                ...(Array.isArray(s.list) ? s.list.filter(opt => !opt.is_user_selected) : []),
                ...(Array.isArray(s.listNotSelected) ? s.listNotSelected.filter(opt => !opt.is_user_selected) : [])
            ];
            const newList = [
                ...(Array.isArray(s.list) ? s.list.filter(opt => opt.is_user_selected) : []),
                ...(Array.isArray(s.listNotSelected) ? s.listNotSelected.filter(opt => opt.is_user_selected) : [])
            ];
            s.listNotSelected = newListNotSelected;
            s.list = newList;

            // and to match old system that uses price and not price_ttc
            s.list.forEach(opt => {
                opt.price = (opt.price_ttc || 0) - (opt.tax_currency || 0);
            });
        });
        return data;
    };

    return (
        <EditDialog
            mutationMode='pessimistic'
            {...props}
            transform={transform}
            maxWidth={false}
            title={<div className='titleToolbar'>{t('titleEditArticle')}</div>}
            className='allContainerEditArticle'
        >
            <SimpleForm validate={validateCreation} toolbar={<CustomToolbar {...props} />}>
                <EditContainerComponent {...props} t={t} />
            </SimpleForm>
        </EditDialog>
    );
};

export default withTranslation()(ArticleEdit);
