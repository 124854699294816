import { useEffect, useState } from 'react';

import './ArticleTimeModification.css';
import { Dialog, TextField } from '@material-ui/core';
import { Delete, Close } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';

const errorTranslate = {
    1: 'errorScheduleFour',
    2: 'errorScheduleThree',
    3: 'errorScheduleOne',
    4: 'errorScheduleTwo',
    5: 'errorScheduleFive'
};

const ArticleTimeModification = ({ t, open, onValidate, onClose, originListTimes }) => {
    const [listTimes, setListTimes] = useState([]);
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        const listTimesWithErrors = originListTimes.map(ele => ({
            ...ele,
            errors: []
        }));
        setListTimes(listTimesWithErrors);
    }, [originListTimes]);

    useEffect(() => {
        let errorsAll = [];
        listTimes.forEach((times, index) => {
            const listCompare = [...listTimes];
            listCompare.splice(index, 1);
            const errors = checkTimeInput(times.open_time, times.close_time, listCompare);
            errorsAll = [...errorsAll, ...errors];
        });
        setIsValidated(Array.from(new Set(errorsAll)).length > 0 ? false : true);
    }, [listTimes]);

    const onCloseModal = () => {
        const listTimesWithErrors = originListTimes.map(ele => ({
            ...ele,
            errors: []
        }));
        setListTimes(listTimesWithErrors);
        onClose();
    };

    const checkIfBetween = (start, end, allTimes) => {
        let errors = [];
        const timeOpen = start.split(':');
        const timeClose = end.split(':');
        const nbMinuteMin = timeOpen[0] * 60 + timeOpen[1];
        const nbMinuteMax = timeClose[0] * 60 + timeClose[1];
        for (const times of allTimes) {
            const splitScheduleOpen = times.open_time.split(':');
            const nbMinScheduleOpen = splitScheduleOpen[0] * 60 + splitScheduleOpen[1];
            const splitScheduleClose = times.close_time.split(':');
            const nbMinScheduleClose = splitScheduleClose[0] * 60 + splitScheduleClose[1];
            if (
                parseInt(nbMinuteMin) > parseInt(nbMinScheduleOpen) &&
                parseInt(nbMinScheduleClose) > parseInt(nbMinuteMin)
            ) {
                errors.push(3);
            }
            if (
                parseInt(nbMinuteMax) > parseInt(nbMinScheduleOpen) &&
                parseInt(nbMinScheduleClose) > parseInt(nbMinuteMax)
            ) {
                errors.push(4);
            }
            if (
                parseInt(nbMinuteMin) <= parseInt(nbMinScheduleOpen) &&
                parseInt(nbMinuteMax) >= parseInt(nbMinScheduleClose)
            ) {
                errors.push(5);
            }
        }
        return errors;
    };

    const checkTimeInput = (start, end, allTimes) => {
        const errors = [];
        if (!start || !end) {
            errors.push(1);
        } else {
            if (start >= end) {
                errors.push(2);
            }
        }
        const errorsBtw = checkIfBetween(start, end, allTimes);
        return [...errors, ...errorsBtw];
    };
    const handleChangeTime = (event, id) => {
        const name = event.target.name;
        const value = event.target.value;
        const newListTimes = [...listTimes];
        if (name === 'updateArticleTimeOpen') {
            newListTimes[id].open_time = value;
        }
        if (name === 'updateArticleTimeClose') {
            newListTimes[id].close_time = value;
        }
        const listToCompare = [...newListTimes];
        listToCompare.splice(id, 1);
        const listErrors = new Set(
            checkTimeInput(newListTimes[id].open_time, newListTimes[id].close_time, listToCompare)
        );
        newListTimes[id].errors = Array.from(listErrors);
        setListTimes(newListTimes);
    };

    const handleDeleteTime = id => {
        const newListTimes = [...listTimes];
        newListTimes.splice(id, 1);
        setListTimes(newListTimes);
    };

    const onClickAddNewTime = () => {
        const newListTimes = [
            ...listTimes,
            {
                open_time: '',
                close_time: '',
                errors: []
            }
        ];
        setListTimes(newListTimes);
    };

    const onValidateTime = () => {
        onValidate(listTimes);
    };

    return (
        <Dialog open={open} onClose={onCloseModal}>
            <div className='ArticleModalContainer'>
                <div className='ArticleModalTitleWrapper'>
                    <div className='ArticleModalTitle'>{t('articleModalTitle')}</div>
                    <div className='ArticleModalClose'>
                        <Close onClick={onCloseModal} />
                    </div>
                </div>
                <div className='ArticleModalWrapper'>
                    <div className='ArticleModalRowButton'>
                        <div>
                            {listTimes.length !== 0 &&
                                listTimes.map((times, index) => {
                                    return (
                                        <div key={`wrapper-${index}`} className='ArticleModalRowParent'>
                                            <div key={`a-${index}`} className='ArticleModalRowSchedules'>
                                                <div key={`b-${index}`} className='ArticleModalWrapperTextField'>
                                                    <TextField
                                                        key={`b-${index}`}
                                                        margin='normal'
                                                        className='ArticleModalAlreadyExistantInput'
                                                        label={t('articleStartTime')}
                                                        error={times.errors && times.errors.length !== 0}
                                                        variant='outlined'
                                                        type='time'
                                                        name='updateArticleTimeOpen'
                                                        value={times.open_time}
                                                        onChange={e => handleChangeTime(e, index)}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            step: 300 // 5 min
                                                        }}
                                                    />
                                                </div>
                                                <div key={`d-${index}`} className='ArticleModalWrapperTextField'>
                                                    <TextField
                                                        key={`e-${index}`}
                                                        margin='normal'
                                                        className='ArticleModalAlreadyExistantInput'
                                                        label={t('articleEndTime')}
                                                        type='time'
                                                        error={times.errors && times.errors.length !== 0}
                                                        name='updateArticleTimeClose'
                                                        variant='outlined'
                                                        onChange={e => handleChangeTime(e, index)}
                                                        value={times.close_time}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            step: 300 // 5 min
                                                        }}
                                                    />
                                                </div>
                                                <Delete
                                                    className='ArticleModalDelete'
                                                    onClick={() => handleDeleteTime(index)}
                                                    style={{ fontSize: 25, color: 'red' }}
                                                />
                                            </div>
                                            {times.errors.length > 0 &&
                                                times.errors.map(err => (
                                                    <div className='ArticleModalError'>{t(errorTranslate[err])}</div>
                                                ))}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className='ArticleButtonWrapper'>
                    <div className='ArticleModalButtonCreate'>
                        <Button onClick={onClickAddNewTime} variant='contained' color='primary'>
                            {t('addSchedule')}
                        </Button>
                    </div>
                    <div className='ArticleModalButtonAdd'>
                        <Button onClick={onValidateTime} disabled={!isValidated} variant='contained' color='primary'>
                            {t('validate')}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default withTranslation()(ArticleTimeModification);
