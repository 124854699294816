import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { addCredentials } from './library';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page } = params.pagination;
        const { search_field, search_value, order_status, order_type, start_date, end_date } = params.filter;
        return httpClient(`${apiUrl}/api/store/history`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    created_by: 'both',
                    start_date: start_date ? { formatted: start_date } : '',
                    end_date: end_date ? { formatted: end_date } : '',
                    order_status_id: '',
                    order_type: 'both',
                    page: page,
                    payment_status: 'all',
                    pickup_type: 'both',
                    search_field: search_field || 'user_detail.first_name',
                    search_value: search_value || ''
                })
            )
        }).then(({ json }) => {
            // CURRENCY SIGN ?
            const data = json.orders
                ? json.orders.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: json.pages * 10
            };
        });
    }
};
