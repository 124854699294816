import {
    Edit,
    SimpleForm,
    useRefresh,
    useNotify,
    TextInput,
    TextField,
    required,
    Toolbar,
    SaveButton,
    email,
    NumberInput,
    FunctionField,
    PasswordInput
} from 'react-admin';
import { withTranslation } from 'react-i18next';
import './account.css';
import CroppingImage from '../../components/CroppingImage';
import { useState } from 'react';
import AddressAutoComplete from '../../components/AddressAutoComplete';
import { REACT_APP_API_URL_NEEDEAT } from '../../config';
import GmapsStore from '../../components/GmapsStore';

const Account = props => {
    const t = props.t;
    const refresh = useRefresh();
    const notify = useNotify();
    const onSuccess = () => {
        notify('Modifications effectuées');
        // we have to use a setTimeOut here to wait for the image to be uploaded on the server
        setTimeout(() => {
            refresh();
            notify('Si vous avez uploadé une image, rafraichissez la page pour la voir modifiée dans le menu');
        }, 1000);
    };
    const [loc, setLoc] = useState(null);
    const [imgCropped, setImgCropped] = useState(null);
    const [newPwd, setNewPwd] = useState(false);
    const validateNotEmpty = [required(t('emptyError'))];
    const validateEmail = [email(t('emailError')), required(t('emptyError'))];

    const validateAccountModif = values => {
        const errors = {};
        if (newPwd) {
            if (values.new_password === '' || values.new_password === null || values.new_password < 6) {
                errors.new_password = t('errorPasswordLength');
            }
            if (values.new_password !== values.new_password_confirmation) {
                errors.new_password_confirmation = t('errorPasswordConfirm');
            }
        }
        return errors;
    };

    return (
        <div className='defaultPageContainer'>
            <div className='titlePage'>{t('accountTitle')}</div>
            <Edit
                className='allContainerAccountEdit'
                mutationMode='pessimistic'
                title='settings'
                onSuccess={onSuccess}
                {...props}
            >
                <SimpleForm
                    warnWhenUnsavedChanges
                    validate={validateAccountModif}
                    toolbar={<ParameterEditToolbar newPwd={newPwd} loc={loc} img={imgCropped} />}
                >
                    <div className='columnAccount'>
                        <div className='cardAccount'>
                            <div className='titleAccount'>
                                <TextField source='name' />
                            </div>
                            <div className='rowAccountContainer'>
                                <div className='rowFirstCard'>
                                    <div className='rowFieldAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelCountry')}</span>
                                        <TextField source='country_details.country_name' />
                                    </div>
                                    <div className='rowFieldAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelCity')}</span>
                                        <TextField source='city_details.city_name' />
                                    </div>
                                    <div className='rowFieldAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelDeliveryType')}</span>
                                        <TextField source='delivery_details.delivery_name' />
                                    </div>
                                </div>

                                <div className='rowFirstCardSecondColumn'>
                                    <div className='rowFieldAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelRefferalCode')}</span>
                                        <TextField source='referral_code' />
                                    </div>
                                    <div className='rowFieldAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelWalletAmount')}</span>
                                        <TextField source='wallet' />
                                    </div>
                                    <div className='rowFieldAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelTotalReferrals')}</span>
                                        <TextField source='total_referrals' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='separatorAccount' />
                        <div className='cardAccount'>
                            <div className='rowAccountContainer'>
                                <div className='rowFirstCard'>
                                    <div className='rowInputAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelName')}</span>
                                        <div className='autoCompleteAccount'>
                                            <TextInput
                                                validate={validateNotEmpty}
                                                label={t('labelName')}
                                                source='name'
                                                fullWidth={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='rowInputAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelEmail')}</span>
                                        <div className='autoCompleteAccount'>
                                            <TextInput
                                                validate={validateEmail}
                                                label={t('labelEmail')}
                                                source='email'
                                                fullWidth={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='rowInputAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelPhone')}</span>
                                        <div className='autoCompleteAccount'>
                                            <NumberInput
                                                validate={validateNotEmpty}
                                                label={t('labelPhone')}
                                                source='phone'
                                                fullWidth={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='rowSecondCard'>
                                    <div className='rowInputAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelUrl')}</span>
                                        <div className='autoCompleteAccount'>
                                            <TextInput
                                                className={'hideLabelClass'}
                                                fullWidth={true}
                                                label={t('labelUrl')}
                                                source='website_url'
                                            />
                                        </div>
                                    </div>
                                    <div className='rowInputAccount'>
                                        <span className='minWidthAccountLAbel'>{t('labelSlogan')}</span>
                                        <div className='autoCompleteAccount'>
                                            {' '}
                                            <TextInput
                                                className={'hideLabelClass'}
                                                fullWidth={true}
                                                label={t('labelSlogan')}
                                                source='slogan'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='separatorAccount' />
                        <div className='cardAccount'>
                            <div className='mapAndAddress'>
                                <div className='rowInputAccount'>
                                    <span className='minWidthAccountLAbel'>Adresse actuelle</span>
                                    <div className='autoCompleteAccount'>
                                        <TextField source='address' label='adresse' />
                                    </div>
                                </div>
                                <div className='rowInputAccount'>
                                    <div>
                                        <div className='minWidthAccountLAbel'>Rechercher une nouvelle adresse</div>
                                        <div className='minWidthAccountLAbel'>
                                            {'(Entrez le nom de votre restaurant)'}
                                        </div>
                                    </div>
                                    <div className='autoCompleteAccount'>
                                        <AddressAutoComplete setLoc={setLoc} />
                                    </div>
                                </div>
                                <div style={{ width: '100%', height: '100%' }}>
                                    <FunctionField
                                        label='carte'
                                        render={record => {
                                            return (
                                                <GmapsStore
                                                    lat={loc ? loc[0] : record.location ? record.location[0] : null}
                                                    lng={loc ? loc[1] : record.location ? record.location[1] : null}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='separatorAccount' />

                        <div className='accountImg'>
                            <div className='accountImgBlock'>
                                <div className='articleTitle'> Image existante</div>
                                <div style={{ height: '24px' }}></div>
                                <FunctionField
                                    label='image'
                                    render={record => {
                                        return (
                                            <img
                                                className='accountImgPrev'
                                                src={REACT_APP_API_URL_NEEDEAT + '/' + record.image_url}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <CroppingImage setImgCropped={setImgCropped} />
                        </div>
                        <div className='separatorAccount' />
                        {localStorage.getItem('isAdmin') !== 'true' && (
                            <div className='cardParameterPassword'>
                                <div className='headerParameter'>
                                    <span className='titlePasswordParameter'>{t('generalPassword')}</span>
                                </div>
                                <div className='rowInputAccount'>
                                    <span className='minWidthAccountLAbel'>{t('passwordLabel')}</span>
                                    <PasswordInput
                                        validate={validateNotEmpty}
                                        label={t('passwordLabel')}
                                        source='old_password'
                                        fullWidth={true}
                                        helperText={t('passwordMandatory')}
                                    />
                                </div>
                                {!!newPwd ? (
                                    <>
                                        <div className='changePasswordOption' onClick={() => setNewPwd(false)}>
                                            {t('labelDontWantToChangePassword')}
                                        </div>
                                        <div className='rowInputAccount'>
                                            <span className='minWidthAccountLAbel'>{t('passwordLabel')}</span>
                                            <TextInput
                                                className={'hideLabelClass'}
                                                fullWidth={true}
                                                label={t('newPasswordLabel')}
                                                source='new_password'
                                            />
                                        </div>
                                        <div className='rowInputAccount'>
                                            <span className='minWidthAccountLAbel'>
                                                {t('labelPasswordConfirmLabel')}
                                            </span>
                                            <TextInput
                                                fullWidth={true}
                                                label={t('labelPasswordConfirmLabel')}
                                                source='new_password_confirmation'
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className='changePasswordOption' onClick={() => setNewPwd(true)}>
                                        {t('labelWantToChangePassword')}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </SimpleForm>
            </Edit>
        </div>
    );
};

const ParameterEditToolbar = props => {
    const transform = data => {
        if (props.img !== null && props.img !== undefined) {
            data.image = props.img;
        }
        if (!props.newPwd || props.newPwd === false) {
            data.new_password = null;
        }
        if (props.loc) {
            data.loc = props.loc;
        }
        return data;
    };
    return (
        <div className='saveButton'>
            <Toolbar {...props}>
                <SaveButton icon={<></>} label='Enregistrer' transform={transform} />
            </Toolbar>
        </div>
    );
};

export default withTranslation()(Account);
