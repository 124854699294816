import _get from 'lodash/get';
import _set from 'lodash/get';
import { useState, useEffect, useRef } from 'react';

import { TextField, Checkbox } from '@material-ui/core';
import { useNotify } from 'react-admin';

const ArticleOptionComponent = ({ values, i, j, tax, listValue }) => {
    const infosPrice = _get(values, `specifications.${i}.${listValue}.${j}.price_ttc`);
    const infosTax = _get(values, `specifications.${i}.${listValue}.${j}.tax_currency`);
    const infosManualTax = _get(values, `specifications.${i}.${listValue}.${j}.manualTax`);

    const [priceTTC, setPriceTTC] = useState(Number(infosPrice));
    const [priceTax, setPriceTax] = useState(Number(infosTax));

    const integersOnly = new RegExp('^[0-9]*$');
    const [manualTax, setManualTax] = useState(infosManualTax);

    const firstUpdate = useRef(true);
    const notify = useNotify();

    // note that we use the state prices for display
    // but to record the actual product object, we have to update "value"
    // this is why we always change "value" when prices are modified
    values.specifications[i][listValue][j].price_ttc = priceTTC;
    values.specifications[i][listValue][j].tax_currency = priceTax;
    values.specifications[i][listValue][j].manualTax = manualTax;

    useEffect(() => {
        const priceTTCValueNow = _get(values, `specifications.${i}.${listValue}.${j}.price_ttc`);
        if (isNaN(priceTTCValueNow)) {
            if (i === 0 && j === 0) {
                notify('Attention redefinition auto des prix OPTIONS', {
                    type: 'warning',
                    autoHideDuration: 1500
                });
            }

            // Initialize
            const priceValueNow = _get(values, `specifications.${i}.${listValue}.${j}.price`);
            const newPriceTTC = priceValueNow || 0;
            const newTaxCurr = Math.round(newPriceTTC - newPriceTTC / (((tax || 0) + 100) / 100));

            setPriceTTC(newPriceTTC);
            setPriceTax(newTaxCurr);
            setManualTax(false);
        }
    }, []);

    // This is to modify the option tax if the product tax is changed, when not in manual mode
    // we had to use the useRef firstUpdate trick for it to have an effect only when product tax is changed, and not at component mounting
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (!manualTax) {
            const taxCurr = Math.round(priceTTC - priceTTC / ((Number(tax) + 100) / 100));
            setPriceTax(taxCurr);
        }
    }, [tax]);

    return (
        <>
            <TextField
                style={{ width: '70px', marginTop: '-30px' }}
                value={priceTTC}
                onChange={e => {
                    if (e.target.value.match(integersOnly)) {
                        const priceTTC = Number(e.target.value);
                        const taxCurr = Math.round(priceTTC - priceTTC / ((tax + 100) / 100));
                        if (!manualTax) {
                        }
                        setPriceTTC(priceTTC);
                        if (!manualTax) {
                            setPriceTax(taxCurr);
                        }
                    }
                }}
            />
            <TextField
                style={{ width: '70px', marginTop: '-30px' }}
                disabled={!manualTax}
                value={priceTax}
                onClick={e => {}}
                onChange={e => {
                    if (e.target.value.match(integersOnly)) {
                        const taxCurr = Number(e.target.value);
                        setPriceTax(taxCurr);
                    }
                }}
            />
            <Checkbox
                style={{ width: '70px', marginTop: '-30px' }}
                label='tax manuelle'
                checked={manualTax}
                onChange={e => {
                    setManualTax(e.target.checked);
                    if (!e.target.checked) {
                        const taxCurr = Math.round(priceTTC - priceTTC / ((tax + 100) / 100));
                        values.specifications[i][listValue][j].tax_currency = taxCurr;
                        setPriceTax(taxCurr);
                    }
                }}
            />
        </>
    );
};

export default ArticleOptionComponent;
