import React, { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageInput } from 'react-admin';
// import { imagesCropWidth, imagesCropHeight } from '../config';
import { Cancel } from '@material-ui/icons';
import ButtonSimple from './ButtonSimple';
import { useFormState } from 'react-final-form';

const imagesCropWidth = 900;
const imagesCropHeight = 450;

const PlaceHolder = () => (
    <div className='accountImagePlaceHolder'>
        <p>Cliquez dans ce cadre ou glissez une image, puis redimensionnez</p>
    </div>
);

const PreviewImage = ({ record }) => {
    if (record.url) {
        return <img src={record.url} className='articleImgNoShow' />;
    } else return null;
};

const CroppingImage = ({ setImgCropped, noPreviousImage }) => {
    const { values } = useFormState();
    const imageRef = useRef(null);
    const ratio = imagesCropWidth / imagesCropHeight;
    const [state, setState] = useState({
        on: false,
        croppedImageUrl: undefined,
        blob: undefined,
        crop: null
    });

    const onImageLoaded = e => {
        imageRef.current = e.target;
        let wInit, hInit;
        if (imageRef.current.width / ratio > imageRef.current.height) {
            hInit = imageRef.current.height;
            wInit = imageRef.current.height * ratio;
        } else {
            wInit = imageRef.current.width;
            hInit = imageRef.current.width / ratio;
        }
        const initCrop = {
            unit: 'px',
            width: wInit,
            height: hInit
        };
        setState(prevState => ({
            ...prevState,
            on: true,
            crop: initCrop
        }));
        makeClientCrop(imageRef.current, initCrop);
    };

    const onCropComplete = crop => {
        makeClientCrop(imageRef.current, crop);
    };

    const onCropChange = crop => {
        if (crop.height > imageRef.current.height) {
            crop.width = state.crop.width;
            crop.height = state.crop.height;
        } else if (crop.height !== state.crop.height) {
            crop.width = crop.height * ratio;
        } else {
            crop.height = crop.width / ratio;
        }
        setState(prevState => ({
            ...prevState,
            crop: crop
        }));
    };
    const makeClientCrop = async (imageRef, crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imageRef, crop, 'newFile.png');
            setState(prevState => ({
                ...prevState,
                croppedImageUrl: croppedImageUrl
            }));
        }
    };
    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = imagesCropWidth;
        canvas.height = imagesCropHeight;
        const ctx = canvas.getContext('2d');

        if (ctx) {
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0, // crop.width,
                0, // crop.height,
                imagesCropWidth,
                imagesCropHeight
            );
        }
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                blob => {
                    if (!blob) {
                        return;
                    }
                    blob['name'] = fileName;
                    let fileUrl = state.fileUrl;
                    window.URL.revokeObjectURL(fileUrl);
                    fileUrl = window.URL.createObjectURL(blob);
                    setImgCropped({ blob, fileUrl });
                    setState(prevState => ({
                        ...prevState,
                        blob: blob,
                        fileUrl: fileUrl
                    }));
                    resolve(fileUrl);
                },
                'image/jpeg',
                0.95
            );
        });
    };

    const ButtonCancel = (
        <ButtonSimple
            action={() => {
                window.URL.revokeObjectURL(state.fileUrl);
                setState({
                    on: false,
                    croppedImageUrl: undefined,
                    blob: undefined,
                    crop: null
                });
                setImgCropped(null);
            }}
            text='Annuler'
            Icon={Cancel}
        />
    );

    return (
        <div>
            {/*  # # # # # # # # UPLOAD ONE IMAGE # # # # # # # #  */}
            {!state.on && (
                <div className='accountImgBlock'>
                    <div className='articleTitle'>Ajouter une image</div>
                    <ImageInput
                        source='image_url_to_upload'
                        placeholder={<PlaceHolder />}
                        label=''
                        accept='.jpg,.jpeg,.png'
                        onChange={() => {
                            setState({
                                on: true,
                                croppedImageUrl: undefined,
                                blob: undefined,
                                crop: null
                            });
                        }}
                    >
                        <PreviewImage source='url' />
                    </ImageInput>
                </div>
            )}
            {!!state.on && (
                <div style={{ display: 'flex' }}>
                    <div className='accountImgBlock'>
                        <div className='accountTitleButton'>
                            <div className='articleTitle' style={{ marginTop: 0 }}>
                                Redimensionnez
                            </div>
                            <div className='articleImgCancelButton'>{ButtonCancel}</div>
                        </div>
                        <div style={{ height: '24px' }}></div>
                        <ReactCrop crop={state.crop} ruleOfThirds onComplete={onCropComplete} onChange={onCropChange}>
                            <img width='300px' src={values?.image_url_to_upload?.url} onLoad={onImageLoaded} />
                        </ReactCrop>
                    </div>

                    {/* <div className='accountImgBlock'>
                        <div className='articleTitle'>Image finale</div>
                        <div style={{ height: '24px' }}></div>

                        <img className='accountImgCropped' src={state.croppedImageUrl} width='300px' />
                    </div> */}
                </div>
            )}
        </div>
    );
};

export default CroppingImage;
