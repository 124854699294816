export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_API_URL_NEEDEAT = process.env.REACT_APP_API_URL_NEEDEAT;
// 'http://139.99.221.16';

export const RELOAD_ORDERS_TIME = 10000; // milliseconds
export const CHECK_NEW_ORDERS_TIME = 10000; // milliseconds
export const RELOAD_DELIVERY_TIME = 10000; // milliseconds

export const imagesCropWidth = 500;
export const imagesCropHeight = 400;
export const MAP_ICON_SIZE = '35px';
export const AUTOCOMPLETE_APIKEY = 'AIzaSyDNFD-7eoMIiwhzW7U7jWMeHyXbXW6rQfQ';
