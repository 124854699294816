import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { REACT_APP_API_URL_NEEDEAT } from '../config';
import { addCredentials } from './library';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getOne: resource => {
        return httpClient(`${apiUrl}/api/store/get_store_data`, {
            method: 'POST',
            body: JSON.stringify(addCredentials({}))
        })
            .then(({ json }) => {
                let res = {};
                if (!!json.success) {
                    res.data = json.store_detail;
                    res.data.id = res.data._id;
                    return res;
                }
            })
            .catch(err => {
                console.log(err, 'error setting');
            });
    },
    update: (resource, params) => {
        const formData = new FormData();
        console.log(params.data);
        formData.append('store_id', localStorage.getItem('store_id'));
        formData.append('server_token', localStorage.getItem('store_token'));
        formData.append('phone', params.data.phone);
        formData.append('name', params.data.name);
        formData.append('email', params.data.email);
        formData.append('website_url', params.data.website_url);
        formData.append('slogan', params.data.slogan);
        formData.append('login_by', 'manuel');
        if (params.data.loc) {
            formData.append('latitude', params.data.loc[0]);
            formData.append('longitude', params.data.loc[1]);
            formData.append('address', params.data.loc[2]);
        }
        formData.append('old_password', params.data.old_password);
        if (params.data.new_password) {
            formData.append('new_password', params.data.new_password);
        }
        if (params.data.image) {
            formData.append('image_url', params.data.image.blob, params.data.image.fileUrl);
        }

        // if there s an image we use the legacy address bc there is an image to upload
        const prefix_url = params.data.image ? REACT_APP_API_URL_NEEDEAT : REACT_APP_API_URL;

        let urlToCall;
        if (localStorage.getItem('isAdmin') === 'true') {
            urlToCall = `${prefix_url}/admin/update_store`;
        } else {
            urlToCall = `${prefix_url}/api/store/update`;
        }
        return httpClient(urlToCall, {
            method: 'POST',
            body: formData
        })
            .then(({ json }) => {
                if (json.success === false) {
                    throw Error;
                }
                localStorage.setItem('store_data', JSON.stringify(json.store));
                return {
                    data: { ...json.store, id: json.store.id }
                };
            })
            .catch(e => {
                console.log(e, 'error return');
            });
    },
    create: (resource, params) => {
        const formData = new FormData();
        // formData.append('image_url', params.image_url);
        formData.append('phone', params.phone);
        formData.append('password', params.password);
        formData.append('country_id', params.country_id);
        formData.append('city_id', params.city_id);
        formData.append('social_id', '');
        formData.append('login_by', 'manuel');
        formData.append('store_delivery_id', params.store_delivery_id);
        formData.append('country_phone_code', params.country_phone_code);
        formData.append('name', params.name);
        formData.append('email', params.email);
        formData.append('address', params.address);
        formData.append('latitude', params.latitude);
        formData.append('longitude', params.longitude);
        formData.append('website_url', params.website_url);
        formData.append('slogan', params.slogan);
        formData.append('referral_code', '');
        formData.append('is_phone_number_verified', '');
        formData.append('is_email_verified', false);

        // if (params.data.image) {
        //     formData.append(0, params.data.image.blob, params.data.image.fileUrl);
        // }
        return httpClient(`${apiUrl}/api/store/register`, {
            method: 'POST',
            body: formData
        })
            .then(({ json }) => {
                return {
                    data: json
                };
            })
            .catch(e => {
                console.log(e, 'server error');
            });
    },
    getCountryList: (resource, params) => {
        return httpClient(`${apiUrl}/api/admin/get_country_list`, {
            method: 'GET'
        })
            .then(({ json }) => {
                if (json.success === false) {
                    throw Error;
                }
                return {
                    countries: json.countries
                };
            })
            .catch(e => {
                console.log(e, 'error return');
            });
    },
    getCityList: (resource, params) => {
        return httpClient(`${apiUrl}/api/admin/get_city_list`, {
            method: 'POST',
            body: JSON.stringify({ country_id: params.country_id })
        })
            .then(({ json }) => {
                if (json.success === false) {
                    throw Error;
                }
                return {
                    cities: json.cities
                };
            })
            .catch(e => {
                console.log(e, 'error return');
            });
    },
    getDeliveryList: (resource, params) => {
        return httpClient(`${apiUrl}/api/admin/get_delivery_list_for_city`, {
            method: 'POST',
            body: JSON.stringify({ city_id: params.city_id })
        })
            .then(({ json }) => {
                if (json.success === false) {
                    throw Error;
                }
                return {
                    deliveries: json.deliveries
                };
            })
            .catch(e => {
                console.log(e, 'error return');
            });
    }
};
