import { useRecordContext } from 'react-admin';
import { FlashOn, AccessTime } from '@material-ui/icons';
import { useState } from 'react';
import { DateField } from 'react-admin';

const style = {
    display: 'flex',
    justifyContent: 'center'
};

const FieldType = ({ source }) => {
    const [showTime, setShowTime] = useState(false);
    const record = useRecordContext();
    if (!record) return null;
    if (!record.is_schedule_order)
        return (
            <div style={style}>
                <FlashOn />
            </div>
        );
    return (
        <div
            style={style}
            onClick={e => {
                e.stopPropagation();
                setShowTime(!showTime);
            }}
        >
            {showTime ? (
                <DateField
                    label='Heure de création'
                    source='schedule_order_start_at'
                    showTime
                    sortable={false}
                    options={{
                        // day: '2-digit',
                        // month: 'long',
                        hour: '2-digit',
                        minute: '2-digit'
                    }}
                />
            ) : (
                <AccessTime />
            )}
        </div>
    );
};

export default FieldType;
