import React from 'react';
import { BooleanInput, ArrayInput, NumberInput, SimpleFormIterator, TextInput, TextField } from 'react-admin';
import { withTranslation } from 'react-i18next';

const CardSettings = ({ t }) => {
    return (
        <div className='cardParameter'>
            <div className='headerParameter'>
                <span className='titleParameter'>{t('generalSettings')}</span>
            </div>
            <div className='rowInput'>
                <span>{t('inActivityLabel')}</span>
                <BooleanInput label='' source='is_business' />
            </div>
            {localStorage.getItem('isAdmin') === 'true' && (
                <div className='rowInput'>
                    <span>{t('visibleText')}</span>
                    <BooleanInput label='' source='is_visible' />
                </div>
            )}
        </div>
    );
};
export default withTranslation()(CardSettings);
