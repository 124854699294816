import { useContext, useState } from 'react';
import '../categories/Category.css';
import { List, useListContext, useNotify, DataProviderContext } from 'react-admin';
import ModalInput from '../../components/ModalInput';
import { Dialog } from '@material-ui/core';
import { PlusOne } from '@material-ui/icons';
import OptionGroupEdit from './OptionGroupEdit';
import './option.css';
import { withTranslation } from 'react-i18next';
import OptionCard from './OptionCard';

const OptionGroupsListLayout = props => {
    const t = props.t;
    const { ids, data, refetch } = useListContext();
    const [isHover, setIsHover] = useState(false);
    const [openModalCreate, setOpenModalCreate] = useState(false);
    const [optionGroupToEdit, setOptionGroupToEdit] = useState(null);
    const dataProvider = useContext(DataProviderContext);
    const notify = useNotify();

    const addOptionsGroup = async name => {
        try {
            const { data } = await dataProvider.customApiCall('options', {
                action: 'addOptionGroup',
                name: name
            });
            if (!data.success) {
                notify('there was an error in creation');
                refetch();
            } else {
                notify("Nouvelle catégorie d'option créée");
                const index = data.specification_group.findIndex(el => el.name === name);
                refetch();
            }
        } catch (e) {
            notify('there was an api error');
        }
    };

    return (
        <>
            <div className='catContainer'>
                {ids.map((id, i) => {
                    return <OptionCard t={t} id={id} i={i} setOptionGroupToEdit={setOptionGroupToEdit} />;
                })}
                <div
                    className='catCardAdd'
                    onClick={() => {
                        setOpenModalCreate(true);
                    }}
                >
                    +
                </div>
            </div>
            <ModalInput
                t={t}
                label={t('labelAddCategoryOption')}
                title={t('titleAddCategoryOption')}
                onValidate={name => {
                    addOptionsGroup(name);
                    setOpenModalCreate(false);
                }}
                open={openModalCreate}
                onClose={() => {
                    setOpenModalCreate(false);
                }}
                iconValidate={PlusOne}
                textValidate={t('textCreate')}
            />
            <Dialog
                open={optionGroupToEdit ? true : false}
                onClose={() => {
                    setOptionGroupToEdit(null);
                }}
            >
                <OptionGroupEdit t={t} optionGroupToEdit={optionGroupToEdit} refetch={refetch} />
            </Dialog>
        </>
    );
};

const OptionGroupsList = props => (
    <div className='optionListAllContainer'>
        <div className='defaultPageContainer'>
            <div className='titlePage'>{props.t('titleOptionList')}</div>
            <List title={props.t('titleOption')} {...props} pagination={null} actions={null}>
                <OptionGroupsListLayout {...props} />
            </List>
        </div>
    </div>
);
export default withTranslation()(OptionGroupsList);
