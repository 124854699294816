import { useRecordContext } from 'react-admin';
import './FieldStatus.css';
import { deliveryStatusTable } from '../data/constants';
// 1: '#808080', // grey
// 5: '#6ac25a', // green
// 101: '#ee5564' // red
// 4a86e8 blue
// f5b26a orange

const colorsStatus = {
    1: '#ee5564', // grey
    3: '#4a86e8', // blue
    5: '#f5b26a', // orange
    7: '#6ac25a', // green
    9: '#808080', // grey
    11: '#808080', // grey
    13: '#808080', // grey
    15: '#4a86e8', // blue
    17: '#4a86e8', // blue
    19: '#f5b26a', // orange
    21: '#6ac25a', // green
    23: '#6ac25a', // green
    25: '#6ac25a', // green
    101: '#ee5564', // red
    103: '#ee5564', // red
    104: '#ee5564', // red
    105: '#ee5564', // red
    112: '#ee5564' // red
};

const FieldType = props => {
    const record = useRecordContext();
    let st = props.basePath === '/deliveries' ? record.order_detail.order_status : record.order_status;
    if (props.source === 'delivery_status') {
        st = record.delivery_status;
    }

    return (
        <div className='fieldStatusContainer' style={{ backgroundColor: colorsStatus[st] || 'grey' }}>
            <span>{deliveryStatusTable[st]}</span>
        </div>
    );
};

export default FieldType;
