import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { addCredentials } from './library';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const { page } = params.pagination;
        const { search_field, search_value, order_status, order_type } = params.filter;

        return httpClient(`${apiUrl}/api/store/order_list_search_sort`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    order_type: '',
                    page: page,
                    payment_mode: '',
                    pickup_type: '',
                    search_field: search_field || 'user_detail.first_name',
                    search_value: search_value || ''
                })
            )
        }).then(({ json }) => {
            // CURRENCY SIGN ?
            const data = json.orders
                ? json.orders.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: json.pages * 10
            };
        });
    },
    setOrderStatus: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/api/store/set_order_status', {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    order_id: record,
                    order_status: otherParameters.status
                })
            )
        }).then(({ json }) => {
            return { data: json };
        });
    },
    getVehiculeList: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/api/store/get_vehicle_list', {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    delivery_type: 1,
                    order_id: record.id
                })
            )
        }).then(({ json }) => {
            return json;
        });
    },
    createRequest: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/api/store/create_request', {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    estimated_time_for_ready_order: otherParameters.time,
                    vehicle_id: otherParameters.vehiculeId,
                    order_id: record.id
                })
            )
        }).then(({ json }) => {
            return json;
        });
    },
    cancelOrder: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/api/store/store_cancel_or_reject_order', {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    cancel_reason: otherParameters.reason,
                    order_id: record.id,
                    order_status: 104
                })
            )
        }).then(({ json }) => {
            return json;
        });
    },
    checkNewOrder: (resource, { record, otherParameters }) => {
        return httpClient(apiUrl + '/api/store/store_notify_new_order', {
            method: 'POST',
            body: JSON.stringify(addCredentials({}))
        }).then(({ json }) => {
            return json;
        });
    }
};
