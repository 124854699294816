import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { REACT_APP_API_URL_NEEDEAT } from '../config';
import { addCredentials } from './library';
const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        return httpClient(`${apiUrl}/api/store/get_store_product_item_list`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    product_id: params.filter.categoryId
                })
            )
        }).then(({ json }) => {
            const data =
                json.products.length > 0
                    ? json.products[0].items.map(resource => ({
                          ...resource,
                          id: resource._id
                      }))
                    : [];
            return {
                data,
                total: data.length
            };
        });
    },
    getOne: (resource, { id }) => {
        return httpClient(`${apiUrl}/api/get_item_detail`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    item_array: [id],
                    id: localStorage.getItem('store_id'),
                    type: 2
                })
            )
        }).then(({ json }) => {
            if (json.items.length === 0) return { data: null };
            const data = json.items[0];
            data.id = data._id;

            // The code below is to match the legacy option system and new system. it's to show all options available as checkable
            // wereas in legacy system the non available options are not shown and not in database
            // that's why I created listNotSelected to remember the not selected options

            // if (data.specifications) {
            //     data.specifications.forEach(sp => {
            //         // force is user_selected=true to everything that's in list
            //         if (Array.isArray(sp.list)) {
            //             sp.list.forEach(opt => (opt.is_user_selected = true));
            //         }
            //         // and complete with user_selected=false for all in listNotSelected
            //         if (sp.listNotSelected && sp.listNotSelected.length) {
            //             // check that some of not selected are not already in selected/ this can happen if option was added through legacy back office
            //             sp.listNotSelected = sp.listNotSelected.filter(optNS => {
            //                 let myreturn = true;
            //                 sp.list.forEach(optS => {
            //                     if (optS._id === optNS._id) {
            //                         myreturn = false;
            //                     }
            //                 });
            //                 return myreturn;
            //             });
            //             sp.listNotSelected.forEach(opt => (opt.is_user_selected = false));
            //             sp.list = sp.list.concat(sp.listNotSelected);
            //         }
            //         delete sp.listNotSelected;
            //     });
            // }
            return {
                data
            };
        });
    },
    update: async (resource, params) => {
        delete params.data.image_url_to_upload;
        //delete images
        const image_url = params.data.image_url;
        try {
            // # # # # # IMAGES DELETE
            if (params.data.imagesToDelete) {
                //delete the ids in the image_url field, that will be passed to the update request
                // that will delete the data base (not the image)
                params.data.imagesToDelete.forEach(imgId => {
                    const index = image_url.findIndex(el => el === imgId);
                    image_url.splice(index, 1);
                });
                //now ask the image server to delete the image
                const answerDelete = await httpClient(`${REACT_APP_API_URL_NEEDEAT}/api/store/delete_item_image`, {
                    method: 'POST',
                    body: JSON.stringify(
                        addCredentials({
                            image_url: params.data.imagesToDelete,
                            _id: params.id
                        })
                    )
                });
            }
            // # # # # # DATA UPLOAD
            const { json } = await httpClient(`${apiUrl}/api/store/update_item`, {
                method: 'POST',
                body: JSON.stringify(
                    addCredentials({
                        ...params.data,
                        image_url: image_url,
                        item_id: params.id
                    })
                )
            });

            // # # # # # IMAGES UPLOAD - Only if contains rawFile
            if (params.data.imageToAdd) {
                const formData = new FormData();
                formData.append('item_id', params.id);
                formData.append(0, params.data.imageToAdd.blob, params.data.imageToAdd.fileUrl);
                const answerAdd = await httpClient(`${REACT_APP_API_URL_NEEDEAT}/api/store/update_item_image`, {
                    method: 'POST',
                    body: formData
                });
            }

            // # # # # # RETURN
            return {
                data: { id: params.id }
            };
        } catch (e) {
            console.log('there was an api error', e);
        }
    },
    create: (resource, params) => {
        const inputs = params.data;

        const price_ttc = inputs.price_ttc;
        const tax = inputs.tax;
        const price = Math.round((100 * price_ttc) / (100 + tax));
        const tax_currency = Math.round((price * tax) / 100);

        return httpClient(`${apiUrl}/api/store/add_item`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    name: inputs.name,
                    price: inputs.price,
                    product_id: inputs.categoryId,
                    sequence_number: inputs.nbArticles + 1,
                    details: '',
                    price_ttc,
                    tax,
                    price,
                    tax_currency,
                    is_item_in_stock: true,
                    is_most_popular: false,
                    is_visible_in_store: true,
                    available_time: []
                })
            )
        }).then(({ json }) => {
            return {
                data: { ...json.item, id: json.item._id }
            };
        });
    },
    updateInStock: async (resource, params) => {
        return httpClient(`${apiUrl}/api/store/update_item`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    ...params.data,
                    item_id: params.data._id
                })
            )
        }).then(({ json }) => {
            if (!!json.success) {
                return {
                    data: json.item
                };
            }
        });
    }
};
