import './ButtonCallApi.css';
import { useState } from 'react';

const ButtonSimple = ({ action, text, Icon }) => {
    const [bubble, setBubble] = useState(false);
    return (
        <button
            className='btn first'
            onClick={e => {
                e.preventDefault();
                action();
            }}
            onMouseEnter={() => {
                setBubble(true);
            }}
            onMouseLeave={() => {
                setBubble(false);
            }}
        >
            {!bubble ? <Icon /> : <div className='insideButton'> {text}</div>}
        </button>
    );
};
export default ButtonSimple;
