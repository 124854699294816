import { useContext, useEffect, useState } from 'react';
import { DataProviderContext, useRefresh, useNotify } from 'react-admin';
import { CHECK_NEW_ORDERS_TIME } from '../config';
import myBell from '../assets/newOrder2.mp3';

const NewOrderChecker = () => {
    const dataProvider = useContext(DataProviderContext);
    const soundBell = new Audio(myBell);
    const refresh = useRefresh();
    const [reload, setReload] = useState(false);
    const notify = useNotify();

    const checkNewOrder = async () => {
        try {
            const data = await dataProvider.customApiCall('orders', {
                action: 'checkNewOrder'
            });

            if (!data.success && data.error_code === 999) {
                //  That means "wrong token"
                // So we will Disconnect the user by removing local storage token. This way the authToken in authProvider the won't pass, and react-admin will force to login page
                localStorage.clear();
            } else if (!localStorage.getItem('store_data') || localStorage.getItem('store_data') === 'undefined') {
                // this restores storeData in case of login through url parameters
                localStorage.setItem('store_data', JSON.stringify(data.store_detail));
            }
            if (data.success) {
                // play the sound and refresh the current page. good news is that refresh function will refresh the current page whether it's orders or deliveries
                notify('Une nouvelle commande est arrivée !', 'success');
                soundBell.play();
                refresh();
            }
        } catch (e) {
            console.log('Error Api');
        }
    };

    useEffect(() => {
        checkNewOrder();
        setTimeout(() => {
            setReload(!reload);
        }, CHECK_NEW_ORDERS_TIME);
    }, [reload]);

    return null;
};
export default NewOrderChecker;
