// in src/Dashboard.js
import * as React from 'react';
import ImgDashboard from '../assets/dashboard.png';

import './Dashboard.css';

export default () => (
    <div className='dashboardContainer'>
        <img src={ImgDashboard} />
    </div>
);
