import { useContext, useEffect, useState } from 'react';
import {
    //   FIELDS
    TextField,
    List,
    Datagrid,
    useRedirect,
    useListContext,
    FunctionField,
    DataProviderContext,
    useNotify,
    CreateButton
} from 'react-admin';
import './ArticleEdit.css';
import { REACT_APP_API_URL_NEEDEAT } from '../../config';
import { AddCircle } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import Switch from '@material-ui/core/Switch';
import { Box, Button, Typography } from '@material-ui/core';

const PreviewImage = ({ record }) => {
    let src = record && record.image_url && record.image_url.length ? record.image_url[0] : null;
    if (src) {
        return <img src={REACT_APP_API_URL_NEEDEAT + '/' + src} className='articleImg' title='no preview' />;
    } else {
        return <div className='articleImgEmpty'>Pas d'image</div>;
    }
};

const CustomActions = ({ categoryId, name, first }) => {
    const redirect = useRedirect();

    const { total } = useListContext();
    return (
        <div className='ContainerAction'>
            <div className='titlePage'>{name}</div>
            {first && <div>Aucun article dans cette catégorie</div>}
            <div
                className='articleButtonAdd'
                onClick={() => {
                    redirect('create', '/articles', null, null, {
                        categoryId,
                        nbArticles: total
                    });
                }}
            >
                <AddCircle />

                <span>{!first ? 'ajouter un article' : 'créer le premier article'}</span>
            </div>
        </div>
    );
};

export const ArticlesList = props => {
    const t = props.t;
    const dataProvider = useContext(DataProviderContext);
    const [categoryId, setCategoryId] = useState(null);
    const notify = useNotify();
    const [name, setName] = useState(null);

    useEffect(() => {
        if (props.location.state !== undefined) {
            if (props.location.state.name !== undefined) {
                localStorage.setItem('categoryIdCurrentArticle', props.location.state.categoryId);
                localStorage.setItem('nameCurrentArticle', props.location.state.name);
                setName(props.location.state.name);
                setCategoryId(props.location.state.categoryId);
            }
        }

        if (props.location.state !== undefined) {
            if (!categoryId && !props.location.state.name) {
                setName(localStorage.getItem('nameCurrentArticle'));
                setCategoryId(localStorage.getItem('categoryIdCurrentArticle'));
            }
        }

        if (props.location.state === undefined) {
            if (localStorage.getItem('nameCurrentArticle') !== undefined) {
                setName(localStorage.getItem('nameCurrentArticle'));
                setCategoryId(localStorage.getItem('categoryIdCurrentArticle'));
            }
        }
    }, []);

    const changeInStockStatus = async dataRecord => {
        dataRecord.is_item_in_stock = !dataRecord.is_item_in_stock;
        const { data } = await dataProvider.customApiCall('articles', {
            action: 'updateInStock',
            data: dataRecord
        });
        if (data) {
            notify(t('notifyInStockChange'));
        }
    };

    const ChangeInStockField = ({ record }) => {
        const { refetch } = useListContext();
        return (
            <>
                <Switch
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    checked={record.is_item_in_stock}
                    onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeInStockStatus(record);
                        refetch();
                    }}
                    color='primary'
                    name='checkStoreOpen'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                {t('labelIsInStock')}
            </>
        );
    };

    // <Box textAlign='center' m={1}>
    //     <Typography variant='h4' paragraph>
    //         No products available
    //     </Typography>
    //     <Typography variant='body1'>Create one or import from a file</Typography>
    //     <CreateButton />
    //     <Button
    //         onClick={() => {
    //             console.log('clic');
    //         }}
    //     >
    //         Import
    //     </Button>
    // </Box>

    return (
        <div className='articleListAllContainer'>
            <div className='defaultPageContainer'>
                <List
                    {...props}
                    title={t('titleArticles')}
                    bulkActionButtons={false}
                    pagination={null}
                    filter={{ categoryId }}
                    actions={<CustomActions t={t} name={name} categoryId={categoryId} />}
                    empty={<CustomActions t={t} name={name} categoryId={categoryId} first={true} />}
                >
                    <Datagrid rowClick={'edit'}>
                        <TextField source='name' label={t('labelArticleSingle')} sortable={false} />
                        <PreviewImage source='Image' />
                        <FunctionField
                            label='prix'
                            render={record => (
                                <div>
                                    {record.price_ttc || record.price + Math.round((record.price * record.tax) / 100)}
                                </div>
                            )}
                            sortable={false}
                        />
                        <FunctionField
                            label={t('labelIsInStock')}
                            render={record => <ChangeInStockField record={record} />}
                            sortable={false}
                        />
                    </Datagrid>
                </List>
            </div>
        </div>
    );
};

export default withTranslation()(ArticlesList);
