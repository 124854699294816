import { DataProviderContext, useListContext, useNotify, useRedirect } from 'react-admin';
import { withTranslation } from 'react-i18next';
import { REACT_APP_API_URL_NEEDEAT } from '../../config';
import { DeleteOutline, Edit, Check } from '@material-ui/icons';
import ImgDashboard from '../../assets/dashboard.png';
import { useContext, useState } from 'react';
import { PlusOne } from '@material-ui/icons';
import ModalInput from '../../components/ModalInput';

const CategoryCard = ({ id, i, t }) => {
    const [isHover, setIsHover] = useState(false);
    const redirect = useRedirect();
    const { ids, data, refetch } = useListContext();
    const [openModal, setOpenModal] = useState(false);
    let urlImage = data[id].image ? REACT_APP_API_URL_NEEDEAT + '/' + data[id].image[0] : ImgDashboard;
    const dataProvider = useContext(DataProviderContext);
    const notify = useNotify();
    const updateProduct = async (isDeleteButton, newName) => {
        try {
            const name = !isDeleteButton ? newName : data[id].name;
            const isVisibleInStore = isDeleteButton ? !data[id].is_visible_in_store : data[id].is_visible_in_store;
            const { json } = await dataProvider.customApiCall('categories', {
                action: 'updateProduct',
                name: name,
                is_visible_in_store: isVisibleInStore,
                product_id: id
            });
            if (!json.success) {
                notify(t('textCommandErrorApi'));
                refetch();
            } else {
                notify(t('categoryUpdated'));
                refetch();
            }
        } catch (e) {
            console.log(e, 'err api');

            notify(t('textCommandErrorApi'));
        }
    };

    return (
        <>
            <div
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => {
                    redirect('list', '/articles', 1, 1, {
                        categoryId: id,
                        name: data[id].name
                    });
                }}
                className='catCard'
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${urlImage})`
                }}
            >
                {!!isHover && (
                    <div className='removeOrEditCategory'>
                        <div
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                updateProduct(true, { isVisible: false });
                            }}
                            className='orderSuppressIcon'
                        >
                            {data[id].is_visible_in_store ? (
                                <DeleteOutline className='orderSuppressIconInside' fontSize='small' />
                            ) : (
                                <Check className='orderSuppressIconInside' fontSize='small' />
                            )}
                        </div>
                        <div
                            onClick={e => {
                                setOpenModal(true);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            className='orderSuppressIcon'
                        >
                            <Edit className='orderSuppressIconInside' fontSize='small' />
                        </div>
                    </div>
                )}

                <div key={id} className='catCardText'>
                    {data[id].name}
                </div>
            </div>
            <ModalInput
                t={t}
                label={'catégorie'}
                title={t('newNameCat')}
                onValidate={input => {
                    updateProduct(false, input);
                    setOpenModal(false);
                }}
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                iconValidate={PlusOne}
                textValidate={t('textUpdate')}
                initialValue={data[id].name}
            />
        </>
    );
};

export default withTranslation()(CategoryCard);
