import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageInput } from 'react-admin';
import ArticleOneImage from './ArticleOneImage';
import { imagesCropWidth, imagesCropHeight } from '../../../config';
import { Cancel } from '@material-ui/icons';
import ButtonSimple from '../../../components/ButtonSimple';

const PlaceHolder = ({ t }) => (
    <div className='articlePlaceHolder'>
        <p>{t('placeHolderImageText')}</p>
    </div>
);

const PreviewImage = ({ record }) => {
    if (record.url) {
        return <img src={record.url} className='articleImgNoShow' />;
    } else return null;
};

const ArticleEditImages = ({ values, reFreshComponent, t }) => {
    const imageRef = useRef(null);
    const ratio = imagesCropWidth / imagesCropHeight;
    const [state, setState] = useState({
        on: false,
        croppedImageUrl: undefined,
        blob: undefined,
        crop: null
    });

    const onImageLoaded = e => {
        imageRef.current = e.target;
        let wInit, hInit;
        if (imageRef.current.width / ratio > imageRef.current.height) {
            hInit = imageRef.current.height;
            wInit = imageRef.current.height * ratio;
        } else {
            wInit = imageRef.current.width;
            hInit = imageRef.current.width / ratio;
        }
        const initCrop = {
            unit: 'px',
            width: wInit,
            height: hInit
        };
        setState(prevState => ({
            ...prevState,
            on: true,
            crop: initCrop
        }));
        makeClientCrop(imageRef.current, initCrop);
    };

    const onCropComplete = crop => {
        makeClientCrop(imageRef.current, crop);
    };

    const onCropChange = crop => {
        if (crop.height > imageRef.current.height) {
            crop.width = state.crop.width;
            crop.height = state.crop.height;
        } else if (crop.height !== state.crop.height) {
            crop.width = crop.height * ratio;
        } else {
            crop.height = crop.width / ratio;
        }
        setState(prevState => ({
            ...prevState,
            crop: crop
        }));
    };
    const makeClientCrop = async (imageRef, crop) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imageRef, crop, 'newFile.png');
            setState(prevState => ({
                ...prevState,
                croppedImageUrl: croppedImageUrl
            }));
        }
    };
    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = imagesCropWidth;
        canvas.height = imagesCropHeight;
        const ctx = canvas.getContext('2d');

        if (ctx) {
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0, // crop.width,
                0, // crop.height,
                imagesCropWidth,
                imagesCropHeight
            );
        }
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                blob => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    blob['name'] = fileName;
                    let fileUrl = state.fileUrl;
                    window.URL.revokeObjectURL(fileUrl);
                    fileUrl = window.URL.createObjectURL(blob);
                    values.imageToAdd = { blob, fileUrl };
                    reFreshComponent();

                    setState(prevState => ({
                        ...prevState,
                        blob: blob,
                        fileUrl: fileUrl
                    }));
                    resolve(fileUrl);
                },
                'image/jpeg',
                0.95
            );
        });
    };

    const ButtonCancel = (
        <ButtonSimple
            action={() => {
                window.URL.revokeObjectURL(state.fileUrl);
                setState({
                    on: false,
                    croppedImageUrl: undefined,
                    blob: undefined,
                    crop: null
                });
                values.imageToAdd = null;
                reFreshComponent();
            }}
            text='Annuler'
            Icon={Cancel}
        />
    );

    return (
        <div>
            {/* # # # # # # DISPLAY DELETABLE PREVIOUS IMAGES # # # # # # # #  */}
            <div className='articleTitle'>{t('imageExisting')} </div>
            <div className='articleImgDeleteContainer'>
                {Array.isArray(values.image_url) &&
                    values.image_url.map(src => {
                        return <ArticleOneImage t={t} src={src} reFreshComponent={reFreshComponent} values={values} />;
                    })}
            </div>
            {/*  # # # # # # # # UPLOAD ONE IMAGE # # # # # # # #  */}
            {!state.on && (
                <>
                    <div className='articleTitle'>{t('addAnImage')}</div>
                    <ImageInput
                        source='image_url_to_upload'
                        placeholder={<PlaceHolder t={t} />}
                        label=''
                        accept='.jpg,.jpeg,.png'
                        onChange={() => {
                            setState({
                                on: true,
                                croppedImageUrl: undefined,
                                blob: undefined,
                                crop: null
                            });
                        }}
                    >
                        <PreviewImage source='url' />
                    </ImageInput>
                </>
            )}
            {state.on && (
                <>
                    <div className='articleButtonandTitle'>
                        <div className='articleTitle' style={{ marginTop: 0 }}>
                            {t('resizeImage')}
                        </div>
                        <div className='articleImgCancelButton'>{ButtonCancel}</div>
                    </div>

                    <ReactCrop crop={state.crop} ruleOfThirds onComplete={onCropComplete} onChange={onCropChange}>
                        <img src={values.image_url_to_upload.url} onLoad={onImageLoaded} className='articleImgCrop' />
                    </ReactCrop>
                </>
            )}
            {/* {state.on && (
                <>
                    <div className='articleTitle'>{t('finaleImage')}</div>
                    <img src={state.croppedImageUrl} width='100%' />
                </>
            )} */}
        </div>
    );
};

export default ArticleEditImages;
