export const addCredentials = data => ({
    ...data,
    store_id: localStorage.getItem('store_id'),
    server_token: localStorage.getItem('store_token')
});

export const addCredentialsWithAdminToken = data => ({
    ...data,
    type:1,
    store_id: localStorage.getItem('store_id'),
    server_token: localStorage.getItem('store_token')
});