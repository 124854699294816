import { useRecordContext } from 'react-admin';

const FieldCustomWidth = ({ source, width, height, text }) => {
    const record = useRecordContext();
    const myClass = {
        width: width || '100px',
        height: height || '40px',
        overflow: 'scroll',
        marginRight:0,
    };

    if (text) {
        return record ? <div className='paddingLeft' style={myClass}>{text}</div> : null;
    }
    return record ? <div className='paddingLeft' style={myClass}>{record[source]}</div> : null;
};

export default FieldCustomWidth;
