import { useState } from 'react';
import { Dialog } from '@material-ui/core';
import OrderDetail from '../../components/OrderDetail';
import FieldType from '../../components/FieldType';
import FieldStatus from '../../components/FieldStatus';
import { withTranslation } from 'react-i18next';
import './history.css';

import {
    //   FIELDS
    TextField,
    FunctionField,
    //   INPUTS
    SelectInput,
    TextInput,
    DateInput,
    //   MISC
    List,
    Datagrid,
    Pagination
} from 'react-admin';
import { getReadableDateFromTimezone } from '../../utils';

const MyPagination = props => <Pagination rowsPerPageOptions={[10]} {...props} />;

let daysbefore = new Date();
daysbefore.setDate(daysbefore.getDate() - 3);

const filterDefaultValues = {
    search_field: 'user_detail.first_name',
    search_value: '',
    order_status: 'all',
    order_type: 'both',
    end_date: '',
    start_date: daysbefore,
    order_status_id: ''
};

const ListHistory = props => {
    const t = props.t;
    const [dialogDetail, setDialogDetail] = useState(false);
    const [currRecord, setCurrRecord] = useState(null);

    const orderFilters = [
        <SelectInput
            label={t('searchFieldLabel')}
            source='search_field'
            choices={[
                { id: 'user_detail.first_name', name: t('labelUser') },
                { id: 'provider_detail.first_name', name: t('labelDeliveryMen') }
            ]}
            alwaysOn
            allowEmpty={false}
        />,
        <TextInput source='search_value' label={t('labelSearch')} alwaysOn resettable />,
        <SelectInput
            label={t('orderStatus')}
            source='order_status_id'
            choices={[
                { id: '', name: t('choiceAll') },
                { id: 3, name: t('choiceRejected') },
                { id: 10, name: t('choiceCompleted') }
            ]}
            allowEmpty={false}
        />,
        <SelectInput
            label={t('labelOrderType')}
            source='order_type'
            choices={[
                { id: 'false', name: t('choiceAsap') },
                { id: 'true', name: t('choicePlanify') },
                { id: 'both', name: t('choiceBoth') }
            ]}
            allowEmpty={false}
        />,
        <DateInput source='start_date' label={t('labelHistoryDateBeginning')} />,
        <DateInput source='end_date' label={t('labelHistoryDateEnd')} />
    ];

    return (
        <div className='containerAllHistoryContainer'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('titleHistory')}</div>
                <List
                    {...props}
                    title={t('titleHistoryList')}
                    bulkActionButtons={false}
                    pagination={<MyPagination />}
                    filters={orderFilters}
                    filterDefaultValues={filterDefaultValues}
                >
                    <Datagrid
                        rowClick={(id, basePath, record) => {
                            setCurrRecord(record);
                            setDialogDetail(true);
                        }}
                    >
                        <TextField source='unique_id' label='Id' sortable={false} />
                        <FieldType source='is_schedule_order' label='Type' />

                        <FunctionField
                            label={t('labelUser')}
                            render={record => `${record.user_detail.first_name} ${record.user_detail.last_name}`}
                            sortable={false}
                        />
                        <FunctionField
                            label={t('labelDeliveryMen')}
                            render={record =>
                                record.provider_detail
                                    ? `${record.provider_detail.first_name} ${record.provider_detail.last_name}`
                                    : ''
                            }
                            sortable={false}
                        />

                        <FunctionField
                            label={t('labelEstimatedDelivery')}
                            render={record => {              
                                return getReadableDateFromTimezone(t,record.date_time[record.date_time.length - 1].date,record.timezone);
                            }}
                        />
                        <TextField source='order_payment_detail.total' label={t('labelAmount')} sortable={false} />
                        <FieldStatus label={t('labelStatus')} />
                    </Datagrid>
                </List>
                <Dialog
                    open={dialogDetail}
                    onClose={() => {
                        setDialogDetail(false);
                    }}
                    maxWidth={false}
                >
                    <OrderDetail data={currRecord} />
                </Dialog>
            </div>
        </div>
    );
};

export default withTranslation()(ListHistory);
