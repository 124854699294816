export const deliveryStatusTable = {
    1: 'Created',
    3: 'Accepted',
    5: 'Preparing',
    6: 'Order Status Number 6',
    7: 'Ready',
    9: 'Assigned',
    10: 'Status 10',
    11: 'Accepted',
    13: 'Coming',
    15: 'Arrived',
    17: 'Picked',
    19: 'Delivering',
    21: 'Arrived at dest.',
    23: '??',
    25: 'Completed',
    101: 'User cancelled',
    103: 'Rejected',
    104: 'Store cancelled',
    105: 'Req. Cancelled',
    109: 'No delivery Man',
    112: 'Deli. Cancelled'
};

export const statusDisplayable = [1, 3, 5, 7, 13, 17, 19, 25, 101, 103, 104];

export const userTypes = {
    0: 'Type User0',
    1: 'Admin',
    2: 'Store',
    3: 'SubAdmin',
    4: 'Type User4',
    5: 'Type User5',
    6: 'Type User6',
    7: 'User',
    8: 'DeliveryMan',
    9: 'Vehicule'
};

export const statusIds = {
    0: 'ideal',
    1: 'Running',
    2: 'Canceled',
    3: 'Rejected',
    4: 'No Answer',
    10: 'Completed'
};

export const admin_profit_mode_on_store = {
    1: '%',
    2: 'absolute price per order',
    3: 'absolute price per item'
};

export const promofor = {
    20: 'Service',
    0: 'Devlieries',
    2: 'Restaurant',
    21: 'Produit',
    22: 'Article'
};

export const promo_code_type = {
    1: 'Pourcentage',
    2: 'Absolu'
};
