import { useState } from 'react';

import { Edit, AddCircle } from '@material-ui/icons';
import ArticleTimeModification from './ArticleTimeModification';
import { withTranslation } from 'react-i18next';

const ArticleTime = ({ t, values }) => {
    const [openModif, setOpenModif] = useState(false);

    const onClickButtonModif = () => {
        setOpenModif(true);
    };

    const onValidateTimeModif = listNewTimes => {
        setOpenModif(false);
        values.available_time = listNewTimes;
    };
    const allTimesArticles = values?.available_time || [];
    return (
        <div>
            <div className='articleTime'>
                <div className='articleTimeContainerSchedule'>
                    <div className='articleTimeTitle'>{t('articleTimeTitle')}</div>
                    <div className='articleOpenTimeContainer'>
                        {allTimesArticles.length > 0 &&
                            allTimesArticles.map((dayTime, index) => {
                                return (
                                    <div
                                        className='articleTimeAlreadyPresent'
                                        key={`articleTime-${index}`}
                                    >{` ${dayTime.open_time}  -  ${dayTime.close_time}`}</div>
                                );
                            })}
                        {/* {allTimesArticles.length <= 0 && (
                            <div className='articleTimeAlreadyPresent'>{t('articleNoTime')}</div>
                        )} */}
                    </div>
                    <div className='articleTimeIconContainer' onClick={onClickButtonModif}>
                        {allTimesArticles.length <= 0 ? (
                            <AddCircle style={{ fontSize: 20 }} />
                        ) : (
                            <Edit style={{ fontSize: 20 }} />
                        )}
                    </div>
                </div>
            </div>
            <ArticleTimeModification
                open={openModif}
                onClose={() => setOpenModif(false)}
                onValidate={onValidateTimeModif}
                originListTimes={allTimesArticles}
            />
        </div>
    );
};

export default withTranslation()(ArticleTime);
