import React, { useEffect, useState } from 'react';
import { useRefresh, BooleanInput } from 'react-admin';
import { withTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import { REACT_APP_API_URL } from '../../../config';
import { Select, MenuItem } from '@material-ui/core';
import { Edit, Add, DeleteForever } from '@material-ui/icons';
import ButtonSimple from '../../../components/ButtonSimple';
import { Dialog, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { TextField, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const CardVehicles = ({ t }) => {
    const { values } = useFormState();
    const [vehiclesList, setVehicleList] = useState(null);
    const [openModal, setOpenModal] = useState(null);
    const [chosenVehicle, setChosenVehicle] = useState(null);
    const refresh = useRefresh();

    // get the vehicle List
    useEffect(() => {
        const getVehicleList = async () => {
            const response = await fetch(`${REACT_APP_API_URL}/admin/vehicle_list`);
            const responseJson = await response.json();
            setVehicleList(responseJson.vehicles.filter(v => v.is_business));
        };
        getVehicleList();
    }, []);

    const deleteVehicle = async vehicle_id => {
        const response = await fetch(`${REACT_APP_API_URL}/admin/store_remove_vehicle`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                vehicle_id,
                store_id: values._id
            })
        });
        refresh();
    };

    const addVehicle = async input => {
        const response = await fetch(`${REACT_APP_API_URL}/admin/store_add_vehicle`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                vehicle_id: chosenVehicle,
                store_id: values._id,
                distance: input === null ? null : Number(input)
            })
        });
        refresh();
    };

    const updateVehicle = async input => {
        const response = await fetch(`${REACT_APP_API_URL}/admin/store_update_vehicle`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                vehicle_id: chosenVehicle,
                store_id: values._id,
                distance: input === null ? null : Number(input)
            })
        });
        refresh();
    };

    if (!localStorage.getItem('isAdmin') === 'true' || !vehiclesList) return null;
    return (
        <div className='cardParameter'>
            <div className='headerParameter'>
                <span className='titleParameter'>{'Véhicules'}</span>
            </div>
            {values &&
                values.vehicle_ids &&
                values.vehicle_ids.map(v => {
                    return (
                        <div className='settingVRow'>
                            <div className='settingVName'>
                                {vehiclesList.find(v1 => v1._id === v.vehicle_id)?.vehicle_name}
                            </div>
                            {v.distance ? (
                                <div> {`Rayon: ${v.distance} km`}</div>
                            ) : (
                                <div> {`Toutes distances acceptées`}</div>
                            )}
                            <div className='settingV2Buttons'>
                                <ButtonSimple
                                    action={() => {
                                        setChosenVehicle(v.vehicle_id);
                                        setOpenModal(true);
                                    }}
                                    text='Modifier'
                                    Icon={Edit}
                                />
                                <ButtonSimple
                                    action={() => {
                                        deleteVehicle(v.vehicle_id);
                                    }}
                                    text='Supprimer'
                                    Icon={DeleteForever}
                                />
                            </div>
                        </div>
                    );
                })}
            <div className='rowInput' style={{ marginTop: '30px', paddingBottom: '20px' }}>
                <div style={{ fontWeight: 'bold' }}> Ajouter un véhicule:</div>
                <Select
                    style={{ width: '100px' }}
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={chosenVehicle}
                    label='Age'
                    placeholder='test'
                    onChange={e => {
                        setChosenVehicle(e.target.value);
                    }}
                >
                    {/* filter the list to the vehicles not already existing */}
                    {vehiclesList &&
                        values.vehicle_ids &&
                        vehiclesList
                            .filter(v => !values.vehicle_ids.map(v2 => v2.vehicle_id).includes(v._id))
                            .map(v => {
                                return <MenuItem value={v._id}>{v.vehicle_name}</MenuItem>;
                            })}
                </Select>
                <div>
                    {chosenVehicle && (
                        <ButtonSimple
                            action={() => {
                                if (chosenVehicle) {
                                    setOpenModal(true);
                                }
                            }}
                            text='Ajouter'
                            Icon={Add}
                        />
                    )}
                </div>
            </div>
            {chosenVehicle && (
                <ModalInputVehicle
                    label={'rayon (km)'}
                    title={vehiclesList.find(v => v._id === chosenVehicle).vehicle_name + ': indiquez le rayon (km)'}
                    onValidate={input => {
                        if (values.vehicle_ids.map(v => v.vehicle_id).includes(chosenVehicle)) {
                            updateVehicle(input);
                        } else {
                            addVehicle(input);
                        }
                        setOpenModal(false);
                    }}
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    iconValidate={Add}
                    textValidate={'valider'}
                    initialValue={''}
                />
            )}
        </div>
    );
};
export default withTranslation()(CardVehicles);

const ModalInputVehicle = ({ title, open, onClose, onValidate, textValidate, label }) => {
    const [inputText, setInputText] = useState('');
    const [infinite, setInfinite] = useState(false);

    return (
        <Dialog open={open} onClose={onClose}>
            <div className='inputContainer'>
                <DialogTitle>
                    <div className='actionModal'>
                        {title}
                        <Close
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                </DialogTitle>
                <div className='bodyModal'>
                    <Switch
                        checked={infinite}
                        onChange={e => {
                            setInputText(infinite ? '' : 'infinite');
                            setInfinite(!infinite);
                        }}
                        label='rayon infini'
                    />
                    Toutes distances acceptées
                    <TextField
                        disabled={infinite}
                        fullWidth={true}
                        placeholder={infinite ? 'Toutes distances acceptées' : ''}
                        type={'text'}
                        value={inputText}
                        label={label}
                        variant='filled'
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={e => {
                            setInputText(e.target.value);
                        }}
                    />
                    <div className='saveButton'>
                        <Button
                            disabled={inputText === ''}
                            onClick={() => {
                                setInputText('');
                                onValidate(inputText !== 'infinite' ? inputText : null);
                            }}
                        >
                            {textValidate}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
