import { Admin, Resource } from 'react-admin';
import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
// Resources
import orders from './resources/orders';
import deliveries from './resources/deliveries';
import history from './resources/history';
import promos from './resources/promos';
import categories from './resources/categories';
import articles from './resources/articles';
import settingResource from './resources/parameters';
import scheduleResource from './resources/schedule';
import accountResource from './resources/account';
import options from './resources/options';
import customRoutes from './customRoutes';
// Components
import Dashboard from './components/Dashboard';
import Layout from './components/Layout';
import MyLoginPage from './components/MyLoginPage';
import NewOrderChecker from './components/NewOrderChecker';

// Provider
import dataProvider from './data/dataProvider';
import authProvider from './data/authProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './translation';

const App = () => {
    // The following code helps automatically connect if we pass the credentials in the url
    const myUrl = window.location.href;
    if (myUrl.split('?token=').length === 2 && myUrl.split('store=').length === 2) {
        const [url, tokenAndStore] = myUrl.split('?token=');
        const [token, StoreIdAndAdminToken] = tokenAndStore.split('&store=');
        const [StoreId, adminToken] = StoreIdAndAdminToken.split('&adminToken=');
        // const [adminServerToken, setAdminServerToken] = tokenAndStore.split('&adminToken=');
        localStorage.clear();
        localStorage.setItem('store_token', token);
        localStorage.setItem('server_token_admin', adminToken);
        localStorage.setItem('store_id', StoreId);
        localStorage.setItem('isAdmin', true);
        window.open(url, '_self');
    }
    const myTheme = merge({}, defaultTheme, {
        palette: {
            mode: 'dark'
        },
        typography: {
            // Use the system font instead of the default Roboto font.
            fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(',')
        },
        components: {
            MuiButton: {
                // override the styles of all instances of this component
                styleOverrides: {
                    root: {
                        // Name of the rule
                        color: 'white' // Some CSS
                    }
                }
            }
        }
    });

    return (
        <>
            <I18nextProvider i18n={i18n}>
                <Admin
                    theme={myTheme}
                    loginPage={MyLoginPage}
                    dashboard={Dashboard}
                    dataProvider={dataProvider}
                    layout={Layout}
                    authProvider={authProvider}
                    customRoutes={customRoutes}
                >
                    <Resource name='orders' {...orders} />
                    <Resource name='deliveries' {...deliveries} />
                    <Resource name='history' {...history} />
                    <Resource name='promos' {...promos} />
                    <Resource name='categories' {...categories} />
                    <Resource name='articles' {...articles} />
                    <Resource name='options' {...options} />
                    <Resource name='parameters' {...settingResource} />
                    <Resource name='schedule' {...scheduleResource} />
                    <Resource name='account' {...accountResource} />
                    <NewOrderChecker />
                </Admin>
            </I18nextProvider>
        </>
    );
};
export default App;
