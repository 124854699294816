import * as React from 'react';
import { useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import FieldStatus from '../../components/FieldStatus';
import FieldType from '../../components/FieldType';
import FieldCustomWidth from '../../components/FieldCustomWidth';
import { makeStyles } from '@material-ui/core';
import './deliveries.css';
import ImgDetails from '../../assets/Vector.png';
import { RELOAD_DELIVERY_TIME } from '../../config.js';
import {
    //   FIELDS
    TextField,
    FunctionField,
    DateField,
    //   MISC
    List,
    Datagrid,
    Pagination,
    useRefresh
} from 'react-admin';

// DATA

// COMPONENTS
import OrderDetail from '../../components/OrderDetail';
import Gmaps from '../../components/Gmaps';
import { withTranslation } from 'react-i18next';
// import DMSelector from '../../components/DMSelector';

const MyPagination = props => <Pagination rowsPerPageOptions={[10]} {...props} />;

const useStyles = makeStyles({
    rowCell: {
        textAlign: 'center'
    }
});

const ListPage = props => {
    const refresh = useRefresh();
    const [reload, setReload] = useState(false);
    const classes = useStyles();
    const t = props.t;
    const [dialogDetail, setDialogDetail] = useState(false); // to pass to modal detail
    const [currRecord, setCurrRecord] = useState(null); // to pass to maps
    const rowStyle = (record, index) => {
        return {
            backgroundColor: record && currRecord && record.id === currRecord.id ? 'rgb(225, 225, 225)' : 'inherit',
            transition: 'all 0.5s ease'
        };
    };

    // will help refresh every x seconds
    useEffect(() => {
        setTimeout(() => {
            refresh();
            setReload(!reload);
        }, RELOAD_DELIVERY_TIME);
    }, [reload]);

    return (
        <div className='containerAllDeliveryList'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('titleDeliveries')}</div>
                <List
                    {...props}
                    title={t('titleDeliveries')}
                    bulkActionButtons={false}
                    pagination={<MyPagination />}
                    actions={<div></div>}
                    aside={<Gmaps record={currRecord} />}
                    empty={false}
                >
                    <Datagrid
                        rowClick={(id, basePath, record) => {
                            setCurrRecord(record);
                            return '';
                        }}
                        rowStyle={rowStyle}
                        classes={{
                            rowCell: classes.rowCell,
                            tbody: classes.tbody,
                            headerCell: classes.rowCell,
                            headerRow: classes.rowCell
                        }}
                    >
                        <TextField source='order_detail.unique_id' sortable={false} label={t('labelId')} />
                        <FieldType label='Type' />

                        <FunctionField
                            label={t('labelEstimatedDelivery')}
                            render={record => {
                                return (
                                    <DateField
                                        source={'order_detail.estimated_time_for_ready_order'}
                                        showTime
                                        sortable={false}
                                        options={{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            timeZone: record.timezone
                                        }}
                                    />
                                );
                            }}
                        />

                        <FieldStatus source='order_detail.order_status' label={t('labelOrderStatus')} />

                        <FunctionField
                            label={t('labelClient')}
                            render={r => (
                                <FieldCustomWidth
                                    // width={"80px"}
                                    sortable={false}
                                    text={r.user_detail.first_name + ' ' + r.user_detail.last_name}
                                />
                            )}
                        />
                        <FunctionField
                            label={t('labelDeliveryMen')}
                            render={r => (
                                <FieldCustomWidth
                                    // width={"80px"}
                                    sortable={false}
                                    text={
                                        r.provider_detail
                                            ? r.provider_detail.first_name + ' ' + r.provider_detail.last_name
                                            : ''
                                    }
                                />
                            )}
                        />
                        <FieldStatus source='delivery_status' label={t('statusDelivery')} />
                        <FunctionField
                            label={t('labelDetail')}
                            render={record => (
                                <img
                                    onClick={() => {
                                        setDialogDetail(true);
                                        setCurrRecord(record);
                                    }}
                                    src={ImgDetails}
                                />
                            )}
                        />
                    </Datagrid>
                </List>
            </div>
            <Dialog
                open={dialogDetail}
                onClose={() => {
                    setDialogDetail(false);
                }}
                maxWidth={false}
            >
                <OrderDetail data={currRecord} />
            </Dialog>
        </div>
    );
};

export default withTranslation()(ListPage);
