import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { addCredentials } from './library';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        return httpClient(`${apiUrl}/api/store/get_product_list`, {
            method: 'POST',
            body: JSON.stringify(addCredentials({}))
        }).then(async ({ json }) => {
            let data = [];
            if (json.products) {
                for (const resource of json.products) {
                    const image = await callApiArticle(resource._id);
                    data.push({ ...resource, id: resource._id, image: image });
                }
            }
            return {
                data,
                total: json.pages * 10
            };
        });
    },
    addCategory: (resource, { name, sequence_number }) => {
        return httpClient(`${apiUrl}/api/store/add_product`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    is_visible_in_store: true,
                    name,
                    sequence_number
                })
            )
        }).then(({ json }) => {
            json.product.id = json.product._id;
            return {
                data: json
            };
        });
    },
    updateProduct: (resource, { name,is_visible_in_store,product_id }) => {
        return httpClient(`${apiUrl}/api/store/update_product`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    is_visible_in_store,
                    name,
                    product_id
                })
            )
        }).then(({ json }) => {
            return {
                json:json
            };
        });
    }
};

const callApiArticle = async id => {
    let resFinal;
    const requestForArticle = await httpClient(`${apiUrl}/api/store/get_store_product_item_list`, {
        method: 'POST',
        body: JSON.stringify(
            addCredentials({
                product_id: id
            })
        )
    })
        .then(res => {
            resFinal = null;
            if (res.json.products.length !== 0) {
                if (res.json.products[0].items.length !== 0) {
                    resFinal = res.json.products[0].items[0].image_url;
                }
            }

            //   const data = res.json.products.length > 0 ? res.json.products[0].items.image_url[0] : [];
        })
        .catch(e => {
            console.log(e, 'error return');
        });

    return resFinal;
};
