import {
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectArrayInput,
    SelectInput,
    NumberInput,
    Toolbar,
    SaveButton,
    DateInput,
    CheckboxGroupInput,
    required,
    number,
    minValue
} from 'react-admin';
import { EditDialog } from '@react-admin/ra-form-layout';
import './Promo.css';
import { useFormState } from 'react-final-form';
import { withTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { callApiArticle } from '../../data/dataProviderPromos';

const CustomToolbar = props => {
    return (
        <div className='saveButton'>
            <Toolbar {...props}>
                <SaveButton label='Enregistrer' icon={<></>} />
            </Toolbar>
        </div>
    );
};

const MyChildrenContainerComponent = ({ t }) => {
    const { values } = useFormState();
    const validateNotEmpty = [required(t('emptyError'))];
    const validateNotnegativeAndRequired = [number(), minValue(0, t('errorNotNegative')), required(t('emptyError'))];
    const validateNotNegative = [number(), minValue(0, t('errorNotNegative'))];
    const [articles, setArticles] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const cat = await callApiArticle();
            let allCategories = [];
            let allArticles = [];

            cat.products.map(cat => allCategories.push({ name: cat.name, id: cat._id }));
            cat.items.map(item => allArticles.push({ name: item.item_name, id: item.product_id }));
            setArticles(allArticles);
            setCategories(allCategories);
        }
        fetchData();
    }, []);

    return (
        <>
            <div className='promoContainer'>
                <div className='promoCard'>
                    <BooleanInput source='is_active' label={t('labelActive')} />
                    <TextInput
                        validate={validateNotEmpty}
                        source='promo_code_name'
                        label={t('labelName')}
                        allowEmpty={false}
                    />
                    <TextInput validate={validateNotEmpty} source='promo_details' label={t('labelDescription')} />
                    <SelectInput
                        validate={validateNotEmpty}
                        source='promo_code_type'
                        choices={[
                            { id: '1', name: t('percenTageText') },
                            { id: '2', name: t('absoluteText') }
                        ]}
                        allowEmpty={false}
                    />
                    <NumberInput
                        min={0}
                        validate={validateNotnegativeAndRequired}
                        source='promo_code_value'
                        label={t('labelValue')}
                    />
                    <SelectInput
                        source='promo_for'
                        choices={[
                            { id: '2', name: t('textRestaurant') },
                            { id: '21', name: t('textCategory') },
                            { id: '22', name: t('textArticle') }
                        ]}
                        allowEmpty={false}
                    />
                </div>
                <div className='promoCard'>
                    <div className='promoTwoInputs'>
                        <BooleanInput
                            source='is_promo_apply_on_completed_order'
                            label={t('labelPromoOnTotalOrder')}
                            style={{ width: '50%' }}
                        />

                        <NumberInput
                            validate={validateNotNegative}
                            source='promo_apply_after_completed_order'
                            label=''
                            disabled={!values.is_promo_apply_on_completed_order}
                        />
                    </div>
                    <div className='promoTwoInputs'>
                        <BooleanInput
                            source='is_promo_required_uses'
                            label={t('labelRequireAccount')}
                            style={{ width: '50%' }}
                        />
                        <NumberInput source='promo_code_uses' label='' disabled={!values.is_promo_required_uses} />
                    </div>
                    <div className='promoTwoInputs'>
                        <BooleanInput
                            source='is_promo_have_minimum_amount_limit'
                            label={t('labelMinimumPrice')}
                            style={{ width: '50%' }}
                        />
                        <NumberInput
                            validate={validateNotNegative}
                            source='promo_code_apply_on_minimum_amount'
                            label=''
                            disabled={!values.is_promo_have_minimum_amount_limit}
                        />
                    </div>
                    <div className='promoTwoInputs'>
                        <BooleanInput
                            source='is_promo_have_max_discount_limit'
                            label={t('labelMaximumReduc')}
                            style={{ width: '50%' }}
                        />
                        <NumberInput
                            validate={validateNotNegative}
                            source='promo_code_max_discount_amount'
                            label=''
                            disabled={!values.is_promo_have_max_discount_limit}
                        />
                    </div>
                    <div className='promoTwoInputs'>
                        <BooleanInput
                            source='is_promo_have_item_count_limit'
                            label={t('labelMinimumArticle')}
                            style={{ width: '50%' }}
                        />
                        <NumberInput
                            validate={validateNotNegative}
                            source='promo_code_apply_on_minimum_item_count'
                            label=''
                            disabled={!values.is_promo_have_item_count_limit}
                        />
                    </div>
                </div>
                <div className='promoCard'>
                    <BooleanInput source='is_promo_have_date' label={t('labelDate')} />
                    {values.is_promo_have_date && (
                        <>
                            <DateInput validate={validateNotEmpty} source='promo_start_date' label='Date de départ' />
                            <DateInput
                                validate={validateNotEmpty}
                                source='promo_expire_date'
                                label="Date d'expiration"
                            />
                            <SelectInput
                                source='promo_recursion_type'
                                choices={[
                                    { id: '0', name: t('choiceNoRecursion') },
                                    { id: '1', name: t('choiceDailyRecursion') },
                                    { id: '2', name: t('choiceWeeklyRecursion') },
                                    { id: '3', name: t('choiceMounthlyRecursion') },
                                    { id: '4', name: t('choiceAnnualyRecursion') }
                                ]}
                            />
                            {[1, 2, 3, 4].includes(Number(values.promo_recursion_type)) && (
                                <>
                                    <TextInput
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        validate={validateNotEmpty}
                                        type={'time'}
                                        source='promo_start_time'
                                        label={t('labelPromoStartTime')}
                                    />
                                    <TextInput
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        validate={validateNotEmpty}
                                        type={'time'}
                                        source='promo_end_time'
                                        label={t('labelPromoEndTime')}
                                    />
                                </>
                            )}
                            {[2, 3, 4].includes(Number(values.promo_recursion_type)) && (
                                <SelectArrayInput
                                    label='Jours'
                                    source='days'
                                    choices={[
                                        { id: 'Monday', name: t('monday') },
                                        { id: 'Tuesday', name: t('tuesday') },
                                        { id: 'Wednesday', name: t('wednesday') },
                                        { id: 'Thursday', name: t('thursday') },
                                        { id: 'Friday', name: t('friday') },
                                        { id: 'Saturday', name: t('saturday') },
                                        { id: 'Sunday', name: t('sunday') }
                                    ]}
                                />
                            )}
                            {[3, 4].includes(Number(values.promo_recursion_type)) && (
                                <SelectArrayInput
                                    label='Semaines'
                                    source='weeks'
                                    choices={[
                                        { id: 'First', name: t('choiceFirst') },
                                        { id: 'Second', name: t('choiceSecond') },
                                        { id: 'Third', name: t('choiceThird') },
                                        { id: 'Fourth', name: t('choiceFourth') },
                                        { id: 'Fifth', name: t('choiceFifth') }
                                    ]}
                                />
                            )}
                            {[4].includes(Number(values.promo_recursion_type)) && (
                                <SelectArrayInput
                                    label='Mois'
                                    source='months'
                                    choices={[
                                        { id: 'January', name: t('choiceJanuary') },
                                        { id: 'February', name: t('choiceFebruary') },
                                        { id: 'March', name: t('choiceMarch') },
                                        { id: 'April', name: t('choiceApril') },
                                        { id: 'May', name: t('choiceMay') },
                                        { id: 'June', name: t('choiceJune') },
                                        { id: 'July', name: t('choiceJuly') },
                                        { id: 'August', name: t('choiceAugust') },
                                        { id: 'September', name: t('choiceSeptember') },
                                        { id: 'October', name: t('choiceOctober') },
                                        { id: 'November', name: t('choiceNovember') },
                                        { id: 'December', name: t('choiceDecember') }
                                    ]}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className='promosProductsContainer'>
                {Number(values.promo_for) === 21 && categories !== null && (
                    <CheckboxGroupInput source='promo_apply_on' choices={categories} />
                )}
                {Number(values.promo_for) === 22 && articles !== null && (
                    <CheckboxGroupInput source='promo_apply_on' choices={articles} />
                )}
            </div>
        </>
    );
};

const MyTitle = props => <div className='promoTitle'>MODIFIER LA PROMO</div>;

const PromoEdit = props => {
    const validatePromoCreation = values => {
        const errors = {};
        if (!values.promo_code_name) {
            errors.promo_code_name = 'Le nom de code est requis';
        }
        if (!values.promo_for) {
            errors.promo_for = 'Ce champ est requis';
        }

        if(!checkIsRequiredIfIsFilled(values.is_promo_apply_on_completed_order,values.promo_apply_after_completed_order)){
            errors.promo_apply_after_completed_order = props.t('fieldRequire');
        }
        if(!checkIsRequiredIfIsFilled(values.is_promo_required_uses,values.promo_code_uses)){
            errors.promo_code_uses = props.t('fieldRequire');
        }
        if(!checkIsRequiredIfIsFilled(values.is_promo_have_minimum_amount_limit,values.promo_code_apply_on_minimum_amount)){
            errors.promo_code_apply_on_minimum_amount = props.t('fieldRequire');
        }
        if(!checkIsRequiredIfIsFilled(values.is_promo_have_max_discount_limit,values.promo_code_max_discount_amount)){
            errors.promo_code_max_discount_amount = props.t('fieldRequire');
        }
        if(!checkIsRequiredIfIsFilled(values.is_promo_have_item_count_limit,values.promo_code_apply_on_minimum_item_count)){
            errors.promo_code_apply_on_minimum_item_count = props.t('fieldRequire');
        }
        return errors;
    };

    const checkIsRequiredIfIsFilled = (isFilled,isRequired) => {
        if (isFilled) {
            if (!isRequired) {
                return false
            }
        }
        return true
    }   

    const transform = data => {
        if ([2, 3, 4].includes(Number(data.promo_recursion_type))) {
            if (data.days === undefined || data.days === null) {
                data.days = ['Saturday', 'Wednesday', 'Monday', 'Thursday', 'Friday', 'Sunday', 'Tuesday'];
            }
        }

        if ([3, 4].includes(Number(data.promo_recursion_type))) {
            if (data.weeks === undefined || data.days === null) {
                data.weeks = ['Third', 'Second', 'Fifth', 'Fourth', 'First'];
            }
        }

        if ([4].includes(Number(data.promo_recursion_type))) {
            if (data.months === undefined || data.days === null) {
                data.months = [
                    'January',
                    'March',
                    'February',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'October',
                    'November',
                    'September',
                    'December'
                ];
            }
        }

        if (data.is_promo_apply_on_completed_order === undefined) {
            data.is_promo_apply_on_completed_order = false;
        }

        if (data.is_promo_have_item_count_limit === undefined) {
            data.is_promo_have_item_count_limit = false;
        }

        if (data.is_promo_have_max_discount_limit === undefined) {
            data.is_promo_have_max_discount_limit = false;
        }

        if (data.is_promo_have_minimum_amount_limit === undefined) {
            data.is_promo_have_minimum_amount_limit = false;
        }

        if (data.is_promo_required_uses === undefined) {
            data.is_promo_required_uses = false;
        }

        if (data.is_promo_have_date === undefined) {
            data.is_promo_have_date = false;
        }
        return data;
    };

    return (
        <EditDialog transform={transform} {...props} maxWidth={false} title={<MyTitle />}>
            <SimpleForm validate={validatePromoCreation} toolbar={<CustomToolbar {...props} />}>
                <MyChildrenContainerComponent {...props} />
            </SimpleForm>
        </EditDialog>
    );
};

export default withTranslation()(PromoEdit);
