import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { addCredentials } from './library';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        return httpClient(`${apiUrl}/api/store/get_specification_group`, {
            method: 'POST',
            body: JSON.stringify(addCredentials({}))
        }).then(({ json }) => {
            const data = json.specification_group
                ? json.specification_group.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data,
                total: 10
            };
        });
    },
    addOptionGroup: (resource, { name }) => {
        return httpClient(`${apiUrl}/api/store/add_specification_group`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    specification_group_name: [name]
                })
            )
        }).then(({ json }) => {
            const data = json.specification_group
                ? json.specification_group.map(resource => ({
                      ...resource,
                      id: resource._id
                  }))
                : [];
            return {
                data: json
            };
        });
    },
    removeEntirelyOption: (resource, { optionGroupId }) => {
        return httpClient(`${apiUrl}/api/store/delete_specification_group`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    specification_group_id: optionGroupId
                })
            )
        }).then(({ json }) => {
            if (json.success === true) {
                return json;
            }
        });
    },

    addOption: (resource, { optionGroupId, name, price }) => {
        return httpClient(`${apiUrl}/api/store/add_option_items`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    specification_group_id: optionGroupId,
                    name,
                    price,
                    type: 1
                })
            )
        }).then(({ json }) => {
            return {
                data: json
            };
        });
    },
    deleteOption: (resource, { parameters }) => {
        return httpClient(`${apiUrl}/api/store/delete_option_items`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    specification_group_id: parameters.optionGroupId,
                    specification_id: parameters.optionId
                })
            )
        }).then(({ json }) => {
            return json;
        });
    },
    linkOptionToArticles: (resource, { parameters }) => {
        return httpClient(`${apiUrl}/api/store/change_option_items`, {
            method: 'POST',
            body: JSON.stringify(
                addCredentials({
                    specification_group_id: parameters.optionGroupId,
                    specification_id: parameters.optionId,
                    is_active: parameters.is_active,
                    type: 1
                })
            )
        }).then(({ json }) => {
            return json;
        });
    }
};
