import * as React from 'react';
import './OrderDetail.css';
import { Spacer, Divider } from './utilities';
import { deliveryStatusTable, statusDisplayable } from '../data/constants';
import { getReadableDateFromTimezone } from '../utils';
import { withTranslation } from 'react-i18next';
import noteIcon from '../assets/noteIcon.svg';

const OrderDetail = ({ data, t }) => {
    function compare(a, b) {
        if (a.status < b.status) {
            return -1;
        }
        if (a.status > b.status) {
            return 1;
        }
        return 0;
    }
    const statusStoreFiltered = data.date_time.filter(status => statusDisplayable.includes(status.status));
    const statusDeliveryFiltered = data.request_detail
        ? data.request_detail.date_time.filter(status => statusDisplayable.includes(status.status))
        : null;
    let allStatus = statusDeliveryFiltered ? statusStoreFiltered.concat(statusDeliveryFiltered) : statusStoreFiltered;
    allStatus.sort(compare);

    const curr = data.order_payment_detail.order_currency_code;
    if (!data) return null;
    return (
        <div className='odContainer'>
            <div className='odPageContainer'>
                {/* # # # # # # # # # # # # # # # PAGE 1 DETAILS # # # # # # # # # # #  */}
                <span className='odTitle'>Commande #{data.order_payment_detail.order_unique_id}</span>
                <Spacer height={'12px'} />
                {data.cart_detail.order_details.map(product => {
                    return (
                        <div className='odProduct' key={product.product_id}>
                            <span className='odProductTitle'>{product.product_name}</span>
                            <Spacer height={'7px'} />
                            <Divider height='2px' />
                            <Spacer height={'7px'} />
                            {product.items.map((item, i) => {
                                const totalPriceWithTax =
                                    Math.round(item.total_item_price) + Math.round(item.total_item_tax);
                                const totalPriceWithTaxSingle =
                                    Math.round(item.total_price) + Math.round(item.total_tax);
                                return (
                                    <div key={i} className='odItemContenair'>
                                        {i !== 0 && <Divider height='1px' />}
                                        <div className='odInvoiceLine' style={{ position: 'relative' }}>
                                            <div>
                                                <span
                                                    className='odItemTitleQuantity'
                                                    style={{ color: item.quantity > 1 ? 'red' : 'black' }}
                                                >
                                                    x {item.quantity}
                                                </span>
                                                <span className='odItemTitle'>{item.item_name}</span>
                                            </div>
                                            <span className='odSpanGrey'>
                                                {/* {item.item_price * item.quantity} {curr} */}
                                                {totalPriceWithTax} {curr}
                                            </span>
                                            <span className='pricePerQuantity'>
                                                {/* {`${item.item_price} ${curr} x${item.quantity}`} */}
                                                {`${totalPriceWithTaxSingle} ${curr} x${item.quantity}`}
                                            </span>
                                        </div>
                                        {item.note_for_item && (
                                            <div className='odNote'>
                                                <img src={noteIcon} height='20px' />
                                                {item.note_for_item}
                                            </div>
                                        )}
                                        <ul className='specifBox'>
                                            {item.specifications.map(spec => {
                                                return (
                                                    <div>
                                                        {spec.list.map(ingredient =>
                                                            !!ingredient.unique_id ? <li>{ingredient.name}</li> : null
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <Spacer height={'20px'} />
                <div className='odInvoiceLine'>
                    <span className='odSpanGrey'></span>
                    <span className='odTitle'>
                        TOTAL TTC: {Math.round(data.order_payment_detail.total_order_price)} {curr}
                    </span>
                </div>
            </div>
            {/* # # # # # # # # # # # # # # # PAGE 2 TIME LINE # # # # # # # # # # #  */}

            <div className='odPageContainer'>
                {allStatus.map((date, i) => {
                    if (statusDisplayable.includes(date.status)) {
                        return (
                            <div className='odTimeLineItem' key={i}>
                                <span> {deliveryStatusTable[date.status]}</span>
                                <Spacer />
                                <span>{getReadableDateFromTimezone(t, date.date, data.timezone)}</span>
                                {i < statusStoreFiltered.length - 1 && <div className='odTimeLineItemBar'></div>}
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
            {/* # # # # # # # # # # # # # # # PAGE 3 INVOICE # # # # # # # # # # #  */}

            <div className='odPageContainer'>
                <span className='odTitle'>Facture #{data.order_payment_detail.invoice_number}</span>
                <Divider height='1px' />
                <Spacer height='5px' />
                <div className='odInvoiceLine'>
                    <span className='odSpanBold'> Frais de Livraison TTC</span>
                    <span className='odSpanBold'>{`${Math.round(
                        data.order_payment_detail.total_delivery_price
                    )} ${curr}`}</span>
                </div>
                <Spacer height='3px' />
                <div className='odInvoiceLine'>
                    <span className='odSpanGrey'>TGC</span>
                    <span className='odSpanGrey'>{`${Math.round(
                        data.order_payment_detail.total_delivery_price * (1 - 1 / 1.06)
                    )} ${curr}`}</span>
                </div>
                <Spacer height='10px' />
                <div className='odInvoiceLine'>
                    <span className='odSpanBold'> Montant des articles TTC</span>
                    <span className='odSpanBold'>{`${Math.round(
                        data.order_payment_detail.total_order_price
                    )} ${curr}`}</span>
                </div>
                <Spacer height='3px' />
                <div className='odInvoiceLine'>
                    <span className='odSpanGrey'>TGC</span>
                    <span className='odSpanGrey'>{`${Math.round(
                        data.order_payment_detail.total_store_tax_price
                    )} ${curr}`}</span>
                </div>
                <Spacer height='8px' />
                <Divider height='1px' />
                <Spacer height='8px' />
                <div className='odInvoiceLine'>
                    <span className='odSpanBold'> Promotions</span>
                    <span className='odSpanBold'>{`${Math.round(
                        data.order_payment_detail.promo_payment
                    )} ${curr}`}</span>
                </div>
                <Spacer height='16px' />
                <div className='odInvoiceLine'>
                    <span className='odSpanBold'> Remises</span>
                    <span className='odSpanBold'>{`${Math.round(
                        data.order_payment_detail.wallet_payment
                    )} ${curr}`}</span>
                </div>
                <Divider height='1px' />
                <Spacer height='8px' />
                <div className='odInvoiceLine'>
                    <span className='odSpanBold'> Total TTC</span>
                    <span className='odSpanBold'>
                        {`${Math.round(data.order_payment_detail.total_after_wallet_payment)} ${curr}`}
                    </span>
                </div>
                <Divider height='1px' />
                <Spacer height='8px' />
                <div className='odInvoiceLine'>
                    <span className='odSpanBold'> Gains du livreur</span>
                    <span className='odSpanBold'>{`${Math.round(
                        data.order_payment_detail.total_provider_income
                    )} ${curr}`}</span>
                </div>
                {!!data.cancel_reason && (
                    <div className='orderDetailCancelReason'>
                        <div> raison de l'annulation :</div>
                        <div className='cancelReasonReason'>{`${data.cancel_reason}`}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(OrderDetail);
