import React, { useEffect, useState } from 'react';
import { CheckboxGroupInput } from 'react-admin';
import { withTranslation } from 'react-i18next';
import dataProvider from '../../../data/dataProvider';

const CardCategories = ({ t }) => {
    const [tagsList, setTagsList] = useState(null);

    useEffect(() => {
        const getTagsList = async () => {
            const { data } = await dataProvider.customApiCall('parameters', {
                action: 'getTagsList'
            });
            setTagsList(data);
        };
        getTagsList();
    }, []);
    if (!tagsList) return null;
    return (
        <div className='cardCategoryParameter'>
            <div className='headerParameter'>
                <span className='titleParameter'>{t('restaurantSpeciality')}</span>
            </div>
            <div className='categoriesParameter'>
                <CheckboxGroupInput
                    label={t('labelTags')}
                    source='famous_products_tags'
                    choices={tagsList.map(tag => {
                        return { id: tag, name: tag };
                    })}
                />
            </div>
        </div>
    );
};
export default withTranslation()(CardCategories);
