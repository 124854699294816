import { MAP_ICON_SIZE } from '../config';
import './Gmaps.css';

const Marker = ({ info, Icon, num, setInfoBubble, infoBubble, color }) => {
    if (!info) {
        return null;
    }

    const name = num === 3 ? info.first_name : info.user_details.name;
    const phone = num === 3 ? info.phone : info.user_details.phone;
    const email = num === 3 ? info.email : info.user_details.email;
    const address = num === 3 ? info.address : info.user_details.address;
    return (
        <div
            className='markerContainer'
            onMouseEnter={() => {
                setInfoBubble(num);
            }}
            onMouseLeave={() => {
                setInfoBubble(null);
            }}
        >
            {infoBubble === num && (
                <div className='bubbleContainer'>
                    <span>{name}</span>
                    <span>{phone}</span>
                    <span>{email}</span>
                    <span>{address}</span>
                </div>
            )}
            <img src={Icon} width={MAP_ICON_SIZE} />
            {/* <div className='markerPoint'></div> */}
        </div>
    );
};

export default Marker;
