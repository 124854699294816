import React, { useState } from 'react'
import { BooleanInput, minValue, number, NumberInput, required } from 'react-admin';
import { withTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import './Cards.css';


const CardPlannif = ({t}) => {
  const { values } = useFormState();
  const [canPlanif, setCanPlanif] = useState(values.is_taking_schedule_order);
  const validateNotnegative = [number(), minValue(0,t('errorNotNegative')),required(t('fieldRequire'))];
  return (
    <>
       <div className='cardParameter'>
      <div className='headerParameter'>
        <span className='titleParameter'>
          {t('titlePlanifParameter')}
        </span>
      </div>
      <div className='rowInput'>
        <span>{t('clientCanCommand')}</span>
        <BooleanInput onChange={event => setCanPlanif(event)} label="" source="is_taking_schedule_order"/>   
      </div>
      {canPlanif &&(
        <div className='rowInput'>
          <span>{t('labelTimeAfterCommand')}</span>
          <NumberInput  min={0} validate={validateNotnegative} label={false} source="schedule_order_create_after_minute" />
        </div>
      )}
    </div>
    </>
   
  );
};
export default withTranslation()(CardPlannif);
