// Props

// text="cancel delivery"
// successMessage="canceled"
// action="cancel"
// otherParameters
// resource if needed

import * as React from 'react';
import { useState, useContext } from 'react';
import './ButtonCallApi.css';

import { DataProviderContext, useRefresh, useNotify } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import ModalMessage from './ModalMessage';

const ButtonCallApiConfirm = ({
    resource,
    action,
    successMessage,
    text,
    parameters,
    Icon,
    messageConfirm,
    successAction,
    setCallAPI,
    isCallAPI
}) => {
    const [loading, setLoading] = useState(false);
    const dataProvider = useContext(DataProviderContext);
    const [bubble, setBubble] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const notify = useNotify();
    const refresh = useRefresh();

    const APICall = async () => {
        setLoading(true);
        setCallAPI(true);
        dataProvider
            .customApiCall(resource, {
                action,
                parameters
            })
            .then(data => {
                setLoading(false);
                setCallAPI(false);
                if (!data.success) {
                } else {
                    if (successMessage) notify(successMessage, { autoHideDuration: 1000 });
                    if (successAction) {
                        console.log('succss Action');
                        successAction(data);
                    }
                }
            })
            .catch(error => {
                refresh();
                setLoading(false);
                setCallAPI(false);
                notify('there was an api error', { type: 'warning', autoHideDuration: 1000 });
            });
    };

    return (
        <div>
            {loading ? (
                <div className='btn first'>
                    <CircularProgress size={'20px'} color='green' />
                </div>
            ) : (
                <button
                    className='btn first'
                    disabled={isCallAPI}
                    onClick={e => {
                        e.stopPropagation();
                        setOpenModal(true);
                    }}
                    onMouseEnter={() => {
                        setBubble(true);
                    }}
                    onMouseLeave={() => {
                        setBubble(false);
                    }}
                >
                    {!bubble ? <Icon /> : <div className='insideButton'> {text}</div>}
                </button>
            )}
            <ModalMessage
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                onValidate={() => {
                    setOpenModal(false);
                    APICall();
                }}
                onCancel={() => {
                    setOpenModal(false);
                }}
                textValidate='Confirmer'
                textCancel='Annuler'
                message={messageConfirm}
            />
        </div>
    );
};

export default ButtonCallApiConfirm;
