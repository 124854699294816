import dataProviderHistory from './dataProviderHistory';
import dataProviderOrders from './dataProviderOrders';
import dataProviderDeliveries from './dataProviderDeliveries';
import dataProviderPromos from './dataProviderPromos';
import dataProviderCategories from './dataProviderCategories';
import dataProviderArticles from './dataProviderArticles';
import dataProviderOptions from './dataProviderOptions';
import dataProviderParameters from "./dataProviderParameters";
import dataProviderSchedule from './dataProviderSchedule';
import dataProviderAccount from './dataProviderAccount';

const allProviders = {
    orders: dataProviderOrders,
    deliveries: dataProviderDeliveries,
    history: dataProviderHistory,
    promos: dataProviderPromos,
    categories: dataProviderCategories,
    articles: dataProviderArticles,
    options: dataProviderOptions,
    parameters:dataProviderParameters,
    schedule:dataProviderSchedule,
    account:dataProviderAccount,
};

export default {
    getList: (resource, params) => allProviders[resource].getList(resource, params),
    getOne: (resource, params) => allProviders[resource].getOne(resource, params),
    update: (resource, params) => allProviders[resource].update(resource, params),
    create: (resource, params) => allProviders[resource].create(resource, params),
    customApiCall: (resource, params) => allProviders[resource][params.action](resource, params)
};
