import './ModalInput.css';
import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const ModalMessage = ({ open, onValidate, onCancel, onClose, textValidate, textCancel, message }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <div className='ModalMessageContainer'>
                <div className='ModalMessageText'>{message}</div>
                <div div className='ModalMessageButtonLine'>
                    <div className='ModalMessageButton'>
                        <Button
                            style={{ margin: 0, color: 'white' }}
                            onClick={() => {
                                onCancel();
                            }}
                        >
                            {textCancel}
                        </Button>
                    </div>
                    <div className='ModalMessageButton'>
                        <Button
                            style={{ margin: 0, color: 'white' }}
                            onClick={() => {
                                onValidate();
                            }}
                        >
                            {textValidate}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ModalMessage;
