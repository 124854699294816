import { useContext, useState } from 'react';
import './Category.css';
import { List, useListContext, useRedirect, useNotify, DataProviderContext } from 'react-admin';
import ModalInput from '../../components/ModalInput';
import { Add, PlusOne } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import CategoryCard from './CategoryCard';

const CategoryLayout = props => {
    const t = props.t;
    const { ids, data, refetch } = useListContext();
    const redirect = useRedirect();
    const [openModal, setOpenModal] = useState(false);
    const dataProvider = useContext(DataProviderContext);
    const notify = useNotify();
    const addCategory = async name => {
        try {
            const { data } = await dataProvider.customApiCall('categories', {
                action: 'addCategory',
                name: name,
                sequence_number: ids.length
            });
            if (!data.success) {
                notify(t('textCommandErrorApi'));
                refetch();
            } else {
                notify(t('notifyCategoryCreated'));
                localStorage.setItem('categoryIdCurrentArticle', data.product.id);
                localStorage.setItem('nameCurrentArticle', data.product.name);
                redirect("list", "/articles");
            }
        } catch (e) {
            notify(t('textCommandErrorApi'));
        }
    };

    return (
        <>
            <div className='catContainer'>
                {ids.map((id, i) => {
                    return <CategoryCard id={id} i={i} />;
                })}
                <div
                    className='catCardAddCategory'
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    <Add fontSize='large' />
                </div>
            </div>
            <ModalInput
                t={t}
                label={'catégorie'}
                title={t('nameNewCat')}
                onValidate={input => {
                    addCategory(input);
                    setOpenModal(false);
                }}
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                iconValidate={PlusOne}
                textValidate={t('textCreate')}
            />
        </>
    );
};

const CategoryList = props => (
    <div className='categoryAllContainer'>
        <div className='defaultPageContainer'>
            <div className='titlePage'>{props.t('titleArticleCategory')}</div>
            <List title='Catégories' {...props} pagination={null} actions={null}>
                <CategoryLayout {...props} />
            </List>
        </div>
    </div>
);
export default withTranslation()(CategoryList);
