import { t } from 'i18next';
import {
    //   FIELDS
    Edit,
    SimpleForm,
    useRedirect,
    SaveButton,
    Toolbar,
    useRefresh,
    useNotify
} from 'react-admin';
import { withTranslation } from 'react-i18next';
import CardCategories from './CardParameters/CardCategories';
import CardDelivery from './CardParameters/CardDelivery';
import CardPassword from './CardParameters/CardPassword';
import CardPlannif from './CardParameters/CardPlannif';
import CardPrice from './CardParameters/CardPrice';
import CardSettings from './CardParameters/CardSettings';
import CardVehicles from './CardParameters/CardVehicles';
import './parameters.css';

const Settings = props => {
    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();
    const onSuccess = () => {
        notify(props.t('onParameterEditSuccess'));
        redirect(`/parameters/${localStorage.getItem('store_id')}`);
        refresh();
    };

    const validateParameters = async values => {
        const errors = {};
        if (values.delivery_time > values.delivery_time_max) {
            errors.delivery_time = props.t('errorDeliveryminMax');
        }

        if (values.deliverEverywhere === false) {
            if (values.delivery_radius === 0) {
                errors.delivery_radius = props.t('errorValueCantBe0');
            }
        }

        return errors;
    };

    const transform = data => {
        if (localStorage.getItem('isAdmin') === 'true') {
            data.old_password = '';
            data.new_password = '';
        }
        if (!data.item_tax) {
            data.item_tax = 0;
        }
        if (!data.min_order_price) {
            data.min_order_price = 0;
        }

        if (data.is_store_pay_delivery_fees === true) {
            if (!data.free_delivery_for_above_order_price) {
                data.free_delivery_for_above_order_price = 0;
            }
            if (!data.free_delivery_within_radius) {
                data.free_delivery_within_radius = 0;
            }
        }
        return data;
    };

    return (
        <div className='defaultPageContainer'>
            <div className='titlePage'>{t('labelParameters')}</div>
            <Edit
                successMessage={t('labelParametersModifyWithSuccess')}
                mutationMode='pessimistic'
                {...props}
                title='settings'
                onSuccess={onSuccess}
                transform={transform}
            >
                <SimpleForm warnWhenUnsavedChanges validate={validateParameters} toolbar={<ParameterEditToolbar />}>
                    <div className='row'>
                        <CardPrice {...props} />
                        <CardPlannif {...props} />
                        <CardDelivery {...props} />
                        <div>
                            <CardSettings {...props} />
                            {localStorage.getItem('isAdmin') === 'true' && <CardVehicles {...props} />}
                        </div>
                        <CardCategories {...props} />
                        {localStorage.getItem('isAdmin') !== 'true' && <CardPassword {...props} />}
                    </div>
                </SimpleForm>
            </Edit>
        </div>
    );
};

const ParameterEditToolbar = props => (
    <div className='saveButton'>
        <Toolbar {...props}>
            <SaveButton label='Enregistrer' icon={<></>} />
        </Toolbar>
    </div>
);

export default withTranslation()(Settings);
