import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import ToolbarSchedule from './ToolbarSchedule';
import ScheduleRow from './ScheduleRow';
import { DataProviderContext, useNotify } from 'react-admin';
import { useEffect, useContext } from 'react';

const Settings = props => {
    const dataProvider = useContext(DataProviderContext);
    const notify = useNotify();

    const t = props.t;
    const [form, setForm] = useState([]);
    const [modified, setModified] = useState(false);

    useEffect(() => {
        dataProvider
            .getOne('schedule', {})
            .then(({ data }) => {
                let newState = JSON.parse(JSON.stringify(data));
                newState.map(day => (day.errors = []));
                setForm(newState);
            })
            .catch(error => {});
    }, []);

    useEffect(() => {
        return () => {
            if (modified) {
                notify(`Assurez-vous d'avoir bien enregistré vos modifications d'horaires`);
            }
        };
    }, [modified]);

    return (
        <div className='defaultPageContainer'>
            <div className='allContainerSchedule'>
                <div className='titlePage'>{t('scheduleTitle')}</div>
                {form.length > 0 && (
                    <div>
                        {form.map(day => {
                            return (
                                <ScheduleRow
                                    key={day.day}
                                    totalForm={form}
                                    form={form[day.day]}
                                    setForm={setForm}
                                    setModified={setModified}
                                />
                            );
                        })}
                        <ToolbarSchedule form={form} setForm={setForm} setModified={setModified} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default withTranslation()(Settings);
