import { fetchUtils } from 'react-admin';
import { REACT_APP_API_URL } from '../config';
import { addCredentials, addCredentialsWithAdminToken } from './library';

const apiUrl = REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

export default {
    getOne: resource => {
        return httpClient(`${apiUrl}/api/store/get_store_data`, {
            method: 'POST',
            body: JSON.stringify(addCredentials({}))
        })
            .then(({ json }) => {
                let res = {};
                if (json.success === true) {
                    res.data = json.store_detail;
                    res.data.id = res.data._id;
                    return res;
                }
            })
            .catch(err => {
                console.log(err, 'error setting');
            });
    },
    update: (resource, params) => {
        if (localStorage.getItem('isAdmin') === 'true') {
            return httpClient(`${apiUrl}/api/store/update`, {
                method: 'POST',
                body: JSON.stringify(addCredentialsWithAdminToken({ ...params.data }))
            }).then(({ json }) => {
                if (json.success === false) {
                    throw Error;
                }
                localStorage.setItem('store_data', JSON.stringify(json.store));
                return {
                    data: { id: params.id }
                };
            });
        } else {
            return httpClient(`${apiUrl}/api/store/update`, {
                method: 'POST',
                body: JSON.stringify(addCredentials({ ...params.data }))
            }).then(({ json }) => {
                if (json.success === false) {
                    throw Error;
                }
                localStorage.setItem('store_data', JSON.stringify(json.store));
                return {
                    data: { id: params.id }
                };
            });
        }
    },
    // just to try await
    getTagsList: async resource => {
        const delivery_list = await httpClient(`${apiUrl}/admin/delivery_list`, {
            method: 'GET'
        }).then(({ json }) => {
            return json?.deliveries?.find(d => d.delivery_name === 'Restaurants')?.famous_products_tags;
        });
        return { data: delivery_list };
    }
};
