import { useState } from 'react';
import './ModalInput.css';
import { Dialog, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';

const ModalInput = ({
    title,
    open,
    onClose,
    onValidate,
    numbersOnly,
    textValidate,
    label,
    initialValue,
    numberMustBeSupToZero
}) => {
    const [inputText, setInputText] = useState(initialValue ? initialValue : '');
    const [error, setError] = useState('initialValue');
    let textError =
        numberMustBeSupToZero === true
            ? 'Vous ne pouvez pas rentrer un champ vide ou inférieur à 1 minute.'
            : 'Vous ne pouvez pas rentrer un champ vide';

    return (
        <Dialog open={open} onClose={onClose}>
            <div className='inputContainer'>
                <DialogTitle>
                    <div className='actionModal'>
                        {title}
                        <Close
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                </DialogTitle>
                <div className='bodyModal'>
                    <TextField
                        error={error === true}
                        fullWidth={true}
                        helperText={error === true ? textError : null}
                        type={numbersOnly ? 'number' : 'text'}
                        value={inputText}
                        label={label}
                        variant='filled'
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={e => {
                            if (numberMustBeSupToZero === true) {
                                if (e.target.value === '' || e.target.value < 1) {
                                    setError(true);
                                } else {
                                    setError(false);
                                }
                            } else {
                                if (e.target.value === '') {
                                    setError(true);
                                } else {
                                    setError(false);
                                }
                            }

                            if (!numbersOnly || !isNaN(Number(e.target.value))) {
                                setInputText(e.target.value);
                            }
                        }}
                    />
                    <div className='saveButton'>
                        <Button
                            disabled={error === true || error === 'initialValue'}
                            onClick={() => {
                                setInputText('');
                                onValidate(inputText);
                            }}
                        >
                            {textValidate}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ModalInput;
