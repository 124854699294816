import {
    //   FIELDS
    TextField,
    FunctionField,
    DateField,
    List,
    Datagrid,
    Pagination,
    BooleanField,
    CreateButton
} from 'react-admin';
import { withTranslation } from 'react-i18next';

import { promofor, promo_code_type } from '../../data/constants';
import PromoEdit from './PromoEdit';

const MyPagination = props => <Pagination rowsPerPageOptions={[10]} {...props} />;

export const PromoList = props => {
    const t=props.t
    return (
        <div className='containerAllPromoList'>
            <div className='defaultPageContainer'>
                <div className='titlePage'>{t('labelPromos')}</div>
                <List
                    {...props}
                    title='Codes Promo'
                    bulkActionButtons={false}
                    pagination={<MyPagination />}
                    actions={<CreateButton resource='promo'/>}
                >
                    <Datagrid rowClick={'edit'}>
                        <TextField source='unique_id' label={t('labelId')} sortable={false} />
                        <BooleanField source='is_active' label={t('labelActive')} sortable={false} />
                        <TextField source='promo_code_name' label={t('labelName')} sortable={false} />
                        <FunctionField
                            label={t('labelType')}
                            render={r => {
                                return promo_code_type[r.promo_code_type];
                            }}
                        />
                        <TextField source='promo_code_value' label={t('labelValue')} sortable={false} />
                        <FunctionField
                            label={t('labelForUsage')}
                            render={r => {
                                return promofor[r.promo_for];
                            }}
                        />
                        <TextField source='used_promo_code' label={t('labelUsage')} sortable={false} />
                        <TextField source='' label={t('labelAccountUsed')} sortable={false} />
                        <DateField source='promo_expire_date' label={t('labelDateEnd')} sortable={false} />
                    </Datagrid>
                </List>
                <PromoEdit {...props} />
            </div>
        </div>
    );
};
export default withTranslation()(PromoList);
