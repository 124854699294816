import { useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import {
    SimpleForm,
    TextInput,
    DataProviderContext,
    PasswordInput,
    SelectInput,
    SaveButton,
    useRedirect,
    CheckboxGroupInput
} from 'react-admin';
import { Dialog, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { backEndErrorCodes } from '../utils';

import './AccountCreate.css';
import { withTranslation } from 'react-i18next';
import AddressAutoComplete from '../components/AddressAutoComplete';

const AccountCreate = props => {
    const dataProvider = useContext(DataProviderContext);

    const t = props.t;
    const [loc, setLoc] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [deliveryList, setDeliveryList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [countryPhoneCode, setCountryPhoneCode] = useState({});

    const customWidth = '400px';
    const redirect = useRedirect();

    useEffect(() => {
        getCountries();
    }, []);

    const RegisterToolbar = props => {
        return (
            <div className='registerSaveButton'>
                <SaveButton
                    label='Créer mon compte'
                    {...props}
                    redirect={false}
                    style={{ backgroundColor: '#358282' }}
                />
                {errorMessage && <div className='registerError'>server error : {errorMessage}</div>}
            </div>
        );
    };

    const getCountries = async () => {
        const { countries } = await dataProvider.customApiCall('account', {
            action: 'getCountryList'
        });
        setCountryList(countries);
    };
    const getCities = async id => {
        const { cities } = await dataProvider.customApiCall('account', {
            action: 'getCityList',
            country_id: id
        });

        setCityList(cities);
    };
    const getDelivery = async id => {
        const { deliveries } = await dataProvider.customApiCall('account', {
            action: 'getDeliveryList',
            city_id: id
        });
        setDeliveryList(deliveries);
    };

    // # # # # # # # # # Click on Save # # # # # # # # # #
    const saveForm = async dataInput => {
        setErrorMessage(null);
        if (loc) {
            dataInput.latitude = loc[0];
            dataInput.longitude = loc[1];
            dataInput.address = loc[2];
        }
        dataInput.country_phone_code = countryPhoneCode.code;
        const { data } = await dataProvider.create('account', dataInput);
        if (data.success === false) {
            setErrorMessage(data.error_description || backEndErrorCodes(data.error_code));
        }
        if (data.success === true) {
            localStorage.clear();
            localStorage.setItem('store_token', data.store.server_token);
            localStorage.setItem('store_id', data.store._id);
            setOpenModal(true);
        }
    };

    // # # # # # # # # # Validation Rules # # # # # # # # # #
    const validateAccountCreation = values => {
        const errors = {};
        if (
            values.email &&
            !values.email
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        )
            errors.email = 'Format email';
        if (values.password !== values.confirm_password) errors.confirm_password = 'mot de passe différent';
        if (
            countryList &&
            values.country_phone_code &&
            values.country_phone_code !== countryList.find(c => c._id === values.country_id).country_phone_code
        )
            errors.country_phone_code =
                'code pays: ' + values.country_phone_code !==
                countryList.find(c => c._id === values.country_id).country_phone_code;

        if (
            values.country_id &&
            countryPhoneCode &&
            values.phone &&
            countryPhoneCode.maxphonelength &&
            countryPhoneCode.maxphonelength !== values.phone.length
        )
            errors.phone = countryPhoneCode.maxphonelength + ' digits';

        if (!values.name) errors.name = 'Obligatoire';
        if (!values.email) errors.email = 'Obligatoire';
        if (!values.phone) errors.phone = 'Obligatoire';
        if (!values.country_id) errors.country_id = 'Sélectionnez un pays';
        if (!values.city_id) errors.city_id = 'Sélectionnez une ville';
        if (!values.store_delivery_id) errors.store_delivery_id = 'Obligatoire';
        if (!values.password) errors.password = 'Obligatoire';
        if (!values.confirm_password) errors.confirm_password = 'Confirmez le mot de passe';
        if (!values.cgucgv || values.cgucgv.length === 0) errors.cgucgv = 'Obligatoire';

        return errors;
    };

    return (
        <div className='registerBackground'>
            {/* <div ></div> */}
            <SimpleForm
                save={saveForm}
                style={{ width: '930px', backgroundColor: 'white', borderRadius: '5px' }}
                validate={validateAccountCreation}
                toolbar={<RegisterToolbar />}
            >
                <div className='registerTwoBlocks'>
                    <div className='registerBlock1'>
                        <div className='articleTitle'>Informations</div>
                        <Box display={{ xs: 'block', sm: 'flex' }} style={{ gap: '5px', width: customWidth }}>
                            <Box flex={1}>
                                <TextInput label='nom' source='name' isRequired />
                            </Box>
                            <Box flex={1}>
                                <TextInput source='email' label='email' isRequired type='email' />
                            </Box>
                        </Box>
                        <Box display={{ xs: 'block', sm: 'flex' }} style={{ gap: '5px', width: customWidth }}>
                            <Box flex={1}>
                                <TextInput source='website_url' label='site web' />
                            </Box>
                            <Box flex={1}>
                                <TextInput label='slogan' source='slogan' />
                            </Box>
                        </Box>
                        <Box display={{ xs: 'block', sm: 'flex' }} style={{ gap: '5px', width: customWidth }}>
                            <Box flex={1}>
                                <SelectInput
                                    style={{ width: '190px' }}
                                    source='country_id'
                                    choices={countryList.map(c => {
                                        return { id: c._id, name: c.country_name };
                                    })}
                                    isRequired
                                    label='pays'
                                    onChange={e => {
                                        const infos = countryList.find(c => c._id === e.target.value);
                                        setCountryPhoneCode({
                                            code: infos.country_phone_code,
                                            maxphonelength: infos.maximum_phone_number_length,
                                            minphonelength: infos.minimum_phone_number_length
                                        });
                                        getCities(e.target.value);
                                    }}
                                />
                            </Box>
                            <Box flex={1}>
                                <SelectInput
                                    disabled={!countryPhoneCode.code}
                                    style={{ width: '191px' }}
                                    source='city_id'
                                    isRequired
                                    choices={cityList.map(c => {
                                        return { id: c._id, name: c.city_name };
                                    })}
                                    label='ville'
                                    onChange={e => getDelivery(e.target.value)}
                                    // helperText={"Sélectionnez d'abord un pays"}
                                />
                            </Box>
                        </Box>
                        <Box display={{ xs: 'block', sm: 'flex' }} style={{ gap: '5px', width: customWidth }}>
                            <Box display={{ xs: 'block', sm: 'flex' }} style={{ gap: '11px', width: customWidth }}>
                                <div className='registerCountryCode'>{countryPhoneCode?.code || '+xx'}</div>
                                <TextInput
                                    disabled={cityList.length === 0}
                                    style={{ width: '138px' }}
                                    source='phone'
                                    label='téléphone'
                                    isRequired
                                    helperText={
                                        !countryPhoneCode.maxphonelength
                                            ? "Sélectionnez d'abord un pays"
                                            : `${countryPhoneCode.maxphonelength} digits`
                                    }
                                />
                                <SelectInput
                                    source='store_delivery_id'
                                    disabled={cityList.length === 0}
                                    choices={deliveryList.map(d => {
                                        return { id: d._id, name: d.delivery_name };
                                    })}
                                    allowEmpty
                                    isRequired
                                    label='type de livraison'
                                    helperText={"Sélectionnez d'abord une ville"}
                                />
                            </Box>
                        </Box>
                    </div>
                    <div className='registerBlock2'>
                        <div className='articleTitle'> Adresse</div>
                        <AddressAutoComplete setLoc={setLoc} />
                        <div className='articleTitle'> Mot de passe</div>
                        <Box display={{ xs: 'block', sm: 'flex' }} style={{ gap: '5px', width: customWidth }}>
                            <Box flex={1}>
                                <PasswordInput
                                    source='password'
                                    label=''
                                    placeholder='mot de passe'
                                    fullWidth
                                    helperText={false}
                                />
                            </Box>
                            <Box flex={1}>
                                <PasswordInput
                                    source='confirm_password'
                                    label=''
                                    placeholder='confirmation'
                                    fullWidth
                                    helperText={false}
                                />
                            </Box>
                        </Box>
                        <Box height={'20px'}></Box>
                        <div className='articleTitle'> Conditions générales de vente</div>
                        <Box className='registerCGV'>
                            <CheckboxGroupInput
                                source='cgucgv'
                                label=''
                                choices={[{ id: 'CGV', name: "j'accepte les " }]}
                            />
                            <a
                                target='_blank'
                                href='https://needeat-nc.com/index.php/conditions-generales-dutilisation-de-ventes/'
                            >
                                CGV/CGU
                            </a>
                        </Box>
                        {/* <CroppingImage setImgCropped={setImgCropped} noPreviousImage /> */}
                    </div>
                </div>
            </SimpleForm>
            <Dialog open={openModal} onClose={null}>
                <div className='inputContainer'>
                    <DialogTitle>
                        <div className='actionModal'>Compte créé avec succès</div>
                    </DialogTitle>
                    <div className='registerMessage'>
                        En cliquant sur le bouton suivant, vous serez redirigé vers votre page de paramètres où vous
                        pourrez uploader votre logo
                    </div>
                    <div style={{ height: '30px' }}></div>
                    <div className='bodyModal'>
                        <div className='saveButton' style={{ justifyContent: 'center' }}>
                            <Button
                                onClick={() => {
                                    redirect(`/account/${localStorage.getItem('store_id')}`);
                                }}
                            >
                                J'ai compris
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default withTranslation()(AccountCreate);
