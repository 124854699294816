import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useNotify } from 'react-admin';

const ArticleFourPrices = ({ values, allPrices, setAllPrices, disabledFields, setDisabledFields }) => {
    const notify = useNotify();

    // note that we use the state prices (allPrices) for display
    // but to record the actual product object, we have to update "value"
    // this is why we always change "value" when allPrices is modified

    // price_ttc and tax_currency beeing new fields, this useEffect will help fill them for the products created before the update
    const initialTaxCurrency =
        values.tax_currency === undefined
            ? Math.round(((values.price || 0) * (values.tax || 0)) / 100)
            : values.tax_currency;
    const initialTtc =
        values.price_ttc === undefined ? Math.round((values.price || 0) + initialTaxCurrency) : values.price_ttc;
    if (values.price !== undefined && (values.tax_currency === undefined || values.price_ttc === undefined)) {
        notify('Attention redefinition auto des prix TTC', {
            type: 'warning',
            autoHideDuration: 1500
        });
        values.tax_currency = initialTaxCurrency;
        values.price_ttc = initialTtc;
    }

    useEffect(() => {
        setAllPrices({
            price_ttc: initialTtc,
            tax: values.tax,
            price: values.price,
            tax_currency: initialTaxCurrency
        });
    }, []);
    const integersOnly = new RegExp('^[0-9]*$');

    const priceHandling = (field, input) => {
        if (!input.match(integersOnly)) return;
        const val = Number(input);
        // the two principle of these calculations are :
        // PriceTTC and taxPercent are independant (changing one won't affect the other)
        // price and tax are independant (changing one won't affect the other)
        switch (field) {
            case 'priceTTC':
                const newHT0 = Math.round((100 * val) / (100 + allPrices.tax));
                const newTaxC0 = Math.round((newHT0 * allPrices.tax) / 100);

                values.price_ttc = val;
                // values.tax= values.tax STAYS FIXED
                values.price = newHT0;
                values.tax_currency = newTaxC0;
                setAllPrices({ price_ttc: val, tax: allPrices.tax, price: newHT0, tax_currency: newTaxC0 });
                break;
            case 'tax':
                const newHT1 = Math.round((100 * allPrices.price_ttc) / (100 + val));
                const newTaxC1 = Math.round((newHT1 * val) / 100);

                // values.price_ttc STAYS FIXED
                values.tax = val;
                values.price = newHT1;
                values.tax_currency = newTaxC1;
                setAllPrices({ price_ttc: allPrices.price_ttc, tax: val, price: newHT1, tax_currency: newTaxC1 });
                break;
            case 'price':
                const newTTC2 = Math.round(val + allPrices.tax_currency);
                const newtaxP2 = Math.round((allPrices.tax_currency / val) * 100);

                values.price_ttc = newTTC2;
                values.tax = newtaxP2;
                values.price = val;
                // values.tax_currency STAYS FIXED
                setAllPrices({ price_ttc: newTTC2, tax: newtaxP2, price: val, tax_currency: allPrices.tax_currency });
                break;
            case 'tax_currency':
                const newTTC3 = Math.round(val + allPrices.price);
                const newtaxP3 = Math.round((val / allPrices.price) * 100);

                values.price_ttc = newTTC3;
                values.tax = newtaxP3;
                // values.price = val; STAYS FIXED
                values.tax_currency = val;
                setAllPrices({ price_ttc: newTTC3, tax: newtaxP3, price: allPrices.price, tax_currency: val });
                break;

            default:
                break;
        }
    };

    // for rounding legacy values on click
    const roundMe = (val, field) => {
        if (Number(val) !== Math.round(Number(val))) {
            priceHandling(field, Math.round(Number(val)).toString());
        }
    };

    return (
        <div
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <div className='articleNote'>
                cliquez sur un des un champs ci-dessous pour le modifier. Les deux champs clignotants seront re-calculés
                automatiquement
            </div>
            <div className='articleOneLine'>
                <TextField
                    disabled={disabledFields[0]}
                    label='Prix TTC (XPF)'
                    value={allPrices.price_ttc}
                    onClick={e => {
                        e.stopPropagation();
                        setDisabledFields([false, true, true, true]);
                    }}
                    onChange={e => {
                        priceHandling('priceTTC', e.target.value);
                    }}
                    className={
                        !disabledFields[0]
                            ? 'articlePriceFieldSelected'
                            : !disabledFields[2] || !disabledFields[3]
                            ? 'articlePriceFieldToChange'
                            : ''
                    }
                />
                <TextField
                    disabled={disabledFields[1]}
                    label='TGC (%)'
                    value={allPrices.tax}
                    onClick={e => {
                        e.stopPropagation();
                        setDisabledFields([true, false, true, true]);
                        roundMe(allPrices.tax, 'tax');
                    }}
                    onChange={e => {
                        priceHandling('tax', e.target.value);
                    }}
                    className={
                        !disabledFields[1]
                            ? 'articlePriceFieldSelected'
                            : !disabledFields[2] || !disabledFields[3]
                            ? 'articlePriceFieldToChange'
                            : ''
                    }
                />
            </div>
            <div style={{ height: '15px' }}></div>
            <div className='articleOneLine'>
                <TextField
                    disabled={disabledFields[2]}
                    label='Prix HT (XPF)'
                    value={allPrices.price}
                    onClick={e => {
                        e.stopPropagation();
                        roundMe(allPrices.tax, 'price');
                        setDisabledFields([true, true, false, true]);
                    }}
                    onChange={e => {
                        priceHandling('price', e.target.value);
                    }}
                    className={
                        !disabledFields[2]
                            ? 'articlePriceFieldSelected'
                            : !disabledFields[0] || !disabledFields[1]
                            ? 'articlePriceFieldToChange'
                            : ''
                    }
                />
                <TextField
                    disabled={disabledFields[3]}
                    label='TGC (XPF)'
                    value={allPrices.tax_currency === undefined ? 'non défini' : allPrices.tax_currency}
                    onClick={e => {
                        e.stopPropagation();
                        setDisabledFields([true, true, true, false]);
                    }}
                    onChange={e => {
                        priceHandling('tax_currency', e.target.value);
                    }}
                    className={
                        !disabledFields[3]
                            ? 'articlePriceFieldSelected'
                            : !disabledFields[0] || !disabledFields[1]
                            ? 'articlePriceFieldToChange'
                            : ''
                    }
                />
            </div>
        </div>
    );
};

export default ArticleFourPrices;
