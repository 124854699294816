import { useState } from 'react';
import { Delete, RemoveCircle } from '@material-ui/icons';
import { REACT_APP_API_URL_NEEDEAT } from '../../../config';

const ArticleOneImage = ({ values, src, reFreshComponent, t }) => {
    const [imgHover, setImgHover] = useState(false);
    const selected = values.imagesToDelete?.includes(src);

    return (
        <div
            className='imgDeleteContainer'
            onMouseEnter={() => {
                setImgHover(true);
            }}
            onMouseLeave={() => {
                setImgHover(false);
            }}
            onClick={() => {
                if (selected) {
                    values.imagesToDelete.splice(
                        values.imagesToDelete.findIndex(id => id === src),
                        1
                    );
                } else {
                    // cp.push(src);
                    if (!values.imagesToDelete) {
                        values.imagesToDelete = [src];
                    } else {
                        values.imagesToDelete.push(src);
                    }
                }
                reFreshComponent();
            }}
        >
            <img
                src={REACT_APP_API_URL_NEEDEAT + '/' + src}
                className={imgHover || selected ? 'articleImgOpacity' : 'articleImg'}
            />
            {imgHover && !selected && <Delete className='articleImgDel' fontSize='large' style={{ color: 'red' }} />}
            {selected && <RemoveCircle className='articleImgDel' fontSize='large' style={{ color: 'red' }} />}
            {imgHover && !selected && <span className='articleImgDelTxt'>{t('textSuppress')}</span>}
            {selected && <span className='articleImgDelTxt'>{t('textSelectedForSuppress')}</span>}
        </div>
    );
};

export default ArticleOneImage;
