export const TRANSLATIONS_FR = {
    titlePriceParameter: 'Paramètres du prix de commande',
    ArticleTaxText: 'Article taxé',
    TGCText: 'Taux de TGC par défaut ',
    minimumBuy: "Minimum d'achat",
    AssignDeliver: "Assigner à un livreur dès l'acceptation de la commande",
    titlePlanifParameter: 'Paramètres de planification de commande',
    clientCanCommand: 'Le client peut programmer une commande',
    labelTimeAfterCommand: 'Durée max entre l’heure de planification et l’heure de passage de commande',
    titleDelivery: 'Paramètres de livraison',
    takeDeliveryInCharge: 'Prise en charge des frais de livraison',
    labelFreeDeliveryPrice: 'Livraison gratuite à partir de',
    labelFreeDeliveryRadius: 'Livraison gratuite dans un rayon de',
    minDeliveryTimeLabel: 'Min. temps de livraison',
    maxDeliveryTimeLabel: 'Max. temps de livraison',
    authorizeClientGetCommand: 'Autoriser le client à récupérer la commande',
    deliveryAllArea: 'Livraison sur toute la zone',
    radiusDelivery: 'Rayon de livraison',
    inActivityLabel: 'En activité ?',
    visibleText: 'Visible ?',
    scheduleOpen: "Horaire d'ouverture",
    scheduleClose: 'Horaire de fermeture',
    sunday: 'Dimanche',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    openH24: 'Ouvert 24/24',
    open: 'Ouvert',
    generalSettings: 'Paramètres généraux',
    restaurantSpeciality: 'Spécialités du restaurant',
    generalPassword: 'Entrez votre mot de passe',
    passwordMandatory: 'mot de passe obligatoire pour changer les parametres',
    passwordLabel: 'Mot de passe',
    labelTags: 'Veuillez cocher les spécialités produites dans votre restaurant',
    inXpf: 'En XPF',
    inKm: 'En Km',
    inMinute: 'En min',
    inPercentage: 'En %',
    addSchedule: 'Ajouter des horaires',
    save: 'sauvegarder toutes les modifications',
    add: 'Ajouter',
    errorScheduleOne: "L'horaire d'ouverture est déjà contenu dans une plage horaire pour ce jour",
    errorScheduleTwo: "L'horaire de fermeture est déjà contenu dans une plage horaire pour ce jour",
    errorScheduleThree: "L'horaire d'ouverture est supérieur à l'horaire de fermeture",
    errorScheduleFour: "L'horaire d'ouverture ou de fermeture ne peut pas être nul",
    errorScheduleFive: 'Vos horaires ne peuvent pas englober une plage horaire déjà existance',
    validate: 'Valider',
    notifySuccesScheduleUpdate: 'Horaire du restaurant bien mis à jour',
    errorNotNegative: 'Nombre négatif interdit',
    errorPercentage: 'Nombre entre 0 et 100',
    onParameterEditSuccess: 'Vos paramètres ont bien été mis à jour',
    onStoreEditSuccess: 'Votre restaurant a bien été mis à jour',
    errorDeliveryminMax: 'Durée minimale doit être inférieur à durée maximale',
    labelName: 'Nom',
    labelEmail: 'Email',
    labelPhone: 'Numéro de téléphone',
    labelAddress: 'Adresse',
    labelUrl: 'Url du site web',
    labelSlogan: 'Slogan',
    labelPasswordConfirmLabel: 'Confirmer le mot de passe',
    labelCountry: 'Pays',
    labelCity: 'Ville',
    labelDeliveryType: 'Type de livraison',
    labelRefferalCode: 'Code de parrainage',
    labelWalletAmount: 'Porte-monnaie',
    labelTotalReferrals: 'Total des avoirs',
    emailError: 'Vous devez rentrer une adresse email',
    emptyError: 'Le champ ne peut pas être vide',
    errorPasswordLength: 'Votre mot de passe doit faire minimum 6 charactères',
    errorPasswordConfirm: 'La confirmation du mot de passe doit être égal au nouveau mot de passe',
    labelParameters: 'Paramètres',
    labelSchedule: 'Horaires',
    modifAccountLabel: 'Compte',
    validateLabel: 'Enregistrer',
    labelArticleOption: "Options d'articles",
    labelArticle: 'Articles',
    labelMenu: 'Menu',
    labelPromos: 'Promos',
    labelHistoric: 'Historique',
    labelCommande: 'Commandes',
    labelDashboard: 'Dashboard',
    deliveryAdressText: 'Delivery address',
    titleHistoryList: 'Historique des Commandes',
    labelUser: 'Utilisateur',
    labelDeliveryMen: 'Livreur',
    labelDateAndHour: 'Date et Heure',
    labelAmount: 'Montant',
    labelStatus: 'Status',
    notifyDeleteOptionWrong: "Erreur dans la suppression de l'option",
    notifyDeleteOptionRight: 'Option supprimée',
    notifyCreateOptionWrong: "Erreur dans la création de l'option",
    notifyCreateOptionRight: 'Option créée',
    textCategory: 'Catégorie',
    titleEditOption: "Nom de la catégorie d'options",
    optionLabel: 'Options (prix TTC par défaut)',
    addOptionLabel: 'Ajouter une option',
    notYetOption:
        'Vous n’avez aucune option associée à cette catégorie pour le moment, commencez à ajouter des options.',
    placeHolderNewOptionName: "nom de l'option",
    placeHolderNewOptionPrice: 'Prix par défaut (TTC)',
    titleHistory: 'Historique',
    titleAddCategoryOption: 'Nom de la nouvelle catégorie',
    labelAddCategoryOption: 'Nom de la catégorie',
    textCreate: 'Créer',
    titleOptionList: "Gérer les options d'article",
    titleOption: "Groupe d'options",
    cancelReasonLabel: "Raison de l'annulation",
    cancelReasonTitle: 'Sélectionner une raison',
    textCancel: 'Cancel',
    textCommandAccepted: 'Commande acceptée avec succès',
    textCommandReadyToDelivery: 'Commande prête à être livrée - succès',
    textCommandErrorApi: "Erreur de l'api",
    textErrorVehicle: 'Impossible de joindre le véhicule',
    textCommandOnGoing: 'Commande passée à en cours',
    textCancelOrderSuccessful: 'Commande bien suppprimée',
    labelNewCommand: 'Nouvelles Commandes',
    labelOnGoing: 'En cours',
    labelTime: 'Temps',
    labelTimePreparation: 'Temps de préparation (mn)',
    labelReadyToBeDelivered: 'Prêt à être livré',
    labelWantToChangePassword: 'Changer de mot de passe ?',
    labelDontWantToChangePassword: 'Ne pas changer de mot de passe ?',
    accountTitle: 'Compte',
    scheduleTitle: 'Horaires',
    labelActive: 'Actif',
    labelDescription: 'Description',
    percenTageText: 'Pourcentage',
    absoluteText: 'Absolu',
    labelValue: 'Valeur',
    textArticle: 'Article',
    textRestaurant: 'Restaurant',
    textProduct: 'Produit',
    labelPromoOnTotalOrder: 'Promo sur la commande totale',
    labelRequireAccount: 'Nécessite un compte',
    labelMinimumPrice: "Minimum d'achat",
    labelMaximumReduc: 'Limite maximum de réduction',
    labelMinimumArticle: "Limite minimum d'article",
    labelDate: 'Date',
    labelDateBegining: 'Date de départ',
    labelDateEnd: "Date d'expiration",
    choiceNoRecursion: 'Pas de récursivité',
    choiceDailyRecursion: 'récursivité journalière',
    choiceWeeklyRecursion: 'récursivité hebdomadaire',
    choiceMounthlyRecursion: 'récursivité mensuelle',
    choiceAnnualyRecursion: 'récursivité annuel',
    labelPromoStartTime: 'heure de départ (ex: 09:30)',
    labelPromoEndTime: 'heure de fin (ex: 20:00)',
    choiceFirst: 'Premier',
    choiceSecond: 'Deuxième',
    choiceThird: 'Troisième',
    choiceFourth: 'Quatrième',
    choiceFifth: 'Cinquième',
    choiceJanuary: 'Janvier',
    choiceFebruary: 'Février',
    choiceMarch: 'Mars',
    choiceApril: 'Avril',
    choiceMay: 'Mai',
    choiceJune: 'Juin',
    choiceJuly: 'Juillet',
    choiceAugust: 'Aout',
    choiceSeptember: 'Septembre',
    choiceOctober: 'OCtobre',
    choiceNovember: 'Novembre',
    choiceDecember: 'Décembre',
    labelId: 'Id',
    labelType: 'Type',
    labelEstimatedDelivery: 'Récupération estimée',
    labelOrderStatus: 'Statut commande',
    labelClient: 'Client',
    statusDelivery: 'Statut livraison',
    labelDetail: 'Détail',
    labelForUsage: 'Pour usage',
    labelUsage: 'Utilisations',
    labelAccountUsed: 'Comptes utilisés ?',
    textDescriptionArticleCreate:
        'Définissez le nom, prix et description de votre article. Vous ajouterez les images et options dans un deuxième temps',
    labelArticleName: "Nom de l'article",
    labelTTCPrice: 'Prix TTC',
    newPasswordLabel: 'Nouveau mot de passe',
    labelSearch: 'chercher',
    filterAll: 'Toutes',
    searchFieldLabel: 'Rechercher par',
    orderStatus: 'Statut commande',
    choiceAll: 'Tous',
    choiceRejected: 'Les rejetées',
    choiceCompleted: 'Les complétées',
    labelOrderType: 'Type de commande',
    choiceAsap: 'ASAP',
    choicePlanify: 'Planifiée',
    choiceBoth: 'Les 2',
    labelHistoryDateBeginning: 'Date de départ',
    labelHistoryDateEnd: 'Date de fin',
    titleArticleCategory: 'Gérer les articles',
    notifyCategoryCreated: 'Nouvelle catégorie créée',
    nameNewCat: 'Nom de la nouvelle catégorie',
    titleArticles: 'Articles',
    labelArticleSingle: 'Article',
    labelPrice: 'Prix',
    labelIsInStock: 'Article En stock',
    titleEditArticle: 'Modifier un article',
    notifyOptionAdd: 'Option ajoutée',
    notifyOptionSuppress: 'Option supprimée',
    TGCLabel: 'TGC(%)',
    labelDescriptionArticle: "Description de l'article",
    labelOptionName: "Nom de l'option d'article",
    labelRequired: 'Requis',
    labelRange: 'Range',
    labelMaxRange: 'Max Range',
    labelOption: 'Option',
    labelSelectByDefault: 'Sélectionné par défaut',
    labelSelByDefault: 'Sél par défaut',
    textSuppressGroupOf: 'Supprimer le groupe de',
    addGroupOptions: "Ajouter un groupe d'options",
    addInOption: 'Ajouter un groupe d options',
    succesMessageArticleEdit: 'Menu mis à jour',
    imageExisting: 'Images article',
    addAnImage: 'Ajouter une image',
    resizeImage: 'Redimensionnez votre image',
    finaleImage: 'Image finale',
    placeHolderImageText: 'Cliquez ou glissez une image dans ce cadre, puis redimensionnez',
    textSuppress: 'Supprimer',
    textSelectedForSuppress: 'Sélectionné pour suppression',
    labelSelectGroup: 'Selectionnez un groupe',
    fieldRequire: 'Champ requis',
    newNameCat: 'Nouveau nom de la catégorie',
    categoryUpdated: 'Catégorie mise à jour',
    textUpdate: 'Modifier',
    errorValueCantBe0: 'Valeur ne peut pas être 0',
    createArticle: 'Créer un article',
    fieldNotNullNotNegative: 'Produit ne peut pas être négatif ou nul',
    notifyErrorEmpty: 'Le champ nom ne peut pas ne pas être rempli',
    notifyPriceUnder0: 'Le prix ne peux pas être inférieur à 0 veuillez rentrer un prix valable.',
    notifyInStockChange: 'Le statut du stock du produit a bien été changé',
    labelParametersModifyWithSuccess: 'Les paramètres ont été modifié avec succès',
    textErrorFieldEmpty: 'Vous ne pouvez pas rentrer un champ vide',
    textErrorFieldEmptyOrInferiorOne: 'Vous ne pouvez pas rentrer un champ vide ou inférieur à 1 minute.',
    authorizedVehicules: 'liste des véhicules autorisés',
    titleDeliveries: 'Livraisons',
    articleStartTime: 'Heure de début',
    articleEndTime: 'Heure de fin',
    articleTimeTitle: 'Horaires des ventes',
    articleNoTime: 'Pas de horaires',
    articleModalTitle: "Modification des horaires de l'article"
};
